import { CREATE, FETCH_ALL, END_LOADING, START_LOADING, FETCH_BY_SEARCH, FETCH_POST, FETCH_BY_CATEGORY, FETCH_BY_FEATURES, FETCH_CATS, FETCH_NFTS, UPLOADED_FILES, UPDATE_STATUS, FETCH_NFTDROPS, FETCH_DROP_BY_CATEGORY, FETCH_DROP_BY_SEARCH, FETCH_DROP, FETCH_DROP_BY_BLOCKCHAIN, FETCH_BY_BLOCKCHAIN} from '../constants/actionTypes';
import * as api from '../api/index.js';


export const likePost = (id, setLikecount) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  try {
    if(user){
      dispatch({ type: START_LOADING });
      const userdata = {user: user, id: id};
      const checklike = await api.checkIfLiked(userdata);
      const likeinfodata = checklike.data[0].likes?.split(',').find((findid)=>{
        return findid === id.toString();
      });
      const databaselikeinfo = checklike.data[0].likes;
      if(!likeinfodata){
       
        var newlikes='';
        if(databaselikeinfo.length ===0){
          newlikes = id.toString();
        }else{
           newlikes = databaselikeinfo.toString()+','+id;
        }
        const updatelikedata = {user: user, likes: newlikes};
        const updateliked = await api.updateLike(updatelikedata);
        const  info  = await api.likePost(id);
        const {data} = await api.getNFTById(id)
        setLikecount(data.likes)
      }
      dispatch({ type: END_LOADING });
    }
  } catch (error) {
    console.log(error);
  }
};

export const likeNFTDrop = (id, setLikecount) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  try {
    if(user){
      dispatch({ type: START_LOADING });
      const userdata = {user: user, id: id};
      const checklike = await api.checkIfLikedForDrops(userdata);
      const likeinfodata = checklike.data[0].dropslikes?.split(',').find((findid)=>{
        return findid === id.toString();
      });
      const databaselikeinfo = checklike.data[0].dropslikes ? checklike.data[0].dropslikes: '';
      if(!likeinfodata){
          var newlikes='';
        if(databaselikeinfo.length ===0){
          newlikes = id.toString();
        }else{
           newlikes = databaselikeinfo.toString()+','+id;
        }
        const updatelikedata = {user: user, likes: newlikes};
        const updateliked = await api.updateDropLike(updatelikedata);
        const { likedata } = await api.likeDropPost(id);
        const {data} = await api.getNFTdrop(id)
        setLikecount(data.likes)
      }
      dispatch({ type: END_LOADING });
    }
  } catch (error) {
    console.log(error);
  }
};

export const mintNFT = (post) => async(dispatch)=>{
  try{
      dispatch({ type: START_LOADING });
      const{ data } = await api.createPost(post);
      dispatch({ type: CREATE, payload: data });
      dispatch({ type: END_LOADING });

  } catch(error){
      console.log(error);
  }
};

export const getNFT = (contract, tokenid) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.getNFT(contract, tokenid);

    dispatch({ type: FETCH_POST, payload: { nft: data } });
    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};

export const getNFTdrop = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.getNFTdrop(id);
   
  dispatch({ type: FETCH_DROP, payload: { nftdrop: data } });
    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};

export const getPosts = (page, sort) => async(dispatch) => {
    try{
        dispatch({ type: START_LOADING });
        const { data: { data, currentPage, numberOfPages } } = await api.getPosts(page, sort);
       
        dispatch({ type: FETCH_ALL, payload: { data, currentPage, numberOfPages } });

        dispatch({ type: END_LOADING });

    }catch(error){
        console.log(error.message);
    }


}

export const getNFTdrops = (page, sort) => async(dispatch) => {
    try{
        dispatch({ type: START_LOADING });
        const { data: { data, currentPage, numberOfPages } } = await api.getNFTdrops(page, sort);
        dispatch({ type: FETCH_NFTDROPS, payload: { data, currentPage, numberOfPages } });
        dispatch({ type: END_LOADING });

    }catch(error){
        console.log(error.message);
    }
}

export const getPostsBySearch = (searchQuery, page, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
   
    const { data: { data, currentPage, numberOfPages } } = await api.getPostsBySearch(searchQuery, page, sort);

    dispatch({ type: FETCH_BY_SEARCH, payload: { data, currentPage, numberOfPages } });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getNFTdropsBySearch = (searchQuery, page, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getNFTdropsBySearch(searchQuery, page, sort);
    dispatch({ type: FETCH_DROP_BY_SEARCH, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getPostsByCategory = (category, page, sort) => async (dispatch) => {
  
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getPostsByCategory(category, page, sort);
   
    dispatch({ type: FETCH_BY_CATEGORY, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getNFTdropsByCategory = (category, page, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getNFTdropsByCategory(category, page, sort);
    dispatch({ type: FETCH_DROP_BY_CATEGORY, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getPostsByBlockchain = (blockchain, page, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getPostsByBlockchain(blockchain, page, sort);
    dispatch({ type: FETCH_BY_BLOCKCHAIN, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getNFTdropsByBlockchain = (blockchain, page, sort) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getNFTdropsByBlockchain(blockchain, page, sort);
    dispatch({ type: FETCH_DROP_BY_BLOCKCHAIN, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getPostsByFeatures = (count) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
   
    const { data: { data } } = await api.getPostsByFeatures(count);
    
    dispatch({ type: FETCH_BY_FEATURES, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getNFTdropsFeatures = (count) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING }); 
    const { data: { data } } = await api.getNFTDropByFeatures(count);
   
    dispatch({ type: FETCH_NFTDROPS, payload: { data } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getCats = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data } = await api.getAllCats();

    dispatch({ type: FETCH_CATS, payload: {data} });
    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};


export const getMyNFTs = (acc) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
   
    const { data: {data, currentPage, numberOfPages }} = await api.getNFTsByAcc(acc);
   
    dispatch({ type: FETCH_NFTS, payload: {data, currentPage, numberOfPages} });
    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};

export const getMyNFTsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getNFTsByAccBySearch(searchQuery);

    dispatch({ type: FETCH_NFTS, payload: { data, currentPage, numberOfPages } });
   
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getCollection = (collectionname) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });

    const { data: { data, currentPage, numberOfPages } } = await api.getCollectionByName(collectionname);
    
    dispatch({ type: FETCH_NFTS, payload: {data, currentPage, numberOfPages} });
    dispatch({ type: END_LOADING });

  } catch (error) {
    console.log(error);
  }
};

export const getCollectionBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getCollectionBySearch(searchQuery);

    dispatch({ type: FETCH_NFTS, payload: { data, currentPage, numberOfPages } });
   
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updateNFTDropQuantityInfo = (quantitydata) => async(dispatch) =>{
  try{
    dispatch({ type: START_LOADING });
    const data = await api.updateNFTDropQuantity(quantitydata);
    dispatch({ type: END_LOADING});
  } catch(error){
    console.log(error)
  }
};

export const updatedNFTsalestatus = (status) => async (dispatch) => {
 try{
      dispatch({ type: START_LOADING });
      const{ data } = await api.updateNFTstatus(status);
      
      dispatch({ type: END_LOADING });

  } catch(error){
      console.log(error);
  }
};

export const updatedNFTsaleprice = (updatedata) => async (dispatch) => {
 try{
      dispatch({ type: START_LOADING });
      const{ data } = await api.updateNFTprice(updatedata);
     
      dispatch({ type: END_LOADING });
  } catch(error){
      console.log(error);
  }
};


export const updateNFTpriceUsingContract = (priceinfo) => async (dispatch) => {
  try{
       dispatch({ type: START_LOADING });
       const{ data } = await api.updateNFTpriceUsingContract(priceinfo);
       
       dispatch({ type: END_LOADING });
   } catch(error){
       console.log(error);
   }
 };

export const updatedNFTpurchases = (purchasedata) => async (dispatch) => {
  try{
       dispatch({ type: START_LOADING });
       const{ data } = await api.updateNFTpurchase(purchasedata);
   
       dispatch({ type: END_LOADING });
   } catch(error){
       console.log(error);
   }
 };
 
 export const uploadedFiles = (filedoc) => async (dispatch) => {
  try{
      
       dispatch({ type: UPLOADED_FILES, payload: filedoc });
       
   } catch(error){
       console.log(error);
   }
 };

 export const createNFTDrop = (nftdata) => async (dispatch) => {
  try{
       dispatch({ type: START_LOADING });
       const{ data } = await api.createNFTDrop(nftdata);
     
       
       dispatch({ type: END_LOADING });
   } catch(error){
       console.log(error);
   }
 };

 export const getUpcomingdrops = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const  {data: { data, currentPage, numberOfPages }}  = await api.getUpcoming(page);
    dispatch({ type: FETCH_NFTDROPS, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getActivedrops = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getActive(page);
    dispatch({ type: FETCH_NFTDROPS, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getPastdrops = (page) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data: { data, currentPage, numberOfPages } } = await api.getPast(page);
    dispatch({ type: FETCH_NFTDROPS, payload: { data, currentPage, numberOfPages } });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};



