import React from 'react';
import './gettingstarted.css';
import GettingStarted from '../../../components/Cards/GettingStarted/GettingStarted';

const Gettingstarted = ()=> {

    return (
        <GettingStarted/>
    )
};

export default Gettingstarted;