import React from 'react';
import { CircularProgress } from '@mui/material';
import NFTCard from "../../../components/Cards/NFTCard/NFTCard";
import { useSelector } from 'react-redux';


const Topnfts = ()=> {
    const { nfts } = useSelector((state) => state.nfts);
  

    if (!nfts) {
      return (
        <div className='item-details-area pt-100 pb-70'>
          <div className='container'>
            <div className='row'>
            <CircularProgress size="5em" />
            </div>
          </div>
        </div>
      );
    }

    return (
        
      <div className='featured-area pt-100 pb-70'>
        <div className='container'>
          <div className='tab featured-tab-area'>
            <div className='row align-items-center'>
              <div className='col-lg-8 col-md-6'>
                <div className='section-title'>
                  <h2>Featured NFTs</h2>
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='auction-btn text-end'>
                    <a  href='/browse?page=1&sort=1' className='default-btn border-radius-5'>Explore More</a>
                </div>
              </div>


            </div>

            <div className='row justify-content-center pt-45'>
              {nfts?.map((nft) => {    
                      return(<NFTCard key={nft.id} item={nft} col={3} />);
              })}
            </div>

         </div>
        </div>
      </div>
    )
};

export default Topnfts;