import React, { useEffect } from 'react';
import { Introduction, Nftdrops, Topnfts, Gettingstarted, Joincommunity, Topcollections } from '../containers';
import { useDispatch } from 'react-redux';
import { getPostsByFeatures, getNFTdropsFeatures } from '../actions/nfts';
import { getTopCollection } from '../actions/collections'
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const sort = query.get('sort') || 1;
    const page = query.get('page') || 1;
    useEffect(() => {
        dispatch(getPostsByFeatures(8));
        dispatch(getNFTdropsFeatures(4));
        dispatch(getTopCollection());
    }, [dispatch,page, sort]);

    return(
        <div>
            <Introduction/>
             <Nftdrops/>
            <Topnfts/>
            <Topcollections/>
            <Gettingstarted/>
            <Joincommunity/>
        </div>
    );

}

export default Home;
