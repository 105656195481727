import axios from 'axios';

const API = axios.create({ baseURL: 'https://testnetapi.nfthev.io' });


export const createPost = (newPost)=> API.post(`/nfthevtestNFTs/create`, newPost);
export const updateNFTstatus = (status)=> API.patch(`/nfthevtestNFTs/updateNFTstatus`, status);
export const updateNFTprice = (updatedata) => API.patch('/nfthevtestNFTs/updateNFTprice', updatedata);
export const updateNFTpurchase = (purchaseinfo) => API.patch('/nfthevtestNFTs/updateNFTpurchase', purchaseinfo);
export const updateNFTpriceUsingContract = (priceinfo) => API.patch('/nfthevtestNFTs/updateNFTpriceFromContract', priceinfo);
export const likePost = (id) => API.patch(`/nfthevtestNFTs/${id}/likePost`);
export const getPosts = (page, sort)=> API.get(`/nfthevtestNFTs?page=${page}&sort=${sort}`);
export const getNFTsByAcc = (acc) => API.post(`/nfthevtestNFTs/myNFTs`, acc);
export const getNFTsByAccBySearch = (searchQuery) => API.post(`/nfthevtestNFTs/myNFTs/search`, searchQuery);
export const getCollectionByName = (collectionname) => API.post(`/nfthevtestNFTs/collection`, collectionname);
export const getCollectionBySearch = (searchQuery) => API.post(`/nfthevtestNFTs/collection/search`, searchQuery);
export const getPostsBySearch = (searchQuery) => API.get(`/nfthevtestNFTs/search?searchQuery=${searchQuery.searchQuery || 'none'}&page=${searchQuery.page}&sort=${searchQuery.sort}`);
export const getPostsByCategory = (category) => API.get(`/nfthevtestNFTs/category?cat=${category.category || 'none'}&page=${category.page}&sort=${category.sort}`);
export const getPostsByBlockchain = (blockchain) => API.get(`/nfthevtestNFTs/network?chain=${blockchain.blockchain || 'none'}&page=${blockchain.page}&sort=${blockchain.sort}`)
export const getPostsByFeatures = (count) => API.get(`/nfthevtestNFTs/feature?count=${count}`);
export const getNFT = (contract, tokenid) => API.get(`/nfthevtestNFTs/${contract}/${tokenid}`);
export const getNFTById = (id) => API.get(`/nfthevtestNFTs/nftdata/${id}`)
export const getAllNFTContracts = () => API.get(`/nfthevtestNFTs/NFTcontracts`);

export const createUser = (newUser)=> API.post(`/users/create`, newUser);
export const checkIfLiked = (userdata)=> API.post(`/users/checkifliked`, userdata);
export const uploadimage = (image) => API.post(`/users/uploadimage`, image,{ headers:{
          'Content-Type': 'multipart/form-data'}
        });
export const checkIfLikedForDrops = (userdata)=> API.post(`/users/checkiflikedfordrops`, userdata);
export const updateUser = (user) => API.patch(`/users/update`, user);
export const getUser = (user) => API.get(`/users/${user}`);
export const updateLike = (userdata) => API.patch(`/users/updatelike`, userdata);
export const updateDropLike = (userdata) => API.patch(`/users/updatedroplike`, userdata);

export const createReport = (newPost)=> API.post(`/reports/create`, newPost);

export const createRequest = (newPost)=> API.post(`/requests/create`, newPost);

export const createTransaction = (newPost)=> API.post(`/transactions/create`, newPost);
export const getTransactions = (transactiondata)=> API.post(`/transactions/token`, transactiondata);
export const getNFTDropTransactions = (transactid)=>API.post(`/transactions/id`, transactid)

export const createNFTDrop = (nftdropdata)=> API.post(`/nfthevtestdrops/create`, nftdropdata);
export const getNFTdrop = (id) => API.get(`/nfthevtestdrops/${id}`);
export const getNFTdrops = (page, sort)=> API.get(`/nfthevtestdrops?page=${page}&sort=${sort}`);
export const getNFTDropByFeatures = (count) => API.get(`/nfthevtestdrops/feature?count=${count}`);
export const getNFTdropsBySearch = (searchQuery) => API.get(`/nfthevtestdrops/search?searchQuery=${searchQuery.searchQuery || 'none'}&page=${searchQuery.page}&sort=${searchQuery.sort}`);
export const getNFTdropsByCategory = (category) => API.get(`/nfthevtestdrops/category?cat=${category.category || 'none'}&page=${category.page}&sort=${category.sort}`);
export const getNFTdropsByBlockchain = (blockchain) => API.get(`/nfthevtestdrops/network?chain=${blockchain.blockchain || 'none'}&page=${blockchain.page}&sort=${blockchain.sort}`);
export const likeDropPost = (id) => API.patch(`/nfthevtestdrops/${id}/likeDropPost`);
export const updateNFTDropQuantity = (quantitydata) => API.patch(`/nfthevtestdrops/updatequantity`, quantitydata);
export const getUpcoming = (page)=>API.get(`/nfthevtestdrops/upcoming?page=${page}`);
export const getActive = (page)=>API.get(`/nfthevtestdrops/active?page=${page}`);
export const getPast = (page)=>API.get(`/nfthevtestdrops/past?page=${page}`);

export const getCollectionData = (collectionname)=> API.get(`/nfthevtestcollection/${collectionname}`);
export const getCollectionByUrl = (collectionurl)=> API.get(`/nfthevtestcollection/url/${collectionurl}`);
export const getTopCollection = ()=> API.get(`/nfthevtestcollection/featured`);
export const getAllCollection = (pagesort)=> API.get(`/nfthevtestcollection?page=${pagesort.page}&sort=${pagesort.sort}`);
export const getAllCollectionBySearch = (searchpagesort)=> API.get(`/nfthevtestcollection/allsearch?searchQuery=${searchpagesort.searchQuery}&page=${searchpagesort.page}&sort=${searchpagesort.sort}`);
export const addCollection = (collection)=> API.post(`/nfthevtestcollection/create`, collection);
export const uploadCollectionFile = (collection) => API.post(`/nfthevtestcollection/uploadfile`, collection,{ headers:{
  'Content-Type': 'multipart/form-data'}
});
export const getOwnerCollection = (ownercollection)=>API.get(`/nfthevtestcollection/owner/${ownercollection.acc}?page=${ownercollection.page}&sort=${ownercollection.sort}`);
export const getOwnerCollectionBySearch = (ownercollection) => API.get(`/nfthevtestcollection/search?owner=${ownercollection.acc}&searchQuery=${ownercollection.searchQuery || 'none'}&page=${ownercollection.page}&sort=${ownercollection.sort}`);
export const updateOwnerCollection = (collectiondata) => API.patch(`/nfthevtestcollection/update`, collectiondata);

export const getCats = (id) => API.get(`/cats/${id}`);
export const getAllCats = () => API.get(`/cats`);

export const createSubscriber =(email)=>API.post(`/nfthevtestsubscribe/create`, email);

export const getNetworkBySymbol = (networkinfo)=> API.post(`/network/symbol`, networkinfo);

export const storenft = (datainfo)=>API.post(`/apicall/nftstore`, datainfo, { headers:{
  'Content-Type': 'multipart/form-data'}})

export const createWhitelister =(whitelisterdata)=>API.post(`/nfthevwhitelist/whitelistcreate`, whitelisterdata, { headers:{
  'Content-Type': 'multipart/form-data'}});
export const getWhitelister =(whitelisteraddy)=>API.post(`/nfthevwhitelist/getwhitelister`, whitelisteraddy, { headers:{
  'Content-Type': 'multipart/form-data'}});

