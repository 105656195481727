// import { Link } from '@mui/material';
import React from 'react';
import image1 from '../../../images/cardicons/howtocreatenft3.jpg';
import image2 from '../../../images/cardicons/createicon1.png';
import image3 from '../../../images/cardicons/createicon2.png';
import image4 from '../../../images/cardicons/createicon3.png';
import image5 from '../../../images/cardicons/createicon4.png';

const GettingStarted = () => {
  return (
    <>
      <div className='create-area pt-100 pb-70'>
        <div className='container'>
          <div className='section-title text-center'>
            <h2>Create And Sell Your NFTs For Free</h2>
          </div>

          <div className='row align-items-center pt-45'>
            <div className='col-lg-6'>
              <div className='create-img'>
                <img src={image1} alt='Images' />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='create-card-right pl-20'>
                <div className='row justify-content-center'>
                  <div className='col-lg-6 col-6'>
                    <div className='create-card'>
                      <img
                        src={image2}
                        alt='Images'
                      />
                      <h3> 
                       
                        <a href='/connect'>Connect Your Wallet</a>
                        
                      </h3>
                      <p>
                        The first step is to connect your wallet by clicking on the button that says "Connect Wallet" in the top
                        right corner.
                      </p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6'>
                    <div className='create-card'>
                      <img
                        src={image3}
                        alt='Images'
                      />
                      <h3>
                        
                        
                        <a href='/create'>
                          Create Your Profile
                        </a>
                        
                      </h3>
                      <p>
                        Click the "create profile" link in the drop down menu showing wallet account number. Fill out form and create profile
                      </p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6'>
                    <div className='create-card'>
                      <img
                        src={image4}
                        alt='Images'
                      />
                      <h3>
                      
                        <a href='/connect'> Add Your NFTs</a>
                      
                      </h3>
                      <p>
                        Click the "Create" button on the top right corner and then click "Create a NFT". Then fill out the form by entering the NFT name, price, uploading the NFT file, etc.  
                      </p>
                    </div>
                  </div>

                  <div className='col-lg-6 col-6'>
                    <div className='create-card'>
                      <img
                        src={image5}
                        alt='Images'
                      />
                      <h3>
                      
                        <a href='/connect'>List Your NFT For Sale</a>
                      
                      </h3>
                      <p>
                        Click the "Create NFT" button once you have filled out the form. Your wallet will prompt you to approve the transaction and pay the gas fee. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GettingStarted;