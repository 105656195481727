import React from 'react';
import { Usercontainer } from '../containers';





const MyNFTs = () => {

    return(
        <div>
            <Usercontainer/>
        </div>
    );

}

export default MyNFTs;
