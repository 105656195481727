import React from 'react';
import {SiteName, ApprovedFileTypes } from '../../../utils/config';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = ({ promotedOne, promotedTwo, promotedThree }) => {

  const samplefile = ()=>{
    fetch('/images/collections/samplenftcollection.json').then(response=>{
      response.blob().then(blob => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = 'Samplejsonfile.json';
      alink.click();
     })
    })
  }

  return (
    <>

      
      <div className='promoted-area pb-70'>
        <div className='container'>
          <div className='section-title'>
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className='row pt-45 justify-content-center'>
              <div>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>How do I get started using {SiteName}?</Typography>
                    
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      First download and install a non custodial wallet. We strongly recommend using <a href="https://metamask.io/download/" target="_blank" rel="noreferrer">MetaMask</a>. Once downloaded, create a MetaMask wallet account. Ensure to write down your seed phrase in a secure place and never ever give your seed phrase to anyone.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>So I have downloaded MetaMask and created an account, what's next?</Typography>
                    
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      MetaMask comes preloaded with Ethereum network installed. You will need to get Ethereum (ETH) in your MetaMask wallet account. If you have ETH in a different wallet you control, send ETH to your MetaMask wallet. If you do not have ETH, you will need to purchase ETH and send to your MetaMask wallet. You can purchase ETH from any reputable cryptocurrency exchange such as Binance.US, Binance.com, coinbase.com etc. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Connect Binance Smart Chain (BSC) network to your MetaMask</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      In order to buy, sell or mint NFT's using BNB, you have to have Binance Smart Chain (BSC) network installed in your MetaMask account. To do this, follow the <a href='https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain' target='_blank' rel="noreferrer">instructions</a> here. Once BSC has been installed, send BNB to the wallet.  
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Connect Polygon (MATIC) network to your MetaMask</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      In order to buy, sell or mint NFT's using MATIC, you have to have polygon (MATIC) network installed in your MetaMask account. To do this, follow the <a href='https://wiki.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/#add-the-polygon-network-manually' target='_blank' rel="noreferrer">instructions</a> here. Once Polygon has been installed, send MATIC to the wallet. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6a-content"
                    id="panel6a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Why do I need to have MATIC, BNB or ETH in my wallet account to buy or mint NFTs?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      In order to buy or mint NFT on a blockchain, a gas fee has to be paid to the blockchain validators in the native cryptocurrency of the blockchain. The native cryptocurrency for the Ethereum, Binance Smart Chain and Polygon blockchain is ETH, BNB and MATIC respectively. Note that the gas fee goes to the validators of the blockchain and not to {SiteName}. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel7a-content"
                    id="panel7a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What is a gas fee?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      A gas fee is a blockchain transaction fee paid to network validators for their service of validating blockchain transactions. This fee is used to incentivize individuals and entities to maintain the blockchain. 
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8a-content"
                    id="panel8a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>How can I purchase a NFT?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Once you have MetaMask installed and have either BNB, ETH or MATIC in your account, navigate to the page of the NFT you want to purchase. Click on the "Connect wallet" button. Then click on the "Buy" button. You will get a prompt from MetaMask to verify the transaction. Once the transaction is verified on MetaMask, the transaction is executed on the blockchain and the NFT can be seen in your wallet. When NFT is purchased using cryptocurrency, the total cost of the NFT is the sum of the NFT cost and gas fee.  
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel13a-content"
                    id="panel13a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>I purchased a NFT but do not see it in my wallet?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Sometimes when you buy a NFT, it does not show up in your wallet. When this happens, you have to add it to your wallet. For MetaMask, you can add the NFT by clicking on the "import token" link. Select "custom token", fill out the form and add the NFT. The token contract address can be obtain on the NFT page. The token symbol should auto fill and the token decimal is 0.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9a-content"
                    id="panel9a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What is a NFT drop?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      NFT drop is a NFT sale where the buyer gets the opportunity to mint/create a NFT without creating the NFT asset versus purchasing an existing NFT from a seller. The buyer gets to become the first one to own the NFT.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10a-content"
                    id="panel10a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What does minting a NFT mean?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Minting a NFT is the process of creating an NFT. It marks the first time a NFT is recorded on a blockchain thus making it available to be bought, sold or traded.     
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel11a-content"
                    id="panel11a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Can anyone participate in a NFT drop?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Yes, minting a NFT from a NFT drop sale will not require preapproval.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel12a-content"
                    id="panel12a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Can anyone conduct a NFT drop?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      No, special approval is required to be given the ability to conduct a NFT drop. If you have an idea about an NFT drop and would like to be approved for one, tell us about your project by filling out this <a href="/request">form</a>.      
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel13a-content"
                    id="panel13a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>How do I take part in a NFT drop?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Browse the available NFT drops using the <a href='/browse/nftdrop'>"NFT Drops"</a> link at the top of the homepage. Select the NFT drop you are interested in. Connect your wallet by clicking the 'Connect' or 'Connect wallet' button. Make sure the selected network in your wallet matches the network or blockchain the NFT drop will mint the NFT in. Then click on the "Mint" or "Mint now" button to mint a NFT. You will get a prompt from MetaMask to approve the transaction. Once transaction is approved, the NFT is minted and can be seen in your wallet. If it does not show up in your MetaMask wallet, you will need to add the NFT. In your MetaMask, click on the "import token" link. Select "custom token", fill out the form and add the NFT.     
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel14a-content"
                    id="panel14a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What are the benefits of minting NFT from a NFT drop?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      NFT drop provides the opportunity to get in on a NFT early at the ground level. If the value of the NFT only appreciates, the minter gets to purchase it at the lowest price. In addition to low prices, some NFT drops provide additional benefits such as offering a minters royalty, discounts to certain stores, access to premium products, etc.   
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel15a-content"
                    id="panel15a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What is a minters royalty?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Minters royalty is a royalty paid to the minter of a NFT during the NFT drop sale. The royalty is a small percentage of sales price paid to the minter anytime said NFT is sold even if the minter no longer owns the NFT. This payment occurs to perpetuity. Note that not all NFT drops will have this incentive.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel16a-content"
                    id="panel16a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>How can I create a NFT?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       {SiteName} has made NFT creation really easy. You first have to have your NFT asset done. So if it is a digital art work, the art work should be done and ready for upload. Once you have your NFT asset, click on the "create" button on the top right corner of the homepage. Click "Connect Wallet" to connect your wallet. Then click "Create an NFT". If you have not created a profile you will be redirected to the profile creation page to create a profile. Once a profile is created and approved, you will be given access to the NFT creation form page to continue creating the NFT. Fill out the form, upload the NFT file and click "Create NFT" once the form is completely filled. You will receive a prompt from MetaMask to approve the transaction. Once it has been approved, the NFT is minted and should show up on your wallet. If you do not see the NFT in your wallet, you will have to add it to your wallet. Each wallet is different, however for MetaMask, click on "import tokens". Then select "Custom token" and enter the token contract address. The token contract address can be found in the NFT detail page. When the contract address is included, the token symbol auto populates. The token decimal is 0. Once filled out, click "Add custom token".     
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel17a-content"
                    id="panel17a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Do I need to be preapproved to have the ability to create a NFT?</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Yes, you have to be preaproved to gain the ability to create NFT    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel20a-content"
                    id="panel20a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What else do I need to know before creating a NFT</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       You will need the NFT asset file ready for upload.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel21a-content"
                    id="panel21a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What are the supported NFT file types </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       The supported NFT file types include {ApprovedFileTypes}.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel22a-content"
                    id="panel22a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Where is the file uploaded and saved? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       The NFT files are hosted in the InterPlanetary File System (IPFS).     
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel23a-content"
                    id="panel23a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What is the InterPlanetary File System (IPFS)? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       The InterPlanetary File System is a protocol, hypermedia and file sharing peer-to-peer network for storing and sharing data in a distributed file system.     
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel23a-content"
                    id="panel23a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>Can I mint a NFT Collection? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Yes, you can.     
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel23a-content"
                    id="panel23a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>How can I mint a NFT collection? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Click on the 'create' button at the top right corner. Then click 'connect wallet' to connect your wallet. Make sure the right network/blockchain you want to work with is selected in your wallet. Click on the "Create NFT collection" button. Make sure your NFT files are saved in any of our supported file types and your metadata file is properly formatted and ready for upload. You can download the sample metadata file format by clicking on this <b onClick={samplefile}>Sample file format</b> link. You will also need to have a banner picture and a collection profile picture for your collection page. Once you have all your files, fill out the form, upload the files per instruction and click the "Create" button. You will be prompted by MetaMask to approve each NFT minted in the collection. So if your collection consist of 1000 unique NFTs, you will be prompted to approve the NFT mint 1000 times.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel24a-content"
                    id="panel24a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>How can I add more NFTs to an existing NFT collection? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Click on the 'Create' button at the top right corner. Then click 'connect wallet' to connect your wallet. Make sure the right network/blockchain you want to work with is selected in your wallet. Click on the "Add to Existing NFT collection" button. Make sure your NFT files are saved in any of our supported file types and your metadata file is properly formatted and ready for upload. You can download the sample metadata file format by clicking on this <b onClick={samplefile}>Sample file format</b> link. Once you have all your files, fill out the form, upload the files per instruction and click the "Add" button. You will receive a prompt from MetaMask to approve each NFT minted in the collection. So if you are adding 1000 unique NFTs to the collection, you will be prompted to approve the NFT mint 1000 times.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel24a-content"
                    id="panel24a-header"
                  >
                    <span className='red-bullet'></span>
                    <Typography variant='h6' className='faq-title' sx={{color:'#f14d5d'}}>What are the costs associated with minting a NFT or NFT collection? </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                       Minting a NFT or NFT collection is FREE on {samplefile}. We do not charge any cost associated with this operation. However, you will be charged a gas fee for each NFT minted. Note that this gas fee goes to the miners that maintain the network and not to {SiteName}.    
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
