import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {FaPlusCircle, FaMinusCircle} from 'react-icons/fa'
import {Box, OutlinedInput, MenuItem, Select, Chip, FormHelperText, FormControl} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NFTStorage, File } from 'nft.storage'
import { useWeb3React } from "@web3-react/core"
import { Integerval, Numberval } from '../../../utils/validate'
import { Fees, Blockchains, AudioFileFormat, VideoFileFormat } from '../../../utils/selectOptions'
import { getCats } from '../../../actions/nfts';
import { getNetworkBySymbol } from '../../../actions/network';
import { MintNFTShared } from '../../Blockchain/MintBuySell';
import { MintSharedEvent } from '../../Blockchain/Events';
import Loading from "../../../images/cardicons/loading.gif";
import Snackalert from '../../Buttons/Snackalert';
import {nftstoragecall} from '../../../actions/apicalls';
import axios from 'axios';
import { GiConsoleController } from 'react-icons/gi';
import collections from '../../../reducers/collections';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, category, theme) {
  return {
    fontWeight:
      category.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const CreateNFTCardMedia = () => {
    const [rowcount, setRowcount]= useState([1]);
    const [category, setCategory] = useState([]);
    const [fee, setFee] = useState('');
    const [network, setNetwork] = useState('');
    const { cats } = useSelector((state) => state.nfts);
    const [values, setValues] = useState({
        nftname:'', nftcat:'', nftfiles:'', nftdesc:'', nftprice:'', nftquantity:'', nftproperties:'', nftroyalty:'', nftnetwork:'', nftcontract:'', nftmediafiles:''
    });
    const [selectedfile, setSelectedfile] = useState('');
    const [displayname, setDisplayname] = useState('');
    const [displaymedianame, setDisplaymedianame] = useState('');
    const [filetypecheck, setFiletypecheck] = useState('');
    const [videofiletypecheck, setVideofiletypecheck] = useState('');
    const [disableForm, setDisableForm] = useState(true);
    const [isloading, setisLoading] = useState(false);
    const [imageURLs, setImageURLs] = useState([]);
    const [mediaImageURLs, setMediaImageURLS] = useState([])
    const [proprow, setProprow] = useState([])
    const dispatch = useDispatch();
   
    const theme = useTheme();
    const userprofile= JSON.parse(localStorage.getItem('profile')); 
    const { library } = useWeb3React();
    let proparray=[]
    let errormes = ''
    let proptype = ''
    let propvalue = ''
    let proppercent = ''
    let newarray = ''
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = useState(false);
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };
    

    const removeProperties = (e)=>{
        const rows = [...rowcount];
        rows.splice(e, 1);
        const arraytosplice = [...proprow];
        arraytosplice.splice(e,1)
        setRowcount(rows);
        setProprow(arraytosplice)
    } 

    const addProperties = ()=>{
        setRowcount([...rowcount, 1])
    }

    const handleChange = (event) => {
        const {
        target: { value },
        } = event;
        setCategory(
     
        typeof value === 'string' ? value.split(',') : value,
        );
        setValues({...values, nftcat: value});
        val('nftcat', value)
     
    };

    const handleSelect = (event) =>{
        setFee(event.target.value);
        setValues({...values, nftroyalty: event.target.value});
        val('nftroyalty', event.target.value)
    }

    const handleSelectBlockchain = async (event) =>{
        let abrv = {symb:event.target.value, contracttype: "Shared", mainnet:0, active:1 }
        let contractinfo = await dispatch(getNetworkBySymbol(abrv));
        setNetwork(event.target.value);
        setValues({...values, nftnetwork: event.target.value, nftcontract: contractinfo[0].contract });
        val('nftnetwork', event.target.value)
    }
    
    const val = (prop, input)=>{
        const name = prop ==='nftname'? input : values.nftname;
        const descript = prop ==='nftdesc'? input : values.nftdesc;
        const nftfiles = prop ==='nftfiles'? input : values.nftfiles;
        const nftcat = prop ==='nftcat'? input : values.nftcat;
        const price = prop ==='nftprice' ? input : values.nftprice;
        const quantity = prop ==='nftquantity' ? input : values.nftquantity;
        const properties = prop === 'nftproperties' ? input : values.nftproperties;
        const nftcreatorfee = prop === 'nftroyalty' ? input : values.nftroyalty;
        const nftnetwork = prop === 'nftnetwork' ? input : values.nftnetwork;
        const nftmediafiles = prop ==='nftmediafiles'? input : values.nftmediafiles;
        if(input){
            if(nftfiles){
                if (nftfiles.length!=0 && name.length!=0 && descript.length!=0 && nftmediafiles.length!=0 && nftcat.length!=0 && price.length!=0 && quantity.length!=0 && properties.length!=0 && nftcreatorfee.toString().length!=0 && nftnetwork.toString().length!=0 ){
                    setDisableForm(false)
                 }
            }else{
             if (name.length!=0 && descript.length!=0 && nftmediafiles.length!=0 && nftcat.length!=0 && price.length!=0 && quantity.length!=0 && properties.length!=0 && nftcreatorfee.toString().length!=0 && nftnetwork.toString().length!=0 ){
                setDisableForm(false)
             }else {
                setDisableForm(true)
            }}
        }else{
            setDisableForm(true)
        }
    }

    const uploadNFTpic = (nftfiles)=>{
        let filename=nftfiles[0].name;
      
        if(nftfiles.length>1){
            for (let i = 1; i < nftfiles.length; i++) {
                        filename = filename+', '+nftfiles[i].name
                }
        }
        setDisplayname(filename);

        const imagefiles = Array.from(nftfiles);
        const previewurl = imagefiles.map((imagefile)=>(
            URL.createObjectURL(imagefile)
        ))
        setImageURLs(previewurl);

        val('nftfiles', nftfiles)
        setValues({...values, nftfiles: nftfiles});
    }

    const uploadMediapic = (nftmediafiles)=>{
        let filename=nftmediafiles[0].name;
      
        if(nftmediafiles.length>1){
            for (let i = 1; i < nftmediafiles.length; i++) {
                        filename = filename+', '+nftmediafiles[i].name
                }
        }
        setDisplaymedianame(filename);
        const audiofilecheck = AudioFileFormat.find(e=>e.toUpperCase()==nftmediafiles[0].type.split('/')[1].toUpperCase());
        const videofilecheck = VideoFileFormat.find(e=>e.toUpperCase()==nftmediafiles[0].type.split('/')[1].toUpperCase());
        audiofilecheck?setFiletypecheck(audiofilecheck):setFiletypecheck('');
        videofilecheck?setVideofiletypecheck(videofilecheck):setVideofiletypecheck('');
        
        const mediafiles = Array.from(nftmediafiles);
        // const mediafiles = new ArrayBuffer(nftmediafiles);
        // const reader = new FileReader();
        // const previewmedias=reader.readAsDataURL(nftmediafiles[0]);
        // previewmedias.onload = function () {
        //     previewmedias.result
        // };
        // const audiofileblob = new Blob([previewmedias], {type: 'audio/mpeg'});
       
        // const previewmedia=reader.readAsDataURL(nftmediafiles);
        // const previewmedia= URL.createObjectURL(previewmedias)
        const previewmedia = mediafiles.map((mediafile)=>(
            URL.createObjectURL(mediafile)
        ))
        setMediaImageURLS(previewmedia);

        val('nftmediafiles', nftmediafiles)
        setValues({...values, nftmediafiles: nftmediafiles});
    }

    const handleProperty = (value, index, column)=>{
        if(column ===1 ){
            if(proprow[index]){
                let getvalue = proprow[index]
                let splitvalue = getvalue.split(':')
                let newvalue = value+':'+splitvalue[1]+':'+splitvalue[2]
                proparray=[...proprow]
                proparray[index]=newvalue
                setProprow(proparray)
            }else {
                let newvalue = value+'::'
                proparray=[...proprow]
                proparray[index]=newvalue
                setProprow(proparray)
            }
            
        }
        if(column ===2 ){
            if(proprow[index]){
                let getvalue = proprow[index]
                let splitvalue = getvalue.split(':')
                let newvalue = splitvalue[0]+':'+value+':'+splitvalue[2]
                proparray=[...proprow]
                proparray[index]=newvalue
                setProprow(proparray)
              
            }else {
                let newvalue = ':'+value+':'
                proparray=[...proprow]
                proparray[index]=newvalue
                setProprow(proparray)
            
            }
        }
        if(column ===3 ){
           if(proprow[index]){ 
                let getvalue = proprow[index]
                let splitvalue = getvalue.split(':')
                let newvalue = splitvalue[0]+':'+splitvalue[1]+':'+value
                proparray=[...proprow]
                proparray[index]=newvalue
                setProprow(proparray)
            }else {
                let newvalue = '::'+value 
                proparray=[...proprow]
                proparray[index]=newvalue
                setProprow(proparray)
            }
        }
        if(proparray){
            proparray.map((prow)=>{
                if(prow.split(':')[0]===''||prow.split(':')[1]===''||prow.split(':')[2]===''){
                    errormes = 'error'
                }
            })
            if(errormes!='error'){
                setValues({...values, nftproperties: proparray})
                val('nftproperties',1)
            }else {
                setValues({...values, nftproperties: ''})
                val('nftproperties','')
            }
        }
    } 

    const handleNumbers = (e, input)=>{
        switch (input) {
            case 'nftquantity':
                if(Integerval(e)===true){
                    setValues({...values, nftquantity:e}); 
                    val('nftquantity',e);
                }else{
                    setValues({...values, nftquantity:''}); 
                    val('nftquantity','');
                }
            break
            case 'nftprice':
                if(Numberval(e)===true){
                    setValues({...values, nftprice:e}); 
                    val('nftprice',e);
                }else{
                    setValues({...values, nftprice:''}); 
                    val('nftprice','');
                }
            break
        }
    }

    const handleSubmit= async(e)=>{
        e.preventDefault()
        setisLoading(true)
  
        values.nftproperties.map((property)=>(
                    proptype = property.split(':')[0],
                    propvalue = property.split(':')[1],
                    proppercent = property.split(':')[2],
                    newarray=[...newarray, {trait_type:proptype,
                        value:propvalue,
                        percent:proppercent
                    }]
                ));
        const fd = new FormData();
        fd.append('filespic', values.nftfiles[0]);
        fd.append('files', values.nftmediafiles[0]);
        fd.append('nftname', values.nftname);
        fd.append('nftdesc', values.nftdesc);
        fd.append('nftroyalty', values.nftroyalty);
        fd.append('newarray', JSON.stringify(newarray));
        const metadata = await dispatch(nftstoragecall(fd)).then(async(metadatavalue)=>{
            
            const metadatlink = metadatavalue.url.split('://')
            await axios.get(`https://ipfs.io/ipfs/${metadatlink[1]}`).then(async(response)=>{
                const metadataimageurl = response.data.image; 
                const metadatamediaimageurl = response.data.mediaimage; 
               
                const nftinfo = {creatorfee: values.nftroyalty, contract: values.nftcontract, metadataurl: metadatavalue.url, price: values.nftprice, connectData: userprofile, quantity: values.nftquantity};
                const mint = await MintNFTShared(nftinfo, library)
                
                if(mint === 'error'){
                    handleSnack('Transaction was not successful', 'warning')
                    setisLoading(false)
                }else{
                    const content = {nftprojectname: values.nftname, nftcontract: values.nftcontract, nftprice: values.nftprice, nftdesc: values.nftdesc, cid: metadataimageurl, meta: metadatavalue.url, nftcategory: values.nftcat.toString(), nftino: 0, nftstatus:1, nftcreatedby: userprofile, nftownedby:userprofile, nftprop:values.nftproperties.toString(), nftblockchain: values.nftnetwork, mediaimage:metadatamediaimageurl};
                        const transactioninfo = {nftaddress: values.nftcontract, txtype: 'MINT', nftmintprice: values.nftprice, nftamount: 1, seller: userprofile, buyer: userprofile, nftblockchain: values.nftnetwork, date:new Date()};
                    let MintNFTid = await MintSharedEvent(nftinfo, library, content, transactioninfo, setisLoading, handleSnack, clear, dispatch);

                }
            })
        })
        newarray='';
    }

    const clear = ()=>{
        setValues({nftname:'', nftcat:'', nftfiles:'', nftdesc:'', nftprice:'', nftquantity:'', nftproperties:'', nftroyalty:'', nftnetwork:'', nftcontract:'', nftmediafiles:''});
        setSelectedfile('');
        setDisplayname('');
        setDisplaymedianame('');
        setDisableForm(true);
        setImageURLs('');
        setMediaImageURLS('');
        setProprow('');
        setCategory([]);
        setRowcount([1]);
        setFee('');
        setNetwork('');
    }

    useEffect(() => {
        dispatch(getCats(0));
    }, []);

    return (
    <>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='collection-form-area'>
            <div className='section-title'>
                <h2>Create NFT Item</h2>
            </div>

            <div className='collection-form'>
                <form onSubmit={handleSubmit}>
                   
                    <div className='profile-outer'>
                        <h3>Upload NFT Image/Audio/Video File *</h3>
                        <div className='profileButton'>
                            <input
                            className='profileButton-input'
                            type='file'
                            name='attachments[]'
                            accept='image/png, image/jpg, image/jpeg, image/gif, video/mp4, video/webM, audio/mp3, audio/wav, audio/ogg, .glb'
                            id='uploadMedia'
                            onChange={(e)=>{uploadMediapic(e.target.files) }}
                            />
                            <label
                            className='profileButton-button ripple-effect'
                            htmlFor='uploadMedia'
                            >
                            {displaymedianame ? displaymedianame :'png, jpg, jpeg and gif, mp4, webM, mp3, wav, ogg, glb only'}
                            </label>
                            <span className='profileButton-file-name'></span>
                        </div>
                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.nftfiles.length>0?true:false}>Upload Media Image File</FormHelperText>
                    </div>

                    <div className='preview-box' hidden={mediaImageURLs?.length>0?false:true}>
                        <h3>Preview</h3>
                        <div className='previewButton'>
                            <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                            {mediaImageURLs&&mediaImageURLs.map((mediaImageURL, index)=>(     
                                    filetypecheck?(<audio key={index} src={mediaImageURL} className="img-thumbnail col-3" controls>Your browser does not support the audio element.</audio>)
                                    :videofiletypecheck?(<video key={index} src={mediaImageURL} className="img-thumbnail col-3" controls></video>):(<img key={index} src={mediaImageURL} className="img-thumbnail col-3"/>)
                            ))}
                            </div>
                        </div>
                    </div>
                    
                    <div className='profile-outer' hidden={filetypecheck.length>0?false:true}>
                        <h3>Upload Media Picture File *</h3>
                        <div className='profileButton'>
                            <input
                            className='profileButton-input'
                            type='file'
                            name='attachments[]'
                            accept='image/*'
                            id='upload'
                            onChange={(e)=>{uploadNFTpic(e.target.files) }}
                            />
                            <label
                            className='profileButton-button ripple-effect'
                            htmlFor='upload'
                            >
                            {displayname ? displayname :'png, jpg, jpeg and gif only'}
                            </label>
                            <span className='profileButton-file-name'></span>
                        </div>
                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.nftfiles.length>0?true:false}>Upload File</FormHelperText>
                    </div>

                    <div className='preview-box' hidden={filetypecheck.length>0&&imageURLs?.length>0?false:true}>
                        <h3>Preview</h3>
                        <div className='previewButton'>
                            <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                            {imageURLs&&imageURLs.map((imageURL, index)=>(     
                                    <img key={index} src={imageURL} className="img-thumbnail col-3"/>
                            ))}
                            </div>
                        </div>
                    </div>

                    <div className='collection-category'>
                        <h3>Choose Item Category *</h3>
                        <FormControl sx={{ m: 1, width: '100%'}} color='error'>
                    
                        <Select
                            labelId="demo-simple-select-chip"
                            id="demo-multiple-chip"
                            multiple
                            value={category}
                            sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="category" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, borderRadius:'15px', border:'none', borderStyle:'none' }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                            {cats?.map((cat) => (
                                <MenuItem
                                key={cat.id}
                                value={cat.name}
                                sx={{border:'none'}}
                                style={getStyles(cat.name, category, theme)}
                                >
                                {cat.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{color:'red'}} hidden={values.nftcat.length>0?true:false}>Select Categories</FormHelperText>
                        </FormControl>
                    </div>


                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>NFT Name *</label>
                                <input
                                type='text'
                                name='name'
                                id='name'
                                className='form-control'
                                placeholder='e. g. “walking in the air”'
                                onChange={(e)=>{setValues({...values, nftname:e.target.value}); val('nftname',e.target.value)}}
                                value={values.nftname}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.nftname.length>0?true:false}>Enter NFT Name</FormHelperText>
                            </div>
                            
                        </div>

                        <div className='col-lg-12 col-md-12'>
                            <div className='form-group'>
                                <label>Description *</label>
                                <textarea
                                name='description'
                                className='form-control'
                                id='description'
                                cols='30'
                                rows='5'
                                placeholder='e. g. “after purchasing you will able to get the real product”'
                                onChange={(e)=>{setValues({...values, nftdesc:e.target.value}); val('nftdesc',e.target.value)}}
                                value={values.nftdesc}
                                ></textarea>
                                <FormHelperText sx={{color:'red'}} hidden={values.nftdesc.length>0?true:false}>Enter Description</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Price in ETH/MATIC/BNB *</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='e. g. “1”'
                                onChange={(e)=>{handleNumbers(e.target.value, 'nftprice')}}
                                value={values.nftprice}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.nftprice.length>0?true:false}>Enter price. Must be a number</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Number Of Copies *</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='e. g. “1”'
                                onChange={(e)=>{handleNumbers(e.target.value, 'nftquantity')}}
                                value={values.nftquantity}
                                
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.nftquantity.length>0?true:false}>Enter number of Copies. Must be an integer</FormHelperText>
                            </div>
                        </div>

                        <div className='collection-category'>
                            <h3>Set Creator's Fee *</h3>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth color='error'>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                                        value={fee}
                            
                                        onChange={handleSelect}
                                        >
                                            {Fees?.map((feenumber, i)=>(
                                                <MenuItem sx={{border:'none'}} key={i} value={feenumber}>{feenumber}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                        <FormHelperText sx={{color:'red'}} hidden={values.nftroyalty.toString().length>0?true:false}>Set Creators Fee</FormHelperText>
                            
                                    </FormControl>
                                </Box>

                        </div>

                        <div className='collection-category'>
                            <h3>Select Blockchain *</h3>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth color='error'>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                                        value={network}
                            
                                        onChange={handleSelectBlockchain}
                                        >
                                            {Blockchains.map((network, i)=>(
                                                <MenuItem sx={{border:'none'}} key={i} value={network}>{network}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                        <FormHelperText sx={{color:'red'}} hidden={values.nftnetwork.toString().length>0?true:false}>Select Blockchain to mint NFT</FormHelperText>
                            
                                    </FormControl>
                                </Box>

                        </div>
                        
                        <h3>Properties * <FaPlusCircle onClick={addProperties} title="Add more NFT properties"/></h3>
                        <div className='form-group'>
                        {rowcount&&rowcount.map((element, index) => (
        
                            <div className="row" key={index}>
                                <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
                                    <div className='form-group'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='trait name e.g. Background'
                                        onChange={(e)=>{handleProperty(e.target.value, index, 1)}}
                                        value = {proprow[index]?proprow[index].split(':')[0]:''}
                                    />
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
                                    <div className='form-group'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='trait value e.g. blue '
                                        onChange={(e)=>{handleProperty(e.target.value, index, 2)}}
                                        value = {proprow[index]?proprow[index].split(':')[1]:''}
                                    />
                                    </div>
                                </div>

                                <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3'>
                                    <div className='form-group'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='% occurrence e.g. 100%'
                                        onChange={(e)=>{handleProperty(e.target.value, index, 3)}}
                                        value = {proprow[index]?proprow[index].split(':')[2]:''}
                                    />
                                    </div>
                                </div>
                                { index ? 
                                        <div className='col-lg-1 col-md-1 col-sm-1 col-xs-1'>
                                            <div className='form-group'>
                                                <h3><FaMinusCircle title="Delete this property" onClick={() => removeProperties(index)}/></h3>
                                            </div>
                                        </div>
                                    : null } 

                            </div>

                        ))}
                        <FormHelperText sx={{color:'red'}} hidden={values.nftproperties.length>0?true:false}>Provide Properties</FormHelperText>
                        </div>

                        <div className='col-lg-12 col-md-12'>
                            <button
                                type='submit'
                                className='default-btn border-radius-5'
                                disabled={isloading? isloading : disableForm}
                                style={{maxWidth:'140px'}}
                            >
                                {isloading?  <img src={Loading} style={{mixBlendMode: 'multiply', transform: 'scale(1.7)'}} className=""/> :"Create NFT"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
};

export default CreateNFTCardMedia;

