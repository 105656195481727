export const Quantities = [1,2,3,4,5,6,7,8,9,10,11,12];

export const Fees = [1,2,3,4,5,6,7,8,9,10,11,12];

export const Blockchains = ['BNB','ETH','MATIC'];

export const RequestOptions = ['NFTHEV Drop Request','Token Community Integration'];

export const AudioFileFormat = ['MP3', 'WAV', 'OGG', 'MPEG'];

export const VideoFileFormat = ['MP4','WebM'];





