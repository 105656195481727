import React from 'react';
import RequestDescription from '../../../components/Cards/RequestForm/RequestDescription';
import RequestForm from '../../../components/Cards/RequestForm/RequestForm';

const RequestArea = () => {
  return (
    <>
        <div className='collection-widget-area pt-70 pb-70'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <RequestDescription />
                    </div>
                    <div className='col-lg-6'>
                        <RequestForm />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
};

export default RequestArea;