import React, {useState, useEffect, Suspense, useRef} from 'react';

import axios from 'axios';
import { getNFTImageSrc, getImageSrc, getNFTMediaImageSrc } from '../../../utils/formatImage';
import { Canvas} from '@react-three/fiber'
import {OrbitControls, useGLTF} from '@react-three/drei';

// function Model({ ...props }) {
//     const group = useRef()
//     const { nodes, materials } = useGLTF('/shoe.gltf')
//     return (
//       <group ref={group} {...props} dispose={null} scale={3}>
//         <mesh geometry={nodes.shoe.geometry} material={materials.laces} material-color={props.customColors.setStripes}/>
//         <mesh geometry={nodes.shoe_1.geometry} material={materials.mesh} material-color={props.customColors.mesh}/>
//         <mesh geometry={nodes.shoe_2.geometry} material={materials.caps} material-color={props.customColors.soul}/>
//         <mesh geometry={nodes.shoe_3.geometry} material={materials.inner}material-color={props.customColors.soul} />
//         <mesh geometry={nodes.shoe_4.geometry} material={materials.sole} material-color={props.customColors.soul}/>
//         <mesh geometry={nodes.shoe_5.geometry} material={materials.stripes}material-color={props.customColors.stripes} />
//         <mesh geometry={nodes.shoe_6.geometry} material={materials.band} material-color={props.customColors.stripes}/>
//         <mesh geometry={nodes.shoe_7.geometry} material={materials.patch} material-color={props.customColors.soul}/>
//       </group>
//     )
// }

// function Model() {
//     const gltf = useGLTF('/shoe.gltf')
//     return (<primitive object={gltf.scene} />)
// }


export default function MediaDisplay({ item, drop, detail }) {

    const [getlinkmedia, setGetlinkmedia] = useState('');
    const [classinfo, setClassinfo] = useState('featured-item-preview-img trim');

    const [mesh,setMesh] = useState("#ffffff")
    const [stripes,setStripes] = useState("#ffffff")
    const [soul,setSoul] = useState("#ffffff")

    const checklink = async (link) => { 
        const mediaurl = getNFTImageSrc(link);
        const url = await axios.head(`${mediaurl}`);
        let {"content-type":contentType} = url.headers
        setGetlinkmedia(contentType.split('/')[0])
    };


    function Model() {
        const gltf = useGLTF(item.imageurl)
        return (<primitive object={gltf.scene} />)
    }
    

    
    const addDefaultSrc = (ev)=>{
          ev.target.src = '/images/logo/NoNFTimage.png'; 
    }


    
    useEffect(() => {
         checklink(item);
         detail===true? setClassinfo('trimdetail'):drop===true ? setClassinfo('trimdrop') : setClassinfo('featured-item-preview-img trim')
     }, [item]);

    

    switch(getlinkmedia){
        case "image":
            return <img className={classinfo}
                        src={getNFTImageSrc(item)}
                        alt={item.name} 
                        onError={addDefaultSrc}
                    />
        break;

        case "audio":    
            return  <div className={detail===false?"audiomargin":""}> <img className={classinfo}
                            src={getNFTMediaImageSrc(item)}
                            alt={item.name} 
                            onError={addDefaultSrc}
                            />
                            <audio controls 
                            className={detail===false?'trimaudio':classinfo}
                                src={getNFTImageSrc(item)}       
                            >
                            Your browser does not support the audio tag.</audio>
                    </div>
           
            break;

        case "video":
            return <div className={detail===false?"videomargin":""}><video className={classinfo}
                    src={getNFTImageSrc(item)}
                    controls> Your browser does not support the video tag.</video></div>
            break;
        
        case "model":
            return <div className={detail ===true ? 'trimthreed': classinfo}><div className="threed">3D Model</div><Canvas >
                        <Suspense fallback={null}>
                             <hemisphereLight intensity={0.15} groundColor='black' />
                                <ambientLight />
                                {/* <pointLight intensity={1} /> */}
                                {/* <directionalLight intensity={2} position={[0,0,50]}/> */}
                                <spotLight
                                    position={[-20, 50, 10]}
                                    angle={0.12}
                                    penumbra={1}
                                    intensity={1}
                                    castShadow
                                    shadow-mapSize={1024}
                                />
                                {/* <spotLight intensity={0.9} 
                                            angle={0.1} 
                                            penumbra={1} 
                                            position={[10,15,10]}
                                            castShadow /> */}
                                {/* <Model customColors={{mesh:mesh, stripes:stripes , soul:soul }}/> */}
                              
                                <Model/>
                                <OrbitControls enablePan={true}
                                                enableZoom={true}
                                                enableRotate={true}/>
                        </Suspense>
                    </Canvas></div>
                break;
    }

}