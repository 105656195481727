import React, {useState} from 'react';

import { useDispatch } from 'react-redux';
import {Box, MenuItem, Select, FormHelperText, FormControl} from '@mui/material';

import { HttpsCheck, Urlval } from '../../../utils/validate'
import { RequestOptions } from '../../../utils/selectOptions'

import { createRequest } from '../../../actions/request';


import Loading from "../../../images/cardicons/loading.gif";
import Snackalert from '../../Buttons/Snackalert';


const RequestForm = () => {
    const [network, setNetwork] = useState('');
    const [httpsChange, setHttpsChange] = useState({requestweb:'', requestinsta:'',requesttube:'', requestdrib:'', requestbehance:'', requesttwit:'', requesttelegram:''});
    const [values, setValues] = useState({
        requestfirstname:'', requestlastname:'', requestnickname:'', requestdesc:'', requesttoken:'', requesttwit:'', requestweb:'', requestinsta:'', requesttype:'', requesttube:'', requestdrib:'', requestbehance:'', requesttelegram:''
    });
    

    const [disableForm, setDisableForm] = useState(true);
    const [isloading, setisLoading] = useState(false);


    const userprofile= JSON.parse(localStorage.getItem('profile')); 
   
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = useState(false);
    const dispatch = useDispatch();
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };

    const handleSelectRequest = async (event) =>{
        setValues({...values, requesttype: event.target.value});
        setNetwork(event.target.value);
        val('requesttype', event.target.value)
    }
    
    const val = (prop, input)=>{
        const requestfirstname = prop ==='requestfirstname'? input : values.requestfirstname;
        const descript = prop ==='requestdesc'? input : values.requestdesc;
        const requestlastname = prop ==='requestlastname'? input : values.requestlastname;
        const requestnickname = prop ==='requestnickname'? input : values.requestnickname;
        const requesttoken = prop ==='requesttoken' ? input : values.requesttoken;
        const requesttype = prop === 'requesttype' ? input : values.requesttype;
        if(input){
            if (requestfirstname.length!=0 && descript.length!=0 && requestnickname.length!=0 && requestlastname.length!=0 && requesttoken.length!=0 && requesttype.toString().length!=0 ){
                setDisableForm(false)
            }else {
                setDisableForm(true)
            }
        }else{
            setDisableForm(true)
        }
    }   

    const handleLinks = (e, input)=>{
        switch(input){
            case 'requestweb':
                
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                       return setValues({...values, requestweb:e});  
                    }
                    else{
                        return setValues({...values, requestweb:''}); 
                    }
                }else{
                    return setValues({...values, requestweb:''}); 
                }
              
            
            case 'requestinsta':
               
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                        return setValues({...values, requestinsta:e});  
                    }
                    else{
                        return setValues({...values, requestinsta:''}); 
                    }
                }else{
                    return setValues({...values, requestinsta:''}); 
                }
            
            case 'requestdrib':
               
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                        return setValues({...values, requestdrib:e});  
                    }
                    else{
                        return setValues({...values, requestdrib:''}); 
                    }
                }else{
                    return setValues({...values, requestdrib:''}); 
                }
            
            case 'requestbehance':
               
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                        return setValues({...values, requestbehance:e});  
                    }
                    else{
                        return setValues({...values, requestbehance:''}); 
                    }
                }else{
                    return setValues({...values, requestbehance:''}); 
                }
                   

            case 'requesttwit':
               
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                        return setValues({...values, requesttwit:e});  
                    }
                    else{
                        return setValues({...values, requesttwit:''}); 
                    }
                }else{
                    return setValues({...values, requesttwit:''}); 
                }
            
            case 'requesttelegram':
               
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                        return setValues({...values, requesttelegram:e});  
                    }
                    else{
                        return setValues({...values, requesttelegram:''}); 
                    }
                }else{
                    return setValues({...values, requesttelegram:''}); 
                }
            
            default:
                if(HttpsCheck(e)===true){
                    if(Urlval(e)===false){
                        return setValues({...values, requesttube:e});  
                    }
                    else{
                        return setValues({...values, requesttube:''}); 
                    }
                }else{
                    return setValues({...values, requesttube:''}); 
                }        
        } 
    }

    const handleSubmit= async(e)=>{
        e.preventDefault()
        setisLoading(true)

       // const nftinfo = {firstnameinfo: values.requestfirstname, lastnameinfo: values.requestlastname, nicknameinfo: values.requestnickname, descinfo: values.requestdesc, tokeninfo: values.requesttoken, twitterinfo: values.requesttwit, weblinkinfo: values.requestweb, instagraminfo: values.requestinsta, requesttype:values.requesttype, requesttube: values};
        const mint = await dispatch(createRequest(values))
       
        if(mint === 'error'){
            handleSnack('Request was not sent', 'warning')
            setisLoading(false)
        }else{
            handleSnack('Your request has been sent', 'success')
            setisLoading(false)
        }
        clear();
        
    
    }

    const clear = ()=>{
        setValues({requestfirstname:'', requestlastname:'', requestnickname:'', requestdesc:'', requesttoken:'', requesttwit:'', requestweb:'', requestinsta:'', requesttype:'', requesttube:'', requestdrib:'', requestbehance:'', requesttelegram:''});
        setDisableForm(true);
        setHttpsChange({requestweb:'', requestinsta:'',requesttube:'', requestdrib:'', requestbehance:'', requesttwit:'', requesttelegram:''});
        setNetwork('')
    }

   

    return (
    <>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='collection-form-area'>
            <div className='section-title'>
                <h2>Send A Request</h2>
            </div>

            <div className='collection-category'>
                            <h3>Select A Request *</h3>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth color='error'>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                                        value={network}
                                        onChange={handleSelectRequest}
                                        >
                                            {RequestOptions.map((network, i)=>(
                                                <MenuItem sx={{border:'none'}} key={i} value={network}>{network}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                        <FormHelperText sx={{color:'red'}} hidden={values.requesttype.toString().length>0?true:false}>Select a request</FormHelperText>
                            
                                    </FormControl>
                                </Box>

                        </div>

            <div className='collection-form'>
                <form onSubmit={handleSubmit}>
            
                    


                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>First Name *</label>
                                <input
                                type='text'
                                name='firstname'
                                id='firstname'
                                className='form-control'
                                placeholder='Enter first name'
                                value={values.requestfirstname}
                                onChange={(e)=>{setValues({...values, requestfirstname:e.target.value}); val('requestfirstname',e.target.value)}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.requestfirstname.length>0?true:false}>Enter First Name</FormHelperText>
                            </div>
                            
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Last Name *</label>
                                <input
                                type='text'
                                name='lastname'
                                id='lastname'
                                className='form-control'
                                placeholder='Enter last name'
                                value={values.requestlastname}
                                onChange={(e)=>{setValues({...values, requestlastname:e.target.value}); val('requestlastname',e.target.value)}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.requestlastname.length>0?true:false}>Enter Last Name</FormHelperText>
                            </div>
                            
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Nick Name *</label>
                                <input
                                type='text'
                                name='nickname'
                                id='nickname'
                                className='form-control'
                                placeholder='Enter nick name'
                                value={values.requestnickname}
                                onChange={(e)=>{setValues({...values, requestnickname:e.target.value}); val('requestnickname',e.target.value)}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.requestnickname.length>0?true:false}>Enter Nick Name</FormHelperText>
                            </div>
                            
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Token Community </label>
                                <input
                                type='text'
                                name='token'
                                id='token'
                                className='form-control'
                                placeholder='Enter token or cryptocurrency name'
                                value={values.requesttoken}
                                onChange={(e)=>{setValues({...values, requesttoken:e.target.value}); val('requesttoken',e.target.value)}}
                                />
                            </div>
                            
                        </div>


                        <div className='col-lg-12 col-md-12'>
                            <div className='form-group'>
                                <label>Description *</label>
                                <textarea
                                name='description'
                                className='form-control'
                                id='description'
                                cols='30'
                                rows='5'
                                placeholder='Enter a description of your request'
                                value={values.requestdesc}
                                onChange={(e)=>{setValues({...values, requestdesc:e.target.value}); val('requestdesc',e.target.value)}}
                                ></textarea>
                                <FormHelperText sx={{color:'red'}} hidden={values.requestdesc.length>0?true:false}>Enter Description</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Website</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requestweb}
                                onChange={(e)=>{handleLinks(e.target.value, 'requestweb'); setHttpsChange({...httpsChange, requestweb:e.target.value});}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requestweb.length==0? true:values.requestweb.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Instagram</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requestinsta}
                                onChange={(e)=>{handleLinks(e.target.value, 'requestinsta'); setHttpsChange({...httpsChange, requestinsta:e.target.value});}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requestinsta.length==0? true:values.requestinsta.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Dribbble</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requestdrib}
                                onChange={(e)=>{handleLinks(e.target.value, 'requestdrib'); setHttpsChange({...httpsChange, requestdrib:e.target.value});}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requestdrib.length==0? true:values.requestdrib.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            </div>
                        </div>
                    
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Behance</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requestbehance}
                                onChange={(e)=>{handleLinks(e.target.value, 'requestbehance'); setHttpsChange({...httpsChange, requestbehance:e.target.value});}}                    
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requestbehance.length==0? true:values.requestbehance.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Twitter</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requesttwit}
                                onChange={(e)=>{handleLinks(e.target.value, 'requesttwit'); setHttpsChange({...httpsChange, requesttwit:e.target.value});}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requesttwit.length==0? true:values.requesttwit.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Telegram</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requesttelegram}
                                onChange={(e)=>{handleLinks(e.target.value, 'requesttelegram'); setHttpsChange({...httpsChange, requesttelegram:e.target.value});}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requesttelegram.length==0? true:values.requesttelegram.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Youtube</label>
                                <input
                                type='text'
                                className='form-control'
                                placeholder='Must begin with "https://"'
                                value={httpsChange.requesttube}
                                onChange={(e)=>{handleLinks(e.target.value, 'requesttube'); setHttpsChange({...httpsChange, requesttube:e.target.value});}}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={httpsChange.requesttube.length==0? true:values.requesttube.length>0?true:false}>Must be a website format beginning with 'https'</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12 col-md-12'>
                            <button
                                type='submit'
                                className='default-btn border-radius-5'
                                disabled={isloading? isloading : disableForm}
                                style={{maxWidth:'140px'}}
                            >
                                {isloading?  <img src={Loading} style={{mixBlendMode: 'multiply', transform: 'scale(1.7)'}} className=""/> :"Send"}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
};

export default RequestForm;
