import * as api from '../api/index.js' 


export const createSubscriber = (email) => async(dispatch) => {

    try{
        const data = await api.createSubscriber(email);
        return data;

    }catch(error){
        console.log(error.message);
    }
}