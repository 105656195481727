import React from "react";
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Snackalert=({severity, snackmess, opensnack, setOpensnack }) =>{

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpensnack(false);
    };
    
    return (
        <Snackbar open={opensnack} anchorOrigin={{ vertical:'top', horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert onClose={handleCloseSnack} severity={severity}>
                {snackmess}
            </Alert>
        </Snackbar>
    );




}


export default Snackalert;