import React from 'react';
import { CreatePage, AboutArea, PolicyArea, TermsArea, HelpCenterArea, FAQ, Marketing, TestnetInfo, ConnectWallet } from '../containers';

import { useLocation } from 'react-router-dom';


const Static = () => {
    const pathname = useLocation().pathname.split('/')[1];

    switch (pathname){
        case 'about':
            return <div><AboutArea/> </div>
        
        case 'policy':
            return <div><PolicyArea/> </div>
        
        case 'terms':
            return <div><TermsArea/> </div>

        case 'marketing':
            return <div><Marketing/></div>

        case 'help-center':
            return <div><HelpCenterArea/><FAQ/></div>

        case 'create':
            return <div><CreatePage/></div>
        
        case 'getting-started':
            return <div><TestnetInfo/></div>

        case 'connect':
            return <div><ConnectWallet/></div>

 
        default:
            return ;
    }

}

export default Static;
