import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';



const Copy = ({text}) => {
    const [copied, setCopied] = useState(true);

 
    const copytoclipboard  = ()=> {
        navigator.clipboard.writeText(text)
        setCopied(false);
        setTimeout(() => {
            setCopied(true);
            }, 1000);
    }
      
    return <i type='button' title='copy to clipboard' onClick={copytoclipboard}>{copied ? <FaRegCopy /> : <FaCheck/>} </i>
     
};

export default Copy;