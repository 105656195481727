import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DiscoverSidebarCollection = () => {
  const { cats } = useSelector((state) => state.nfts);
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState('')
  const history = useHistory();
  const query = useQuery();
  const pathname = useLocation().pathname.split('/')[2];
  
  const sortdata = (e)=>{
    const page = query.get('page') || 1;
    const cat = query.get('category');
    const searchQuery = query.get('searchQuery');
    const blockchain = query.get('blockchain');

    if(searchQuery){
      pathname ==='nftdrop' ? history.push(`/browse/nftdrop?searchQuery=${searchQuery}&page=${page}&sort=${e}`) : history.push(`/browse?searchQuery=${searchQuery}&page=${page}&sort=${e}`);
    }else if(cat){
      pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${page}&sort=${e}&category=${cat}`) : history.push(`/browse?page=${page}&sort=${e}&category=${cat}`);
    }else if(blockchain){
      pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${page}&sort=${e}&blockchain=${blockchain}`) : history.push(`/browse?page=${page}&sort=${e}&blockchain=${blockchain}`);
    }else {
      pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${page}&sort=${e}`) : history.push(`/browse?page=${page}&sort=${e}`);
    }
  }
   const sortdatabycat = (e)=>{
  
    //  const cat = query.get('category');
  
     if(e === 'NFT Drops')
     {
       history.push(`/browse/nftdrop?page=1&sort=1`)
     } else{
        pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=1&sort=1&category=${e}`) : history.push(`/browse?page=1&sort=1&category=${e}`);
     }
   }
  
  const clear = () => {
    setSearch({ search: '' });
  };

 
  const searchNFT = (e) => {
    e.preventDefault();
    if (search.trim()) {
      const page = query.get('page') || 1;
      const sort = query.get('sort') || 1;
      history.push(`/browse/collections?searchQuery=${search || 'none'}&page=${page}&sort=${sort}`);
    } else {
     history.push('/browse/collections');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchNFT(e);
    }
  };

  const handleNFTMarketRadio = (e) =>{
    setChecked('')
    e.target.value==="NFT" ? history.push(`/browse`) : history.push(`/browse/nftdrop`)
  }

  const handleNFTBlockchainRadio = (e) =>{
    const page = query.get('page') || 1;
    const sort = query.get('sort') || 1;
    setChecked(e.target.value)
    // switch(e.target.value){
      // case 'BNB':
        return  pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${1}&sort=${1}&blockchain=${e.target.value}`) : history.push(`/browse?page=${1}&sort=${1}&blockchain=${e.target.value}`);
    //   case 'ETH':
    //     return pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${page}&sort=${sort}&blockchain=${e.target.value}`) ;
    //   case 'MATIC':
    //     return pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${page}&sort=${sort}&blockchain=${e.target.value}`);
    //   default: 
    //     return pathname ==='nftdrop' ? history.push(`/browse/nftdrop?page=${page}&sort=${sort}&blockchain=${e.target.value}`)
    // }
    // e.target.value==="BNB" ? history.push(`/browse`) : history.push(`/browse/nftdrop`)
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  return (
    <>
      <div className='side-bar-area pl-20'>
        <div className='side-bar-widget'>
          <h3 className='title'>
            Filter <a href={pathname ==='collections' ? '/browse/collections' : '/browse'}>Clear All</a>
          </h3>
          <form className='search-form' onSubmit={searchNFT}>
          
            <input
              type='search'
              className='form-control'
              placeholder='Search keyword'
              onKeyDown={handleKeyPress}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <button type='submit'>
              <i className='ri-search-line'></i>
            </button>
          </form>
        </div>

        <div className='side-bar-widget'>
          <h3 className='title'>Sort By</h3>
          <div className='form-group select-group'>
            <select className='form-select form-control' onChange={(e)=>sortdata(e.target.value)}>
              <option value='0' data-display='Sort By (Default)'>
                Sort By (Most Relevant)
              </option>
              <option value='1'>Recently Sold</option>
              <option value='2'>Name: A-Z</option>
              <option value='3'>Name: Z-A</option>
              <option value='4'>Recently Listed</option>
              <option value='5'>Oldest Listing</option>
            </select>
          </div>
        </div>

        <div className='checkbox-method-area'>
          <h3 className='title'>Sale Type</h3>
          <div className='col-lg-12 col-md-12'>
            <div className='checkbox-method'>
              <p>
                <input
                  type='radio'
                  id='fixed-price'
                  name='radio-group'
                  value="NFT"
                  onChange={(e)=>handleNFTMarketRadio(e)}
                  
                />
                <label htmlFor='fixed-price'>NFT</label>
              </p>
            </div>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='checkbox-method'>
              <p>
                <input
                  type='radio'
                  id='timed-auction'
                  name='radio-group'
                  value="NFT Drop"
                  onChange={(e)=>handleNFTMarketRadio(e)}
                  
                />
                <label htmlFor='timed-auction'>NFT Drop</label>
              </p>
            </div>
          </div>
        </div>

       

       

        


      </div>
    </>
  );
};

export default DiscoverSidebarCollection;
