import * as api from '../api/index.js'  

export const createRequest = (requestinfo) => async(dispatch) => {

    try{
        await api.createRequest(requestinfo);
        return "request submitted"

    }catch(error){
        console.log(error.message);
    }
}




