import {  FETCH_USER, FETCH_CONNECTED_USER, FETCH_OWNER } from '../constants/actionTypes';

const users = (state = { isLoading: true, nfts: [] }, action) => {
  switch (action.type) {  

    case 'START_LOADING':
      return { ...state, isLoading: true };
      
    case 'END_LOADING':
      return { ...state, isLoading: false };

    case FETCH_USER:
      return {
        ...state,
        user: action.payload.data,
      };
    
    case FETCH_CONNECTED_USER:
      return {
        ...state,
        connecteduser: action.payload.data,
      };
    
    case FETCH_OWNER:
      return {
        ...state,
        nftowner: action.payload.data,
      };
  
    default:
      return state;
  }
};

export default users;