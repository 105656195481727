import * as api from '../api/index.js' 
import {FETCH_TRANSACTIONS, FETCH_DROPTRANSACTIONS, START_LOADING, END_LOADING } from '../constants/actionTypes.js' 

export const createTransaction = (txdata) => async(dispatch) => {

    try{
        
        await api.createTransaction(txdata);
       
       return 

    }catch(error){
        console.log(error.message);
    }
}

export const getTransactions = (transdata) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
  
      const { data } = await api.getTransactions(transdata);
  
  
      dispatch({ type: FETCH_TRANSACTIONS, payload: {data} });
      dispatch({ type: END_LOADING });
  
    } catch (error) {
      console.log(error);
    }
  };


export const getNFTDropTransactions = (contract) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });

      const { data } = await api.getNFTDropTransactions(contract);
      dispatch({ type: FETCH_DROPTRANSACTIONS, payload: {data} });
      dispatch({ type: END_LOADING });
  
    } catch (error) {
      console.log(error);
    }
};

