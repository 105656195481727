import {  FETCH_COLLECTION, FETCH_OWNER_COLLECTION, FETCH_FEATURED_COLLECTION, FETCH_ALL_COLLECTION } from '../constants/actionTypes';

const collections = (state = { collections: null, ownerscollections: null, collection: null, isLoadingCollection: true }, action) => {
  switch (action.type) {  

    case 'START_LOADING':
      return { ...state, isLoadingCollection: true };
      
    case 'END_LOADING':
      return { ...state, isLoadingCollection: false };

    case FETCH_COLLECTION:
      return {
        ...state,
        collection: action.payload.data,
      };
    case FETCH_OWNER_COLLECTION:
      return{
        ...state, 
        ownerscollections: action.payload.data,
        currentPageCollection: action.payload.currentPage,
        numberOfPagesCollection: action.payload.numberOfPages,
      };
    case FETCH_FEATURED_COLLECTION:
      return {
        ...state,
        topfeaturedcollections: action.payload.data,
      };
    
    case FETCH_ALL_COLLECTION:
    return {
      ...state,
      collections: action.payload.data,
      currentPageCollection: action.payload.currentPage,
      numberOfPagesCollection: action.payload.numberOfPages,
    };
  
    default:
      return state;
  }
};

export default collections;