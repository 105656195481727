import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch } from 'react-redux';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Numberval } from '../../../utils/validate';
import { fShortenNumber } from '../../../utils/formatNumber';
import { getCollectionImageSrc } from '../../../utils/formatImage';
import { getOwner } from '../../../actions/users';




const CollectionCard = ({floor}) => {

  const {collection} = useSelector((state) => state.collections);
  const {nftowner} = useSelector((state) => state.users);
  //const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const dispatch = useDispatch();
 

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimage.png'
  }

  // useEffect(() => {
  //     dispatch(getOwner(collection?.owner));
  // }, [collection]);

  // const getImageSrc = (acc)=>{
  //   if(acc){
  //     const checkhttp = acc.thumbnail?.split('://')[0]
  //     const checkext = acc.thumbnail?.split('.')[1]
  //     if(checkhttp ==="https"){
  //       return acc
  //     }else if (checkhttp==="ipfs"){
  //       return `https://ipfs.infura.io/ipfs/${acc.thumbnail?.split('//')[1]}`
  //     }else if (checkext ==="png"){
  //       return `../images/collection/${acc?.name}/${acc.thumbnail}`
  //     }else{
  //       return 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'
  //     }
  //   }else{
  //     return 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'
  //   }
    
  // }

 

  return (
    <>
      <div className='author-profile-sidebar  mr-20'>
        <div className='author-user'>
          
          <img
              src={getCollectionImageSrc(collection, true)}
              alt={collection?.name} 
              onError={addDefaultSrc}
          />
          
        </div>

        <h3>
          {/* <a href='/collection-profile'> */}
           {collection?.name}
          {/* </a> */}
        </h3>
        <span>By <a href={`/user/${nftowner?.wallet_address}`}>{nftowner?.username}</a></span>
        <p>
          {collection?.description}
        </p>
        
        <div className='author-content'>
         
          <div className='connection-content-left'>
              <span>Floor Price</span>
              <h4>{Numberval(floor)===true ? fShortenNumber(floor):'---'} BNB</h4>
          </div>


          <div className='content-right'>
            Follow
            <ul className='author-social'>
              <li>
                <a href={collection?.fb} target='_blank' rel="noreferrer">
                  <i> <FaFacebookF/> </i>
                </a>
              </li>
              <li>
                <a href={collection?.instagram} target='_blank' rel="noreferrer">
                  <i> <FaInstagram/> </i>
                </a>
              </li>
              <li>
                <a href={collection?.twitter} target='_blank' rel="noreferrer">
                  <i> <FaTwitter/> </i>
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
        
    </>
  );
};

export default CollectionCard;
