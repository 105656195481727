import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import AuthorProfile from '../../../components/Cards/AuthorProfile/AuthorProfile';
import MyNFTCard from '../../../components/Cards/MyNFTCard/MyNFTCard';
import CollectionInfoCard from '../../../components/Cards/Collections/CollectionInfoCard';
import Pagination from './Pagination';
import { useHistory, useLocation } from 'react-router-dom';


const MyNFTcontainer = () => {
    const history = useHistory();
    const query = useQuery();
    const page = query.get('page') || 1;
    const sort = query.get('sort') || 1;
    const searchQuery = query.get('searchQuery');
    const { nftcontracts } = useSelector((state) => state.nfts);
    const {connecteduser} = useSelector((state) => state.users);
    const { connectData } = useSelector((state) => state.connect);
    const { ownerscollections } = useSelector((state)=> state.collections);
    const [search, setSearch] = useState('');
    const pathname = useLocation().pathname.split('/')[2];

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const sortdata = (e)=>{
        const page = query.get('page') || 1
        const searchQuery = query.get('searchQuery');

        if(searchQuery){
            if(pathname === 'collections'){
                history.push(`/mynfts/collections?searchQuery=${searchQuery}&page=${page}&sort=${e}`);
            }else{
                history.push(`/mynfts?searchQuery=${searchQuery}&page=${page}&sort=${e}`);
            }
        }else {
            if(pathname === 'collections'){
                history.push(`/mynfts/collections?page=${page}&sort=${e}`);
            }else{
                history.push(`/mynfts?page=${page}&sort=${e}`);
            }
        }
    }

    const searchNFT = (e) => {
        e.preventDefault();
        if (search.trim()) {
            const page = query.get('page') || 1;
            const sort = query.get('sort') || 1;
            if(pathname === 'collections'){
                history.push(`/mynfts/collections?searchQuery=${search || 'none'}&page=${page}&sort=${sort}`);
            }else{
                history.push(`/mynfts?searchQuery=${search || 'none'}&page=${page}&sort=${sort}`);
            }
        } else {
            if(pathname === 'collections'){
                history.push(`/mynfts/collections`);
            }else{
                history.push(`/mynfts`);
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
        searchNFT(e);
        }
    };
    
    if(connectData!=='connected'){
        return(
            <div className='item-details-area pt-100 pb-70'>
                <div className='container'>
                    <div className='row'>
                        <h1> Connect wallet to see your NFTs</h1>
                    </div>
                </div>
            </div>
        )
    }

    return (
        
    <>
        <div className='collection-widget-area pt-70 pb-70'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3'>
                
                        <AuthorProfile />
                        <div className='side-bar-area pl-10'>
                            <div className='side-bar-widget'>
                                <h3 className='title'>
                                    Filter<a href={pathname==='collections'? '/mynfts/collections':'/mynfts'}>Clear All</a>
                                </h3>
                                <form className='search-form' onSubmit={searchNFT}>
                                
                                    <input
                                    type='search'
                                    className='form-control'
                                    placeholder='Search keyword'
                                    onKeyDown={handleKeyPress}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    />
                                    <button type='submit'>
                                    <i className='ri-search-line'></i>
                                    </button>
                                   
                                </form>
                            </div>
                            { pathname === 'collections'? 
                                <div className='side-bar-widget'>
                                <h3 className='title'>Sort By</h3>
                                <div className='form-group select-group'>
                                    <select className='form-select form-control' onChange={(e)=>sortdata(e.target.value)}>
                                        <option value='0' data-display='Sort By (Default)'>
                                            Sort By (Most Relevant)
                                        </option>
                                        <option value='1'>Most Relevant</option>
                                        <option value='2'>Name: A-Z</option>
                                        <option value='3'>Name: Z-A</option>
                                        <option value='4'>Recently Updated</option>
                                        <option value='5'>Oldest Update</option>
                                        <option value='6'>Recently Listed</option>
                                        <option value='7'>Oldest Listing</option>
                                    </select>
                                </div>
                            </div>
                             :
                            <div className='side-bar-widget'>
                                <h3 className='title'>Sort By</h3>
                                <div className='form-group select-group'>
                                    <select className='form-select form-control' onChange={(e)=>sortdata(e.target.value)}>
                                        <option value='0' data-display='Sort By (Default)'>
                                            Sort By (Most Relevant)
                                        </option>
                                        <option value='1'>Recently Sold</option>
                                        <option value='2'>Name: A-Z</option>
                                        <option value='3'>Name: Z-A</option>
                                        <option value='4'>Price: High to Low</option>
                                        <option value='5'>Price: Low to High</option>
                                        <option value='6'>Recently Listed</option>
                                        <option value='7'>Oldest Listing</option>
                                        <option value='8'>Most Liked</option>
                                    </select>
                                </div>
                            </div>

                            }   
                        </div>
                    </div>

                    <div className='col-lg-9'>
                        {!pathname &&connecteduser ? 
                            <div className='row justify-content-center'>
                            {nftcontracts?.map((nft) => (
                                <MyNFTCard key={nft.id} item={nft} />
                
                            ))}
                            <Pagination page={page} sort={sort} searchQuery={searchQuery} />
                            </div>
                            :
                            pathname==='collections' && connecteduser ?  
                            <div className='row justify-content-center'>
                            
                            {ownerscollections?.map((collection) => (
                                <CollectionInfoCard key={collection.id} item={collection} user={connecteduser}/>
                                
                            ))}
                            <Pagination page={page} sort={sort} searchQuery={searchQuery} />
                            </div>
                            :
                            pathname==='collections' && !connecteduser? 
                            <h1> No Collection Found</h1>
                            :
                            <h1> No NFT Found</h1>
                        }

                         

                    </div>

                  
                </div>
            </div>
        </div>
    </>
    );
};

export default MyNFTcontainer;