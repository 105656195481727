import React, {useState, useRef, useEffect} from 'react';
import { Button, Box, Avatar, Typography, TextField, TextareaAutosize, Switch, FormControlLabel, FormGroup } from '@mui/material';
import {useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GiCheckMark } from "react-icons/gi";
import { FaFacebookF, FaTwitter, FaInstagram, FaRegCopy, FaUserEdit, FaUser, FaLink } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import { MdOutlineDescription } from 'react-icons/md';
import Copy from '../../Buttons/Copy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import { updateUser, getConnectedUser, uploadUserImage } from '../../../actions/users';
import { rProfileImagef, getImageSrc } from '../../../utils/formatImage';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AuthorProfile = ({item}) => {
  const {user, connecteduser} = useSelector((state) => state.users);
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const [open, setOpen] = React.useState(false);
  const [disableForm, setDisableForm] = useState(true);

  const [checked, setChecked] = useState(true);
  const [label, setLabel] = useState('Add Profile Picture Link')
  const [selectedfile, setSelectedfile] = useState('');
 
  const [values, setValues] = useState({
        facebookhandle:connecteduser?.fb, instahandle:connecteduser?.instagram, twithandle:connecteduser?.twitter, profiledesc:connecteduser?.description, profileusername:connecteduser?.username, profilepic:connecteduser?.image, profilepicfile:connecteduser?.imagefile, profileacc:connecteduser?.wallet_address
         });
  const dispatch = useDispatch();
  const ref = useRef();
  
  const pathname = useLocation().pathname.split('/')[1];
 
  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimage.png'
   }

  const setprofilepic = (name)=>{
    const fileext = String(name.split('.')[1]);
    if(fileext != 'gif'){
      setValues({...values, profilepicfile: `profilepic.png`});
    }else {
      setValues({...values, profilepicfile: `profilepic.${fileext}`});
    }
  }

  const editprofile = async (e)=>{
    e.preventDefault();
  
    if(selectedfile){
      
       if(selectedfile.type==='image/png' || selectedfile.type==='image/jpeg' || selectedfile.type==='image/jpg' || selectedfile.type==='image/gif'){
          if(selectedfile.size<500000){
            let imageresized 
            if(selectedfile.type==='image/gif'){
               imageresized = selectedfile
            }else{
               imageresized = await rProfileImagef(selectedfile); 
            } 
            
            const fd = new FormData();
         
            fd.append('files', imageresized);
            fd.append('acc', values.profileacc);
            

            dispatch(uploadUserImage(fd))
          } 
       }
    }
    dispatch(updateUser(values));
    setOpen(false);
  }

  const handleClickModal = () => {
      setOpen(true);
  };
  
  const handleClose = () => {
      setValues({ facebookhandle:connecteduser?.fb, instahandle:connecteduser?.instagram, twithandle:connecteduser?.twitter, profiledesc:connecteduser?.description, profileusername:connecteduser?.username, profilepic:connecteduser.image, profilepicfile:connecteduser.imagefile, profileacc:userprofile});
      setOpen(false);
  };

  const handleToggle = (event) =>{
    setChecked(event.target.checked);
    if(event.target.checked ===true){
      setLabel('Add Profile Picture Link');
      ref.current.value = ""
      setSelectedfile('')
      setValues({...values, profilepicfile: ''});
    }else{
      setLabel('Upload Profile Picture');
      setValues({...values, profilepic: ''})
    }
  }
  const handleChange = (prop) => (event) => {
          setValues({...values, [prop]: event.target.value})
        
        if(event.target.value.length!=0) {setDisableForm(false)} else {setDisableForm(true)}
    };

  useEffect(() => {
   
    setValues({ facebookhandle:connecteduser?.fb, instahandle:connecteduser?.instagram, twithandle:connecteduser?.twitter, profiledesc:connecteduser?.description, profileusername:connecteduser?.username, profilepic:connecteduser?.image, profileacc:connecteduser?.wallet_address });
  }, [connecteduser]);

 
  if(!connecteduser){
    return (
      <>
      <div className='author-profile-sidebar  mr-20'>
        <div className='author-user'>
          <img
            src={'/images/logo/NoNFTimage.png'}
            alt='create profile' 
            onError={addDefaultSrc}
          />
        </div>
        {pathname!='profile' && <h3>
          <a href='/profile/create'>
           Click to create profile
          </a>
        </h3>
        }
      </div>
      </>

    )
  }


  return (
    <>
      <div className='author-profile-sidebar  mr-20'>
        <div className='author-user'>
          
          <img
            src={getImageSrc(connecteduser)}
             alt={connecteduser?.username} 
            onError={addDefaultSrc}
          />
          {connecteduser?.verified===1&&<i title='user has been verified'><GiCheckMark/></i>}
          
        </div>

        <h3>
          <a href={`/user/${connecteduser?.wallet_address}`}>
           {connecteduser?.username}
          </a>
        </h3>
      
        <p>
          {connecteduser?.description}
        </p>
        <div className='sp-title'>
          {connecteduser?.wallet_address?.split('').splice(0, 8).join('')}{'...'}{connecteduser?.wallet_address.split('').splice(35)} <Copy text={connecteduser?.wallet_address} />
        </div>
        <div className='author-content'>
          {userprofile.toLowerCase()===connecteduser.wallet_address.toLowerCase()&&
            <div className='content-left' onClick={handleClickModal} title='edit profile'>
                  <i> <FaUserEdit/> </i>
            </div>
          }

          <div className='content-right'>
            Follow
            <ul className='author-social'>
              <li>
                <a href={connecteduser?.fb} target='_blank' rel="noreferrer">
                  <i> <FaFacebookF/> </i>
                </a>
              </li>
              <li>
                <a href={connecteduser?.instagram} target='_blank' rel="noreferrer">
                  <i> <FaInstagram/> </i>
                </a>
              </li>
              <li>
                <a href={connecteduser?.twitter} target='_blank' rel="noreferrer">
                  <i> <FaTwitter/> </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit {connecteduser.username}'s Profile</DialogTitle>
          <DialogContent>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Avatar sx={{ width: 30, height: 30 }}> <FaUser/> </Avatar>
                  <TextField fullWidth sx={{marginLeft:1}} id="input-username" label="username" variant="standard" value={values.profileusername} onChange={handleChange('profileusername')}/>
                  <Typography variant="subtitle2"> Username</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Avatar sx={{ width: 30, height: 30 }}> <MdOutlineDescription/> </Avatar>
                  <TextareaAutosize
                    fullWidth
                    minRows={4}
                    aria-label="maximum height"
                    placeholder="Tell us about this user"
                    style={{ width: 400, marginTop: 30, marginLeft:7, marginRight:3 }}
                    value={values.profiledesc} 
                    onChange={handleChange('profiledesc')}
                  />
                  <Typography variant="subtitle2"> About this user</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Avatar sx={{ width: 30, height: 30 }}> <FaFacebookF/> </Avatar>
                  <TextField fullWidth sx={{marginLeft:1}} id="input-facebook" label="facebook" helperText="link must start with https://" variant="standard" value={values.facebookhandle} onChange={handleChange('facebookhandle')}/>
                  <Typography variant="subtitle2"> Facebook</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Avatar sx={{ width: 30, height: 30 }}> <FaInstagram/> </Avatar>
                  <TextField fullWidth sx={{marginLeft:1}} id="input-instagram" label="instagram" helperText="link must start with https://" variant="standard" value={values.instahandle} onChange={handleChange('instahandle')}/>
                  <Typography variant="subtitle2"> Instagram</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Avatar sx={{ width: 30, height: 30 }}> <FaTwitter/> </Avatar>
                  <TextField fullWidth sx={{marginLeft:1}} id="input-twitter" label="twitter" helperText="link must start with https://" variant="standard" value={values.twithandle} onChange={handleChange('twithandle')}/>
                  <Typography variant="subtitle2"> Twitter</Typography>
              </Box>

              <Box sx={{marginTop:3}} >
                <Typography variant="subtitle2"> Toggle Swith to either upload pic or add profile link</Typography>
                <FormControlLabel control={<Switch checked={checked} onChange={handleToggle} inputProps={{ 'aria-label': 'controlled' }} />} label={label}/>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'flex-end'}} hidden={checked===true ? false : true}>
                  <Avatar sx={{ width: 30, height: 30 }}> <FaLink/> </Avatar>
                  <TextField fullWidth sx={{marginLeft:1}} id="input-piclink" placeholder="https:// or ipfs://" helperText="link must start with https:// or ipfs://" label="https:// or ipfs://" variant="standard" value={checked===false? '': values.profilepic} onChange={handleChange('profilepic')}/>
                  <Typography variant="subtitle2"> Picture Link</Typography>
              </Box>

              <Box hidden={checked} sx={{ display: 'flex', alignItems: 'flex-end', marginTop:3 }}>
                <Avatar sx={{ width: 30, height: 30, marginRight:1 }}> <FiUpload/> </Avatar>
                <input ref={ref} fullWidth title=".png, .jpg, .jpeg only" type="file" name="fileupload" onChange={(e)=>{setSelectedfile(e.target.files[0]); setprofilepic(e.target.files[0].name); if(values.length!=0) {setDisableForm(false)} else {setDisableForm(true)} }} />
                <Typography variant="subtitle2"> Upload profile picture</Typography>
              </Box> 
             
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button disabled={disableForm} onClick={(e)=>{editprofile(e)}}>Edit Profile</Button>
             
          </DialogActions>
      </Dialog>
     
    </>
  );
};

export default AuthorProfile;
