import React from 'react';
import { useLocation } from 'react-router-dom';
import { GiCheckMark } from "react-icons/gi";
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import Copy from '../../Buttons/Copy';

import { getImageSrc } from '../../../utils/formatImage';


//const profileimage = require('profilepic.png');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  //bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AuthorUserProfile = ({item}) => {
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const pathname = useLocation().pathname.split('/')[2];
  //console.log(pathname)
 
  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimage.png'
  }

  //  if(!item){
    if(!item && userprofile && userprofile.toLowerCase()===pathname.toLowerCase()){
      return (
        <>
        <div className='author-profile-sidebar  mr-20'>
          <div className='author-user'>
            <img
              src={'/images/logo/NoNFTimage.png'}
              alt='create profile' 
              onError={addDefaultSrc}
            />
          </div>
          {pathname!='profile' && <h3>
            <a href='/profile/create'>
             Click to create profile
            </a>
          </h3>
          }
        </div>
        </>
      )
    }
    // }else{
    if(!item){
      return (
        <>
        <div className='author-profile-sidebar  mr-20'>
          <div className='author-user'>
            <img
              src={'/images/logo/NoNFTimage.png'}       
              alt='create profile' 
              onError={addDefaultSrc}
            />
          </div>
          {/* {pathname!='profile' && <h3>
            <a href='/profile/create'> */}
            This user has not created a profile
            {/* </a>
          </h3>
          } */}
        </div>
        </>

      )
    }
  // }


  

  return (
    <>
      <div className='author-profile-sidebar  mr-20'>
        <div className='author-user'>
          
          <img
              src={getImageSrc(item)}
              alt={item?.username} 
              onError={addDefaultSrc}
          />
          {item?.verified===1&&<i title='user has been verified'><GiCheckMark/></i>}
          
        </div>

        <h3>
          <a href={`/user/${item?.wallet_address}`}>
           {item?.username}
          </a>
        </h3>
        {/* <span>@Jenar</span> */}
        <p>
          {item?.description}
        </p>
        <div className='sp-title'>
          {item?.wallet_address?.split('').splice(0, 8).join('')}{'...'}{item?.wallet_address?.split('').splice(35)} <Copy text={item?.wallet_address} />
        </div>
        <div className='author-content'>

          <div className='content-right'>
            Follow
            <ul className='author-social'>
              <li>
                <a href={item?.fb} target='_blank' rel="noreferrer">
                  <i> <FaFacebookF/> </i>
                </a>
              </li>
              <li>
                <a href={item?.instagram} target='_blank' rel="noreferrer">
                  <i> <FaInstagram/> </i>
                </a>
              </li>
              <li>
                <a href={item?.twitter} target='_blank' rel="noreferrer">
                  <i> <FaTwitter/> </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorUserProfile;
