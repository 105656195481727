import React from 'react';
import AuthorProfile from '../../../components/Cards/AuthorProfile/AuthorProfile';
import CreateProfileCard from '../../../components/Cards/CreateProfileCard/CreateProfileCard';

const CreateUser = () => {
  return (
    <>
        <div className='collection-widget-area pt-70 pb-70'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <AuthorProfile />
                    </div>
                    <div className='col-lg-9'>
                        <CreateProfileCard />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
};

export default CreateUser;