import React, { useState, useEffect } from 'react';
import { BiHeart } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import {MenuItem, Select, FormControl, Stack} from '@mui/material';
import { likeNFTDrop } from '../../../actions/nfts';

import { MintNFTDropBNB, MintNFTDropKYC, CheckPrice } from '../../Blockchain/MintBuySell';
import { MintDropEvent } from '../../Blockchain/Events';
import { fNumber2decimal, fShortenNumber } from '../../../utils/formatNumber'
import { Quantities } from '../../../utils/selectOptions'
import Loading from "../../../images/cardicons/loading.gif";
import { getImageSrc, getNFTImageSrc }  from '../../../utils/formatImage';
import { useWeb3React } from "@web3-react/core";
import { injected } from '../../Blockchain/Connector';
import * as actionType from '../../../constants/actionTypes';
import { getUser } from '../../../api/index.js';
import {fDateyy} from '../../../utils/formatTime'

import Snackalert from '../../Buttons/Snackalert';
import MediaDisplay from '../MediaDisplay/MediaDisplay';


const NFTDrop = ({ item, col }) => {
  const {connecteduser} = useSelector((state) => state.users);
  const {connectData} = useSelector((state) => state.connect);
  const dispatch = useDispatch(); 
  const {library, activate} = useWeb3React();
  const [loadingBNB, setLoadingBNB] = useState(false);
  const [loadingKYC, setLoadingKYC] = useState(false);
  const [userData, setUserData]= useState(undefined);
  const [kycprice, setKycprice] = useState('');
  const [snackmess, setSnackmess] = useState(undefined); 
  const [severity, setSeverity] = useState('success');
  const [opensnack, setOpensnack] = React.useState(false);
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const [mintquantity, setMintquantity] = useState(1);
  const [likecount, setLikecount] = useState('');
  let now = new Date();
  let parsenow = Date.parse(now) / 1000
  
  const handleSnack = (mess, severity) => {
      setOpensnack(true);
      setSnackmess(mess);
      setSeverity(severity);
  };
  const Likes = () => { 
      if(connectData){
          return connecteduser?.dropslikes?.split(',').find((like) => like === item.id.toString()) 
          ? (
                  <><p style={{display: 'inline-flex'}}>
                  <i title='you have liked this item'><BiHeart /> </i>
                  {/* {item.likes} */}
                  {likecount? likecount : item?.likes}
                  </p>
                  </>
          ) : (
            !likecount?
              <><p style={{display: 'inline-flex'}}>
                  <i type='button' onClick={() => dispatch(likeNFTDrop(item.id, setLikecount))}><BiHeart /> </i>
                  {/* {item.likes} */}
                  {likecount? likecount : item?.likes}
                  </p>
                  </>
              :
              <><p>
                <i title='you have liked this item'><BiHeart /> </i>
                {likecount? likecount : item?.likes}
                </p>
              </>
          )
      }
      return  <><p style={{display: 'inline-flex'}}>
                  <i title='connect wallet to like item'><BiHeart/> </i>
                  {/* {item.likes} */}
                  {likecount? likecount : item?.likes}
                </p></>;
  };

  const addDefaultSrc = (ev)=>{
      ev.target.src = '/images/logo/NoNFTimage.png'
  }

  const handleSelect = (event) =>{
    setMintquantity(event.target.value);
   
  }

  const buyDropWithBNB = async(saledata)=>{
      setLoadingBNB(true);
      const nftinfo = {contract: saledata.contract, metadataurl: saledata.metadataurl, mintprice: saledata.price, connectData: userprofile, quantity:mintquantity};
      await MintNFTDropBNB(nftinfo, library).then(
        async (res)=> {
             if(!res || res===false){
                 res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
                 setLoadingBNB(false);
                 
             } else{
                const content = {nftprojectname: saledata.name, nftcontract: saledata.contract, nftprice: saledata.price, nftdesc: saledata.description, cid:saledata.imageurl , meta: saledata.metadataurl, nftcategory: saledata.category, nftino: 0, nftcreatedby: saledata.owner, nftownedby:userprofile, nftstatus:1, nftprop:saledata.properties, nftblockchain: saledata.chain, nftcollectionname:saledata.collection, nftcollectionid:saledata.collectionid};     
                const transactioninfo = {dropid: saledata.id, nftaddress: saledata.contract, txtype: 'MINT', nftmintprice: saledata.price, nftamount: 1, seller: saledata.owner, buyer: userprofile, nftblockchain: saledata.chain, date:new Date()}; 
                const remainingquantity = saledata.quantity_left - mintquantity > 0 ? saledata.quantity_left - mintquantity : 0;
                const updatequantity = {quanityleft:remainingquantity, id:saledata.id}
                let MintNFTid = await MintDropEvent(nftinfo, library, content, transactioninfo, updatequantity, setLoadingBNB, handleSnack, dispatch);
               
             }
     });

  }

  const buyDropWithKYC = async(saledata)=>{
      setLoadingKYC(true);
      const nftinfo = {contract: saledata.contract, metadataurl: saledata.metadataurl, mintprice: saledata.price, connectData: userprofile, quantity:mintquantity };
      await MintNFTDropKYC(nftinfo, library).then(
       async (res)=> {
            if(!res || res!=true){
                res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
                setLoadingKYC(false)
               
            } else{
              
                      const content = {nftprojectname: saledata.name, nftcontract: saledata.contract, nftprice: saledata.price, nftdesc: saledata.description, cid:saledata.imageurl , meta: saledata.metadataurl, nftcategory: saledata.category, nftino: 0, nftcreatedby: saledata.owner, nftownedby:userprofile, nftstatus:1, nftprop:saledata.properties, nftblockchain: saledata.chain,  nftcollectionname:saledata.collection, nftcollectionid:saledata.collectionid};
                      const transactioninfo = {dropid: saledata.id, nftaddress: saledata.contract, txtype: 'MINT', nftmintprice: saledata.price, nftamount: 1, seller: saledata.owner, buyer: userprofile, nftblockchain: saledata.chain, date:new Date()};
                      const remainingquantity = saledata.quantity_left - mintquantity > 0 ? saledata.quantity_left - mintquantity : 0;
                      const updatequantity = {quanityleft:remainingquantity, id:saledata.id}
                      let MintNFTid = await MintDropEvent(nftinfo, library, content, transactioninfo, updatequantity, setLoadingKYC, handleSnack, dispatch);
                     
                  
            }
       }
      );
      
  }

  const ConnecttoWallet = ()=> {
    async function connetweb3wallet(){
      try {
          await activate(injected)
          dispatch({ type: actionType.CONNECT, payload:'connected' });
      } catch (ex) {
        console.log(ex)
      }
    }
    connetweb3wallet()

  }

  const getKYCPrice = (price)=>{
    async function getKYCPriceInfo(){
      if(price){
          let NFTprice;
          NFTprice = await CheckPrice(price, library);
          NFTprice = fNumber2decimal(NFTprice)
      
          setKycprice(NFTprice)
      }
    }
    getKYCPriceInfo()
  }


  useEffect(() => {
    async function getandsetUser(){
      if(item.owner.length>0){     
        const userinfo = await getUser(item.owner);
        setUserData(userinfo.data)
      }
    }
    getandsetUser()
  }, [item.owner]);
  
//   useEffect(() => {
//     if (connectData) {
//      getKYCPrice(item.price)
//     }
//  }, [item.price, connectData]);

useEffect(()=>{

},[dispatch, item.likes])

  return (
    <>
      <div className={col===4? 'col-lg-4 col-md-6' :col===3?'col-lg-3 col-md-6':'col-lg-12 col-md-12 p-1'} > 
      <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='auction-card'>
          <div className='auction-card-img'>
              <a href={`/detail/nftdrop/${item.id}/${item.contract}`}>
                <MediaDisplay item={item} drop={true} detail={false}/>
                {/* <img className='trimdrop'
                  src={getNFTImageSrc(item)}
                  alt={item.name} 
                  onError={addDefaultSrc}
                /> */}
              </a>
          
            <div className='auction-card-user'>
                <a href={`/user/${userData?.wallet_address}`} className='auction-card-user-option'>
                  <img
                      src={getImageSrc(userData)}
                      alt={userData?.username} 
                      onError={addDefaultSrc}
                  />
                  <span>Created by {userData?.username.split('').splice(0, 15).join('')}{userData?.username.split('').length > 15 && '...' }</span>
                </a>
             
            </div>
            
          </div>

          <div className='content'>
            <h3>
              <a href={`/detail/nftdrop/${item.id}/${item.contract}`}><span style={{overflow: 'hidden',WebkitLineClamp: 1, display: '-webkit-box',WebkitBoxOrient: 'vertical'}}>{item.name}</span></a>
              
            </h3>

            <div className='d-flex justify-content-between auction-card-content'>
              <span style={{color:'red'}}>Select Quantity</span>
            
              <FormControl fullWidth color='error'>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                    value={mintquantity}
                    onChange={handleSelect}
                  >
                      {Quantities.map((quantity, i)=>(
                          <MenuItem sx={{border:'none'}} key={i} value={quantity}>{quantity}</MenuItem>
                      ))}
                      
                  </Select>
              </FormControl>
            </div>
            <div className='d-flex justify-content-between auction-card-content'>
            <Stack direction="row" spacing={5} alignItems="left">
                <div>
                  <span>Buy With BNB</span>
                  <h5 title={item.price}>{fShortenNumber(item.price)} {item.chain}</h5>
                  <div type='button' style={{pointerEvents: loadingBNB && 'none'}} onClick={()=>{connectData ? loadingBNB === false && buyDropWithBNB(item) : ConnecttoWallet()}}>
                    <span className='place-btn'>{loadingBNB ? <i className='loading-gif'><img src={Loading} alt="loading..." style={{ minWidth:'70px', maxWidth:'70px' }}/> </i> : connectData? "MINT NOW" : "Connect" }</span>
                  </div>
                </div>
                <div>
                  <span className='text-success'><b>{Date.parse(item.startdate) / 1000 > parsenow? 'Starts':item.active===0? 'Ended':'Started'}</b></span>
                  <div>{item.active!=0&&fDateyy(item.startdate)}</div>
                 
                </div>
              </Stack>
                {/* {item.chain==='BNB' &&
                <div>
                  <span>Buy With KYC</span>
                  <h5 title={kycprice}>{fShortenNumber(kycprice)} KYC</h5>
                  <div type='button' style={{pointerEvents: loadingKYC && 'none'}} onClick={()=>{connectData ? loadingKYC === false && buyDropWithKYC(item) : ConnecttoWallet()}}>
                    <span className='place-btn '>{loadingKYC ? <i className='loading-gif'><img src={Loading} alt="loading..." style={{width:'70px'}}/> </i> : connectData? "MINT NOW" : "Connect" }</span>
                  </div>
                </div>
                } */}
            </div>
            <div className='d-flex justify-content-between'>
              <span className='col-8'> Quantity Left: {item.quantity_left}</span>
              <Likes className='col-4'/>
            </div>
          </div>

        
        </div>
      </div>
    </>
  );
};

export default NFTDrop;
