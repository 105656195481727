import React from 'react';
import { CreateUser } from '../containers';


const CreateProfile = () => {

    return(
        <div>
          <CreateUser />  
        </div>
    );

}

export default CreateProfile;
