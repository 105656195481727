import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { CgSearch } from "react-icons/cg";
import {FaCaretDown } from "react-icons/fa"
import { Colors, Devices } from "../Theme/KittyNftTheme/Theme";

const SearchBarEl = styled.article`
  // background-color: ${Colors.Background};
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  height: 100%;
  max-width: 480px;
  flex: 1;
  align-items: center;
  display: none;
  @media ${Devices.Laptop} {
    display: flex;
  }
`;

const SearchBarBg = styled.div`
  background-color: ${Colors.Gray};
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  border-radius: 20px;
  padding: 0.5rem 0.7rem;
  svg {
    font-size: 1.5rem;
    color: ${Colors.Red};
  }
`;

const SearchInput = styled.input`
    border: none;
    background-color: ${Colors.Gray};
    font-size: 1.13rem;
    padding: 0.1rem 0.5rem;
    flex: 1;
    :focus {
        outline: none;
    }
`;

const SearchSelect = styled.div`
    color: ${Colors.Red};
    max-width: 120px;
    display: flex;
    white-space: nowrap;
    gap: 0.2rem;
    padding: 0.01 0.4rem;
    border-right: 3px solid ${Colors.Red}; 
`;

const Select = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 10px;
    position: relative;
    min-width: 122px;
`;

const SelectList = styled.ul`
    position: absolute;
    top: 139%;
    left: 0;
    list-style: none;
    background: #fff;
    color: ${Colors.Red};
    width: 100%;
    min-width: 122px;
    border-radius: 5px;
    padding: 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const ListItem = styled.li`
  cursor: pointer;
  justify-content: space-between;
  padding: 0px 5px 0px 10px;
`;

const Horiz = styled.hr`
  margin:5px;
  padding: 0px 5px 1px 10px;
`

export default function SearchBar() {
  const [open, setOpen]= useState(false);
  const [searchCat, setSearchCat]= useState('NFT Drops');
  const [searchPlaceHolder, setSearchPlaceHolder] = useState('Search NFT Drops')
  const [searchInput, setSearchInput] = useState({input:''})
  const history = useHistory();

  const handleToggle=()=>{
    setOpen(!open);
  }
  const handleSearchSelect=(e)=>{
    setSearchCat(e.target.innerText)
    setOpen(false);
    e.target.innerText === 'NFT Drops'? setSearchPlaceHolder('Search NFT Drops') : setSearchPlaceHolder('Search NFTs')
  }

  const searchNFT=()=>{
    if(searchCat ==='NFT Drops'){
      history.push(`/browse/nftdrop?searchQuery=${searchInput.input}&page=1&sort=1`);
    }else{
      history.push(`/browse?searchQuery=${searchInput.input}&page=1&sort=1`);
    }
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchNFT();
    }
  };

  return (
    <SearchBarEl>
      <SearchBarBg>

        <SearchSelect>
          <Select>  
              <div onClick={handleToggle}>{searchCat}
              </div>
              <FaCaretDown onClick={handleToggle}/>
              <SelectList style={open?{display:'block'}:{display:'none'}}>
                <ListItem onClick={handleSearchSelect}>
                  NFT Drops
                </ListItem>
                <Horiz/>
                <ListItem onClick={handleSearchSelect}>
                  NFT   
                </ListItem>
              </SelectList>
          </Select>
        </SearchSelect>

        <SearchInput value={searchInput.input} placeholder={searchPlaceHolder} onKeyDown={handleKeyPress} onChange={(e)=>{setSearchInput({...searchInput, input:e.target.value})}}/>
        <CgSearch  onClick={searchNFT}/>
      </SearchBarBg>
    </SearchBarEl>
  );
}