import { combineReducers } from 'redux';

import connect from './connect';
import nfts from './nfts';
import lptokens from './lptokens';
import users from './users';
import transactions from './transactions';
import collections from './collections';
import whitelist from './whitelist';

export const reducers =  combineReducers({
    connect: connect,
    nfts: nfts,
    lptokens: lptokens,
    users: users,
    transactions: transactions,
    collections: collections,
    whitelist: whitelist, 
    
});



