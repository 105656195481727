import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWeb3React } from "@web3-react/core";

import { BiHeart } from "react-icons/bi";
import {MenuItem, Select, FormControl} from '@mui/material';
import Loading from "../../../images/cardicons/loading.gif";
import { injected } from '../../Blockchain/Connector';
import {CONNECT }from '../../../constants/actionTypes';
import { likeNFTDrop } from '../../../actions/nfts';
import { MintNFTDropBNB, MintNFTDropKYC, CheckPrice } from '../../Blockchain/MintBuySell';
import { fNumber2decimal, fShortenNumber } from '../../../utils/formatNumber'
import { getImageSrc, getNFTImageSrc }  from '../../../utils/formatImage';
import { getUser } from '../../../api/index.js'; 
import { MintDropEvent } from '../../Blockchain/Events';
import { Quantities } from '../../../utils/selectOptions';
import { SiteName } from '../../../utils/config'
import Snackalert from '../../Buttons/Snackalert';
import MediaDisplay from '../MediaDisplay/MediaDisplay';

const BannerArea1 = ({item}) => {
  const {connectData} = useSelector((state) => state.connect);
  const {connecteduser} = useSelector((state) => state.users);
  const [loadingBNB, setLoadingBNB] = useState(false);
  const [loadingKYC, setLoadingKYC] = useState(false);
  const [userData, setUserData]= useState(undefined);
  const {library, activate} = useWeb3React();
  const [kycprice, setKycprice] = useState('');
  const dispatch = useDispatch(); 
  const [snackmess, setSnackmess] = useState(undefined); 
  const [severity, setSeverity] = useState('success');
  const [opensnack, setOpensnack] = React.useState(false);
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const [mintquantity, setMintquantity] = useState(1);
  const [likecount, setLikecount] = useState('');
  const handleSnack = (mess, severity) => {
    setOpensnack(true);
    setSnackmess(mess);
    setSeverity(severity);
  };

  const Likes = () => { 
    if(connectData){
        return connecteduser?.dropslikes?.split(',').find((like) => like === item.id.toString()) 
        ? (
                <><p>
                <i title='you have liked this item'><BiHeart /> </i>
                {/* {item.likes} */}
                {likecount? likecount : item?.likes}
                </p>
                </>
        ) : (
          !likecount?
            <><p>
                <i type='button' onClick={() => dispatch(likeNFTDrop(item.id, setLikecount))}><BiHeart /> </i>
                {/* {item.likes} */}
                {likecount? likecount : item?.likes}
                </p>
                </>
            :
            <><p>
              <i title='you have liked this item'><BiHeart /> </i>
              {likecount? likecount : item?.likes}
              </p>
            </>
        )
    }
    return  <><p>
                <i title='connect wallet to like item'><BiHeart/> </i>
                {/* {item.likes} */}
                {likecount? likecount : item?.likes}
              </p></>;
  };

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimage.png'
  }

  const ConnecttoWallet = async()=> {
    try {
        await activate(injected)
        dispatch({ type: CONNECT, payload:'connected' });
    } catch (ex) {
    console.log(ex)
    }

  }

  const handleSelect = (event) =>{
    setMintquantity(event.target.value);
   
  }

  const buyDropWithBNB = async(saledata)=>{
    setLoadingBNB(true);
    const nftinfo = {contract: saledata.contract, metadataurl: saledata.metadataurl, mintprice: saledata.price, connectData: userprofile, quantity:mintquantity};
    await MintNFTDropBNB(nftinfo, library).then(
      async (res)=> {
           if(!res || res===false){
               res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
               setLoadingBNB(false);
               
           } else{
             
              const content = {nftprojectname: saledata.name, nftcontract: saledata.contract, nftprice: saledata.price, nftdesc: saledata.description, cid:saledata.imageurl , meta: saledata.metadataurl, nftcategory: saledata.category, nftino: 0, nftcreatedby: saledata.owner, nftownedby:userprofile, nftstatus:1, nftprop:saledata.properties, nftblockchain: saledata.chain, nftcollectionname:saledata.collection, nftcollectionid:saledata.collectionid};     
              const transactioninfo = {nftaddress: saledata.contract, txtype: 'MINT', nftmintprice: saledata.price, nftamount: 1, seller: userprofile, buyer: userprofile, nftblockchain: saledata.chain, date:new Date()}; 
              const remainingquantity = saledata.quantity_left - mintquantity > 0 ? saledata.quantity_left - mintquantity : 0;
              const updatequantity = {quanityleft:remainingquantity, id:saledata.id}
              let MintNFTid = await MintDropEvent(nftinfo, library, content, transactioninfo, updatequantity, setLoadingBNB, handleSnack, dispatch);
             
           }
   });
  }

  const buyDropWithKYC = async(saledata)=>{
    setLoadingKYC(true);
    const nftinfo = {contract: saledata.contract, metadataurl: saledata.metadataurl, mintprice: saledata.price, connectData: userprofile, quantity:mintquantity };
    await MintNFTDropKYC(nftinfo, library).then(
     async (res)=> {
          if(!res || res!=true){
              res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
              setLoadingKYC(false)
             
          } else{
                    const content = {nftprojectname: saledata.name, nftcontract: saledata.contract, nftprice: saledata.price, nftdesc: saledata.description, cid:saledata.imageurl , meta: saledata.metadataurl, nftcategory: saledata.category, nftino: 0, nftcreatedby: saledata.owner, nftownedby:userprofile, nftstatus:1, nftprop:saledata.properties, nftblockchain: saledata.chain, nftcollectionname:saledata.collection, nftcollectionid:saledata.collectionid};
                    const transactioninfo = {nftaddress: saledata.contract, txtype: 'MINT', nftmintprice: saledata.price, nftamount: 1, seller: userprofile, buyer: userprofile, nftblockchain: saledata.chain, date:new Date()};
                    const remainingquantity = saledata.quantity_left - mintquantity > 0 ? saledata.quantity_left - mintquantity : 0;
                    const updatequantity = {quanityleft:remainingquantity, id:saledata.id}
                    let MintNFTid = await MintDropEvent(nftinfo, library, content, transactioninfo, updatequantity, setLoadingKYC, handleSnack, dispatch);
          }
     }
    );
}

const getKYCPrice = async(price)=>{
  if(price){
      let NFTprice;
      NFTprice = await CheckPrice(price, library);
      NFTprice = fNumber2decimal(NFTprice)
  
      setKycprice(NFTprice)
  }
}
  
  useEffect(() => {
     async function getuserinformation (){ 
      if(item.owner.length>0){     
          const userinfo = await getUser(item.owner);
          setUserData(userinfo.data)
      }
    }
    getuserinformation()

 }, [dispatch, item.owner]);



//  useEffect(() => {
//   if (connectData) {
//    getKYCPrice(item.price)
//   }
// }, [item.price, connectData]);

  return (
    <>
      <div className='banner-area-two'>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <div className='banner-content-two'>
                <h1>Explore the world of NFTs</h1>
                <p>
                  Welcome to {SiteName} marketplace testnet, the home of the world's most unique and valuable non-fungible tokens (NFTs). Whether you're a seasoned NFT collector or just starting to explore the world of digital assets, you'll find all you need here.
                </p>
                <div className='banner-btn'>
                    <a href='/browse?page=1&sort=1' className='default-btn border-radius-5'>Explore More</a>
             
                    <a href='/browse/nftdrop?page=1&sort=1' className='default-btn two border-radius-5'>
                      NFT Drops
                    </a>
                 
                </div>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='banner-item-slider owl-theme'>
              
                  <div>
                    <div className='banner-item'>
                      <div className='banner-item-img'>
                        <a href={`/detail/nftdrop/${item.id}/${item.contract}`}>
                          <MediaDisplay item={item} drop={false} detail={true}/>
                          {/* <img className="trimdetail"
                            src={getNFTImageSrc(item)}
                            alt={item.name}
                            onError={addDefaultSrc}
                          /> */}
                        </a>
                        <div className='banner-item-user'>    
                            <a href={`/user/${userData?.wallet_address}`} className='banner-item-user-option'>
                              <img
                                src={getImageSrc(userData)}
                                alt={userData?.username}
                                onError={addDefaultSrc}
                               
                              />
                              <span>Created by {userData?.username}</span>
                            </a>
                        </div>
                      </div>

                      <div className='content'>
                        <a href={`/detail/nftdrop/${item.id}/${item.contract}`}><h3>{item.name}</h3> </a>
                        
                        <div className='d-flex justify-content-between auction-card-content'>
                          <span style={{color:'red'}}>Select Quantity</span>
                         
                          <FormControl fullWidth color='error'>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                                value={mintquantity}
                                onChange={handleSelect}
                              >
                                  {Quantities.map((quantity, i)=>(
                                      <MenuItem sx={{border:'none'}} key={i} value={quantity}>{quantity}</MenuItem>
                                  ))}
                                  
                              </Select>
                          </FormControl>
                        </div>

                        <div className='d-flex justify-content-between'>
                          <div className=''>
                            <h5 title={item.price}>{fShortenNumber(item.price)} {item.chain}</h5>
                            <div type='button' style={{pointerEvents: loadingBNB && 'none'}} onClick={()=>{connectData ? loadingBNB === false && buyDropWithBNB(item) : ConnecttoWallet()}}>
                              <span className='place-btn'>{loadingBNB ? <i className='loading-gif'><img src={Loading} alt="loading..." style={{minWidth:'70px', maxWidth:'70px' }}/> </i> : connectData? "BUY NOW" : "Connect" }</span>
                            </div>
                          </div>
                          
                          {/* {item.chain==='BNB' &&
                            <div className=''>
                              <h5 title={kycprice}>{fShortenNumber(kycprice)} KYC</h5>
                              <div type='button' style={{pointerEvents: loadingKYC && 'none'}} onClick={()=>{connectData ? loadingKYC === false && buyDropWithKYC(item) : ConnecttoWallet()}}>
                                <span className='place-btn '>{loadingKYC ? <i className='loading-gif'><img src={Loading} alt="loading..." style={{width:'70px'}}/> </i> : connectData? "BUY NOW" : "Connect" }</span>
                              </div>
                            </div>
                          } */}
                        </div>

                        <div className='d-flex justify-content-between banner-item-content'>
                         
                          <div className='item-right'>
                            <h3 className='item-remaining'>Quantity Remaining</h3>
                            <div
                              className='timer-text'
                              data-countdown='2021/11/11'
                            >
                              {item.quantity_left}
                            </div>
                          </div>
                          <Likes/>
                        </div>
                        
                      </div>
                    </div>
                    
                  </div>
              
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default BannerArea1;
