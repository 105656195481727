import React from 'react';
import './footer.css';
import Footercomp from '../../components/Footer/Footercomp';

const Footer = ()=> {

    return (
        <Footercomp/>
    )
};

export default Footer;