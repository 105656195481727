import React from 'react';
import {SiteName, SitePolicyEmail } from '../../../utils/config';

const PolicyArea = () => {
  return (
    <>
      <div className='about-area pt-70 pb-70'>
        <div className='container'>
         <div className='section-title text-center'>
            <h2 className='m-auto'>Privacy Policy</h2>
         </div>
            <p>
              Your privacy is important to Epin LLC. d/b/a {SiteName} ("{SiteName}," "we," "us," or "our").
              The {SiteName} website, mobile app, and other software offered on or in connection with our services, as detailed in our Terms of Service (collectively, the "Service"), have been designed to provide you with information about how we collect, use, and share Personal Data (as defined below).
              In this privacy statement, "NFT" refers to a non-fungible token or comparable digital object that is deployed on a blockchain (like the Ethereum blockchain) and employs smart contracts to link to or otherwise be connected to certain content or data. 
            </p>
         
          <div className='single-content'>
            <h3>Information Collection</h3>
            <p>
            Personal data is any information that may be used to uniquely identify you, such as your name, email address, and any other private details that are connected to or associated with any of the aforementioned.
            Data, including aggregated and de-identified data, that is not linked to or related with your personal data is referred to as "Anonymous Data" since it cannot be used to identify specific individuals.
            We gather both anonymous and personal data, as described below, . 
            </p>
            <h5><i>Information You Give To Us</i></h5>
            
              <ul>
                <li> We may get Personal Data from you when you use our Service, update your account profile, or contact us, including your email address, first and last name, user name, and other details you submit.
                  When you use our Service, we may link your blockchain address—which we also collect—to Personal Data.
                </li>
                <li>
                  You can save preferences for things like notification settings, how your information is displayed, and preferred websites using our service.
                  These preferences may be connected to your ID, browser, or mobile device.
                </li>
                <li>
                  We will gather your name, contact information, and any other message content if you give us feedback or get in touch with us. 
                </li>
                <li>
                We may also get personal information from you at other times when you willingly supply it or when we explicitly declare that we are collecting it. 
                </li>
              </ul>
            
            <h5><i>Information Obtained Via Technology</i></h5>
            <p>
              We may employ automatic data collection technologies to gather specific details about your device, browsing behavior, and trends as you move around and engage with our Service, including:
            </p>
            <ul>
              <li>
                Details gathered by our servers: We (or a third-party service provider) may gather information about you in order to deliver our Service and enhance its utility for you, such as but not limited to your browser type, operating system, Internet Protocol ("IP") address, mobile device ID, blockchain address, wallet type, and date/time stamps. 
              </li>
              <li>
                Log File: We automatically collect certain information, as is true for the majority of websites and services, and log files are where we store it.
                IP addresses, browser type, ISP, referring/exit sites, operating system, date/time stamps, and clickstream data are some of the details included in this information.
                We utilize this data to better understand trends, manage the service, follow users around the service, and better cater our services to the needs of our consumers.
                For instance, some of the data may be gathered so that the Service will recognize you when you come and that it can tailor your experience based on the data. 
              </li>
              <li>
                Cookies:Similar to many other online services, we use cookies to gather data.
                To better understand how users interact with our Service, enhance the quality of our products, and give users a more individualized experience, we may employ both session cookies, which expire when you shut your web browser, and persistent cookies, which remain on your computer until you delete them.
              </li>
              <li>
                Do not track signal: The "do not track" signals or other techniques that would allow Users to refuse tracking on our site are not presently recognized by our systems.
              </li>
              <li>
                Services for analytics: Other businesses may set their own cookies or other tracking technologies when you use our Service in addition to the tracking technologies we use, like Cookies and Pixel Tags.
                This includes the analytics services we utilize from third parties (the "Analytics Services") to study how users interact with the Service.
                The Analytics Services get the information created by the Cookies or other technologies regarding how you use our service (the "Analytics Information").
                The Analytics Services produce reports on user behavior that we may obtain on an individual or aggregate basis using the Analytics Information. We use the data we receive from the analytics services to make our service better.
                When required by law or when those third parties process analytics information on their behalf, the Analytics Services may also send information to third parties.
                The terms of use and privacy policies of each Analytics Service limit how and with whom Analytics Information may be used and shared.
                By using our service, you agree that Analytics Services may process data about you in the ways and for the purposes described above. 
              </li>

            </ul>
          
            <h5><i>Information Gathered From Outside Businesses.</i></h5>
            <p>
              Companies who provide their goods and/or services for use in connection with our service or whose goods and/or services may be linked from our service may provide us with Personal and/or Anonymous Data about you.
              Your blockchain address and any other information you choose to share with third-party wallet providers, for instance, are sent to us by them.
              The information we have already gathered from or about you through our Service may be combined with this.
            </p>

            <h5><i>Public Information From The Blockchain</i></h5>
            <p>
              We gather information from blockchain activity that is publicly available and/or accessible.
              This could include blockchain addresses and details on NFT purchases, sales, or transfers; these details might then be linked to other information you've given us. 
            </p>
  
          </div>

          <div className='single-content'>
            <h3>Use of Personal Data</h3>
            <p>
              We use your Personal Data to operate our business, deliver the Service, enhance the Service, and cater to your individual needs.
            </p>
              We use your Personal Data in particular to:
              <ul>
                <li>facilitate your account's creation and security; 
                </li>
                <li>recognize you as a user in our system;
                </li>
                <li>offer you our Service, which may include but is not limited to assisting you in viewing, exploring, and creating NFTs using our tools and, at your choice, connecting with others directly to buy, sell, or transfer NFTs on open blockchains;
                </li>
                <li>analyze how you and other users locate and use the service in order to better the administration of our service and the quality of your experience when you interact with it; 
                </li>
                <li>reply to your requests and questions and offer customer service;
                </li>
                <li>behaviour that may be against our Terms of Service will be looked at and addressed;
                </li>
                <li>fraud, violations of our terms or rules, and/or other harmful or illegal behavior; detect, prevent, and address;
                </li>
                <li>your username will be displayed next to any NFTs that are currently or have previously been accessible in your third-party wallet and next to any NFTs that you have interacted with;
                </li>
                <li>send you a welcome email to confirm that you are the owner of the email address you used to register for an account;
                </li>
                <li>give you administration notices, such as security, help, and upkeep alerts; 
                </li>
                <li>provide you notifications of actions taken on the service, such as offers made on your NFTs;
                </li>
                <li>send you emails, advertisements, and other communications regarding our services or the products and services of third parties; 
                </li>
                <li>Respond to your requests for information about job openings or other requests; abide by applicable laws;
                </li>
                <li>assist with inquiries into potential legal wrongdoing by law enforcement or other authorities;
                </li>
                <li>pursue or defend against legal threats or claims; 
                </li>
                <li>and take any other action we may specify when you provide the Personal Data.
                </li>
              </ul>
            
            <p>
              We might convert personal data into anonymous data records.
              In order to optimize the navigation of the Service and improve our Services, we use this Anonymous Data to examine request and usage patterns.
              We reserve the right to disclose Anonymous Data to third parties without limitation and to use Anonymous Data for any purpose. 
            </p>
          </div>

          <div className='single-content'>
            <h3>Disclosure of Personal Data</h3>
            <p>
              We share your personal information as outlined below and elsewhere in this privacy statement.
            </p>
            <p>
              Service Providers from a Third Party: In order to: provide technical infrastructure services; carry out quality assurance testing; examine how our Service is used; prevent, detect, and respond to unauthorized activities; provide technical and customer support; and/or to provide other support to us and to the Service, we may share your Personal Data with third-party service providers.
            </p>
            <p>Affiliates: Any subsidiaries, joint ventures, or other businesses that are under our common control (collectively, "Affiliates") may receive some or all of your Personal Data from us, in which case we will require our Affiliates to abide by this Privacy Policy. 
            </p>
            <p>Corporate Restructuring: In connection with or during negotiations of any merger, financing, acquisition, or dissolution transaction or procedure involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets, we may share some or all of your Personal Data.
                Personal Data may also be transferred as a corporate asset in the case of insolvency, bankruptcy, or receivership.
                The Personal Data we have acquired will be in the possession of the company that purchases our business, assets, or corporation, and it will also take on the duties and rights related to your Personal Data outlined in this Privacy Policy.  
            </p>
            <p>Legal Rights:If {SiteName} has a good faith belief that disclosing Personal Data is necessary, it may do so regardless of any choices you make regarding your Personal Data (as described below): (a) in connection with any legal investigation; (b) to comply with applicable laws or to respond to subpoenas, warrants, or other legal process served on {SiteName}; (c) to protect or defend the rights or property of {SiteName} or users of the Service; and/or (d) to look into potential violations of the law, this privacy statement, or our terms of service or to help prevent them.
            </p>
            <p>Additional Disclosures: Additionally, we may divulge your personal information to: carry out the request that you make; to satisfy any other purpose that was made known to you at the time of request; 
            </p>
          </div>

          <div className='single-content'>
            <h3> Linked/Third Party Websites</h3>
            <p>
              There is a chance that our service will link to external websites.
              You will leave our Service and visit another site where another organization may gather Personal Data about you when you click on a link to any other website or place.
              These third-party websites are not under our control, we do not check them, and we are not liable for their content.
              Please note that neither the provisions of this privacy policy nor any material on such third-party websites, nor any collecting of your personal information after you click on links to such third-party websites, are subject to this Privacy Policy.
              We advise reading the privacy statements for each website you visit.
              Links to other websites or locales are provided solely for your convenience and not an endorsement of the third party product, website or the content. 
            </p>
          </div>

          <div className='single-content'>
            <h3> Third Party Wallet</h3>
            <p>
              You must use a third-party wallet that enables transactions on open blockchains in order to use our service.
              The applicable terms of service and privacy policy of any third-party wallet provider will apply to your dealings with that third party.
            </p>
          </div>

          <div className='single-content'>
            <h3> Your Choices Regarding Information</h3>
            <p>
              Regarding how you can utilize the information on our services, you have a few options:
            </p>
            <p>
              Email correspondence: Periodically, we might send you emails, newsletters, or both that specifically advertise using our service or other people's products and services.
              You can let us know that you'd prefer not to receive newsletters or promotional emails from us by following the unsubscribe instructions in the email you receive or by adjusting your notification choices on the Settings page.
              Regardless of these choices, We could occasionally contact you with informational communications about transactional services.
            </p>
            <p>
              You can instruct your browser to stop accepting Cookies or to notify you before accepting a Cookie from a website you visit by changing its settings if you decide at any time that you do not wish to accept Cookies from our Service for any of the purposes mentioned above.
              Consult the technical documentation for your browser.
              However, you might not be able to utilize all of the Service's features or all of its functionality if you refuse to accept Cookies. 
            </p>
          </div>

          <div className='single-content'>
            <h3>Data Access and Control</h3>
            <p>
              Through your Settings page, you can view, access, update, or delete your Personal Data for specific features of the Service.
              Additional rights that you might have include:
            </p>
            <p>
              According to the relevant European and UK General Data Protection Regulations ("GDPR"), if you are a user in the European Economic Area or the United Kingdom, you have specific rights.
              These rights include the opportunity to I ask for access to and a copy of your personal data; (ii) ask for its correction or deletion; (iii) object to or limit the processing of your personal data; and (iv) ask for the transfer of your personal data.
              You also have the right to revoke your permission at any moment if we gathered and processed your personal data with it. 
            </p>
            <p>
              The California Consumer Privacy Act (or "CCPA") gives residents of California some rights.
              These rights include the ability to I request access to, information about, and a copy of the personal data we have gathered about you and/or disclosed to third parties; (ii) request deletion of the personal data we have gathered about you; and (iii) the ability to refuse to have your personal data sold.
              According to the CCPA's definitions, we do not "sell" your "personal information." 
            </p>
            <p>Please contact us using the "Submit a request" link here or at the address listed in Section 13 below, specify your request, and cite the relevant law if you desire to exercise your rights under the GDPR, CCPA, or other applicable data protection or privacy laws.
                We might need proof of your identification or more details regarding your request.
                Any request made above will be taken into consideration, and we'll respond in accordance with the law.
                We won't treat you differently if you exercise any of these rights. 
            </p>
            <p>Despite the foregoing, we do not have custody or control over any blockchains, therefore we are unable to change or remove any data that is stored on one, such as the Ethereum blockchain.
              Your blockchain address and any NFTs you have stored at that address may be the subject of purchases, sales, and transfers that are recorded on the blockchain. 
            </p>
          </div>

          <div className='single-content'>
            <h3>Retention of Data.</h3>
            <p>
              As long as you use the Service or have an account with us, or as long as it takes to accomplish the goals listed in this Privacy Policy, we may keep your Personal Data on file.
              If we reasonably believe that keeping your Personal Data is necessary to comply with legal obligations, resolve disputes, stop fraud and abuse, enforce our Terms or other agreements, or otherwise protect our legitimate interests, we may keep it even after you deactivate your account and/or stop using the Service.
              We shall erase your Personal Data if it is no longer needed for these purposes. 
            </p>
          </div>

          <div className='single-content'>
            <h3>Protection of Data.</h3>
            <p>
              We use technological, administrative, and physical protections to maintain the integrity and security of the information we collect through our service because we are concerned about the security of your information.
              The security of our systems cannot be guaranteed because no security system is impenetrable.
              We shall take action to investigate and correct the issue if any information in our possession or control is compromised due to a security breach, and we will notify any persons whose information may have been compromised in accordance with applicable laws and regulations.
            </p>
            <p>
              Your digital wallet is your responsibility to keep secure, so we strongly advise you to take the necessary precautions.
              If you find a problem with your wallet, contact your wallet provider.
            </p>
          </div>

          <div className='single-content'>
            <h3>Minors</h3>
            <p>We do not purposefully collect personal information from users under the age of 13 on our website.
              All users must comply with our Terms of Service and be at least 18 years old.
              Under 18-year-old minors who are at least 13 years old are permitted to use a parent's or guardian's {SiteName} account, but only with the account holder's permission.
              When a child under the age of 13 submits Personal Data to {SiteName}, we make every effort to get that data removed as soon as we become aware that it belongs to a child under 13.
              Please use the <a href="/submitrequest">"Submit a request" </a> link here or contact us using the email at the bottom, if you think we might have any Personal Data from a child under 13 in our possession. 
            </p>
          </div>

          <div className='single-content'>
            <h3> Users From Outside Of The US</h3>
            <p>
              If you are a non-U.S. user of the Service, you accept and agree that your Personal Data may be processed for the reasons detailed in the Privacy Policy by using the Service and providing us with data.
              Your Personal Data may also be handled in the nation where it was collected as well as in other nations, such as the United States, where the regulations on processing Personal Data may be less strict than those in your nation.
              You hereby grant such transfer your consent by providing your personal data. 
            </p>
          </div> 

          <div className='single-content'>
            <h3>Alterations To This Privacy Policy.</h3>
            <p>
              For any reason, this privacy statement may be modified from time to time.
              Any time we make changes to our privacy policy, we'll let you know by uploading the updated version at https://{SiteName}/privacy.
              The beginning of this privacy statement states the time the policy was last updated.
              It is your responsibility to often check our Service and our Privacy Policy for updates. 
            </p>
          
          </div>

          <div className='single-content'>
            <h3>Questions, getting in touch with {SiteName}, and filing violations.</h3>
            <p>
              If you have any questions, concerns, or complaints about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us, please contact us at {SitePolicyEmail}. 
            </p>
          
          </div>



         
        </div>
      </div>
    </>
  );
};

export default PolicyArea;
