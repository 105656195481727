import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderEl, Center, LogoText, Logo, Nav, NavItem, SearchIcon, MenuIcon, AuthItems, Buttontwo, Buttonthree, Ulist, Mobilediv, Laptopdiv } from './Style'
import { FiMenu } from "react-icons/fi";
import { CgSearch } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
//import MuiAlert from '@material-ui/lab/Alert';
// import Alert from '@mui/material/Alert';

import { AppBar, Snackbar, Toolbar, Button, MenuItem, Grow, ButtonGroup, Popper, Paper, ClickAwayListener, MenuList, Grid, Link, Avatar, Box, IconButton, ListItemIcon, Menu, Typography, Tooltip, TextField } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import CreateIcon from '@mui/icons-material/Create';
import Logout from '@mui/icons-material/Logout';
import SearchBar from "./SearchBar";
import SearchBarMobile from "./SearchBarMobile"
import { Colors, Devices } from "../Theme/KittyNftTheme/Theme"
import { useWeb3React } from "@web3-react/core"
import { injected, resetWalletConnector, walletconnect } from '../Blockchain/Connector';
import { getConnectedUser } from '../../actions/users';
import * as actionType from '../../constants/actionTypes';

// import { styled, alpha } from '@mui/material/styles';
import { styled, alpha } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import AppsIcon from '@mui/icons-material/Apps';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ConnectWalletDropdown from '../Buttons/ConnectWalletDropdown'
// import { useAccount, useDisconnect } from 'wagmi';
// import { useWeb3Modal } from '@web3modal/react';


const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(5.5),
      minWidth: 143,
      display: 'none',
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '@media only screen and (min-width: 1024px)':{
        display: 'flex',
          },
        '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));


const StyledMenuMobile = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(5.5),
      minWidth: 240,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '@media only screen and (min-width: 1024px)':{
            display: 'none',
          },
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Headercomp = ({mobileMenu})=>{
    const { active, account, library, connector, activate, deactivate, error } = useWeb3React();
    // const { address } = useAccount();
    // const { disconnect } = useDisconnect();
    // const { close } = useWeb3Modal()
    const {connecteduser} = useSelector((state) => state.users);
    const { MobileMenuIsOpen, setMobileMenuIsOpen } = mobileMenu;
    const [SearchIsOpen, setSearchIsOpen] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile'))); 
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = React.useState(false);
    const [openmodal, setOpenmodal] = React.useState(false);
    const anchorRef = React.useRef(null);
   
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElMobile, setAnchorElMobile] = useState(null);
    const open = Boolean(anchorEl);
    const openMobile = Boolean(anchorElMobile)
   
    // function Alert(props) {
    //     return <MuiAlert elevation={6} variant="filled" {...props} />;
    // }
    
    const handleClickModal = () => {
      setOpenmodal(true);
    };
  
  const handleCloseModal = () => {
      setOpenmodal(false);
  };
   

    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpensnack(false);
    };

    //const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    // const handleClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElMobile(event.currentTarget);
    // };

    const handleClickMobile = (event) => {
        //console.log(event.currentTarget);
        setAnchorElMobile(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleCloseMobile = () => {
        setAnchorElMobile(null);
    };


    function toggleMenu() {
            setMobileMenuIsOpen(!MobileMenuIsOpen);
    }

    const logout = async () => {
        await deactivate()
        dispatch({ type: actionType.LOGOUT });
        handleSnack('Please connect wallet.','error');
        setUser(null);
        // history.push('/');
        return location.pathname 
    };

    const logoutWalletConnect = async () => {
      await deactivate()
      // await disconnect();
      dispatch({ type: actionType.LOGOUT });
      handleSnack('Please connect wallet.','error');
      setUser(null);
      // history.push('/');
      return location.pathname 
  };

    const openMyNFTs = (e) => {
        history.push(`/mynfts`);
    };

    const openCreate = (e) => {
        history.push(`/create`);
    };

    const openMyCollections = (e) => {
      history.push(`/mynfts/collections`)
    }

    const handleProfile = (e) => {
        history.push(`/user/${account}`);
      };

    const handleCreateProfile = (e) => {
      history.push(`/profile/create`);
    };
    
    // const openConnect = (e) => {
    //    // history.push(`/connect`);
    //     //ConnectWalletConnect()
    // };
    
    const handleBrowse = (page) => {
       page==='nftdrop' ? history.push(`/browse/nftdrop?page=1&sort=1`) : history.push(`/browse?page=1&sort=1`);
    };

    const ConnectWallet = async()=> {
        try {
            await activate(injected)
            dispatch({ type: actionType.CONNECT, payload:'connected' });
        } catch (ex) {
            console.log(ex)
        }
    }

    const showStickyMenu = () => {
        if (window.scrollY >= 60) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };
    
    if (typeof window !== 'undefined') {
        // browser code
        window.addEventListener('scroll', showStickyMenu);
    }

    useEffect(() => {
        //dispatch(getConnectWallet(setConnectWallet));
        if(JSON.parse(localStorage.getItem('profile'))){
            ConnectWallet();
            dispatch(getConnectedUser(JSON.parse(localStorage.getItem('profile'))));
        }
      }, []);

    useEffect(() => {
        if(account){
            setUser(account);
            dispatch(getConnectedUser(account));
            localStorage.setItem('profile', JSON.stringify(account));   
        }
        // if(address){
        //   setUser(address);
        //     dispatch(getConnectedUser(address));
        //     localStorage.setItem('profile', JSON.stringify(address));
        // }
        else{
          // console.log(error)
            //dispatch({ type: actionType.LOGOUT });
            //handleSnack('Please connect to MetaMask.','error');
            setUser(null);
        }
    }, [account]);

    return (
         
       <HeaderEl as={sticky===true?'span':''}>

            <Snackbar open={opensnack} anchorOrigin={{ vertical:'top', horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={severity}>
                    {snackmess}
                </Alert>
            </Snackbar>
            <MenuIcon>
                <IconButton
                onClick={handleClickMobile}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={openMobile ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMobile ? 'true' : undefined}
                >
                    {/* {MobileMenuIsOpen ? ( */}
                    {openMobile ? (
                    <IoClose
                        style={{ fontSize: "2.0rem" }}
                        color={Colors.Red}
                        //onClick={toggleMenu}
                    />
                    ) : (
                    <FiMenu
                        style={{ fontSize: "2.0rem" }}
                        color={Colors.Red}
                        //onClick={toggleMenu}
                    />
                   
                    )} 
                    
                </IconButton>
                
                
                <Mobilediv>
                    <StyledMenuMobile
                        id="demo-customized-menu"
                        MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorElMobile}
                        open={openMobile}
                        onClose={handleCloseMobile}
                    >
                        <MenuItem onClick={handleCloseMobile} disableRipple>
                            <Buttonthree onClick={openCreate} className='default-btn'>Create</Buttonthree>
                        </MenuItem>
                        <MenuItem onClick={handleCloseMobile} disableRipple>
                           <span onClick={()=>{handleBrowse('nftdrop')}}>NFT Drops</span>
                        </MenuItem>

                        <MenuItem onClick={handleCloseMobile} disableRipple>
                           <span onClick={()=>{handleBrowse('nft')}}>NFTs</span>
                        </MenuItem>
                        
                        
                        <Divider sx={{ my: 0.5 }} />

                        <MenuItem onClick={handleCloseMobile} disableRipple>
                            {/* <MoreHorizIcon /> */}
                            
                            {/* {!account ? ( <div><Buttonthree id="basic-button"
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                                // onClick={ConnectWallet}
                                                >
                                                 Connect Wallet
                                               </Buttonthree>
                                              <ConnectWalletDropdown opendropdown={open} anchorEl={anchorEl} handleClose={handleClose}/>
                                          </div>) : */}
                            {!account ? ( <Buttonthree onClick={ConnectWallet}>Connect Wallet</Buttonthree>) :
                            (<Buttonthree onClick={logout} className='banner-btn'>Disconnect</Buttonthree>)}
                        </MenuItem>
                       
                        <MenuItem style={account? {display:'flex'} : {display:'none'}} onClick={handleCloseMobile} disableRipple>
                            <span onClick={openMyNFTs}>My NFTs</span>
                        </MenuItem>

                        <MenuItem style={account? {display:'flex'} : {display:'none'}} onClick={handleCloseMobile} disableRipple>
                            <span onClick={openMyCollections}>My Collections</span>
                        </MenuItem>

                        <MenuItem style={account? {display:'flex'} : {display:'none'}} onClick={handleCloseMobile} disableRipple>
                            <span onClick={handleProfile}>My Profile</span>
                        </MenuItem>

                        {!connecteduser&&
                          <MenuItem style={account? {display:'flex'} : {display:'none'}} onClick={handleCloseMobile} disableRipple>
                              <span onClick={handleCreateProfile}>Create Profile</span>
                          </MenuItem>
                        }
                    </StyledMenuMobile>
                </Mobilediv>
            </MenuIcon>


            <Center>
                <a href='/'><Logo src="/images/logo/LogoTestnet.png" /></a>
                {/* <LogoText href="/">KNM NFT MARKETPLACE</LogoText> */}
                <SearchBar />
                <Nav>
                <Ulist>
                    <li>
                    <NavItem href="/browse?page=1&sort=1">Explore</NavItem>
                    </li>
                    <li>
                    <NavItem href="/browse/nftdrop?page=1&sort=1">NFT Drops</NavItem>
                    </li>
                    <li>
                    <Buttontwo onClick={openCreate} className='default-btn'>Create</Buttontwo>
                    </li>
                </Ulist>
                </Nav>
                
            </Center>
            {SearchIsOpen ? (
                <SearchBarMobile
                SearchIsOpen={SearchIsOpen}
                setSearchIsOpen={setSearchIsOpen}
                />
            ) : (
                ""
            )}
           
             
             {/* {!account ? ( <div><Buttontwo id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    // onClick={handleClickModal}
                                    >Connect Wallet
                            </Buttontwo>
                            <ConnectWalletDropdown opendropdown={open} anchorEl={anchorEl} handleClose={handleClose}/>
                           </div> ) : */}
             {!account ? ( <Buttontwo onClick={ConnectWallet}>Connect Wallet</Buttontwo>) :
            (
           
                <div>
                    <Laptopdiv>
                    <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        style={{backgroundColor:'#f14d5d',
                                borderRadius: '10px',
                                padding: '7px 15px',
                                color:'white',
                                fontWeight:500,
                                fontSize:'16px',
                                }}
                       
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                    >
                        {user?.substring(0,4)}....{user?.substr(-4)}
                    </Button>
                    </Laptopdiv>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose} disableRipple>
                            <span onClick={openMyNFTs}>
                                <AppsIcon />
                                My NFTs
                            </span>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                            <span onClick={openMyCollections}>
                                <CollectionsIcon />
                                My Collections
                            </span>
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                            <span onClick={handleProfile}>
                                <AccountCircleIcon />
                                My Profile
                            </span>
                        </MenuItem>

                      {!connecteduser&&
                        <MenuItem onClick={handleClose} disableRipple>
                            <span onClick={handleCreateProfile}>
                                <CreateIcon />
                                Create Profile
                            </span>
                        </MenuItem>
                      }   
                        
                        <Divider sx={{ my: 0.5 }} />

                        <MenuItem onClick={handleClose} disableRipple>
                            <Logout onClick={logoutWalletConnect}/>
                            <span onClick={logoutWalletConnect}>Disconnect</span>
                        </MenuItem>
                    </StyledMenu>
                </div>


            )}

            <SearchIcon>
                <CgSearch
                onClick={() => {
                    setSearchIsOpen(!SearchIsOpen);
                }}
                />
            </SearchIcon>

        </HeaderEl>
       

    )
}

export default Headercomp;