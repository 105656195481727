import { START_LOADING, FETCH_COLLECTION, END_LOADING, FETCH_OWNER_COLLECTION, FETCH_FEATURED_COLLECTION, FETCH_ALL_COLLECTION } from '../constants/actionTypes';
import * as api from '../api/index.js';



// export const getCollectionData = (collectionname) => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADING });

//     const { data } = await api.getCollectionData(collectionname);

//     dispatch({ type: FETCH_COLLECTION, payload: {data} });
//     dispatch({ type: END_LOADING });

//   } catch (error) {
//     console.log(error);
//   }
// };

export const getCollectionByUrl = (collectionurl) => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const { data } = await api.getCollectionByUrl(collectionurl);
    dispatch({ type: FETCH_COLLECTION, payload: {data} });
    dispatch({ type: END_LOADING });

  } catch (error){
    console.log(error)
  }

}

export const getOwnerCollection = (owneracc) => async(dispatch)=>{
  try{
    dispatch({ type: START_LOADING });
    const { data: {data, currentPage, numberOfPages }} = await api.getOwnerCollection(owneracc);
    dispatch({ type: FETCH_OWNER_COLLECTION, payload: {data, currentPage, numberOfPages} });
    dispatch({ type: END_LOADING });
  }catch(error){
    console.log(error)
  }
}

export const getOwnerCollectionBySearch = (owneracc) => async(dispatch)=>{
  try{
    dispatch({ type: START_LOADING });

    const { data: {data, currentPage, numberOfPages }} = await api.getOwnerCollectionBySearch(owneracc);
    dispatch({ type: FETCH_OWNER_COLLECTION, payload: {data, currentPage, numberOfPages} });
  
    dispatch({ type: END_LOADING });
  }catch(error){
    console.log(error)
  }
}

export const getCollectionByUrlBol = (collectionurl) => async (dispatch) => {
  try{

    const { data } = await api.getCollectionByUrl(collectionurl);

    if(data) {return true }else{ return false} 

  } catch (error){
    console.log(error)
  }

}

export const getTopCollection = () => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const { data } = await api.getTopCollection();
    dispatch({ type: FETCH_FEATURED_COLLECTION, payload: {data} });
    dispatch({ type: END_LOADING });

  } catch (error){
    console.log(error)
  }

}

export const getAllCollection = (pagesort) => async(dispatch)=>{
  try{
    dispatch({ type: START_LOADING });
    const { data: {data, currentPage, numberOfPages }} = await api.getAllCollection(pagesort);
    dispatch({ type: FETCH_ALL_COLLECTION, payload: {data, currentPage, numberOfPages} });
  
    dispatch({ type: END_LOADING });
  }catch(error){
    console.log(error)
  }
}

export const getAllCollectionBySearch = (searchpagesort) => async(dispatch)=>{
  try{
    dispatch({ type: START_LOADING });

    const { data: {data, currentPage, numberOfPages }} = await api.getAllCollectionBySearch(searchpagesort);
    dispatch({ type: FETCH_ALL_COLLECTION, payload: {data, currentPage, numberOfPages} });
  
    dispatch({ type: END_LOADING });
  }catch(error){
    console.log(error)
  }
}

export const addCollection = (collection) => async (dispatch) => {
  try{
    dispatch({ type: START_LOADING });
    const { data } = await api.addCollection(collection);
   
    dispatch({ type: END_LOADING });
    return data.insertId;
  } catch (error){
    console.log(error)
  }

}


export const uploadCollectionFile = (file) => async (dispatch) =>{
  try{
    dispatch({ type: START_LOADING });
    const data = await api.uploadCollectionFile(file);
    dispatch({ type: END_LOADING });
  
  }catch(error){
    console.log(error);
  }
}

export const updateOwnerCollection = (datainfo)=>async (dispatch)=>{
  try{
    dispatch({ type: START_LOADING });
    const data = await api.updateOwnerCollection(datainfo);
    dispatch({ type: END_LOADING });
  } catch (error){
    console.log(error);
  }

}