import React from 'react';
const Footer = () => {
	return (
		<>
			<footer className="footer-area pt-100 pb-70">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-sm-8">
							<div className="footer-widget">
								<div className="footer-logo">
								
										<a href="/">
											<img
												className="logobottom"
												src="/images/logo/LogoTestnet.png"
												alt="Footer Logo"
											/>
										</a>
								
								</div>
								
							</div>
						</div>

						<div className="col-lg-2 col-sm-4">
							<div className="footer-widget ps-5">
								<h3>Marketplace</h3>
								<ul className="footer-list">
									<li>
										
											<a href="/browse/nftdrop?page=1&sort=1">NFT Drops</a>
										
									</li>
									<li>
										
											<a href="/browse?page=1&sort=1">All NFTs</a>
										
									</li>
									<li>
										
											<a href="/browse?page=1&sort=1&category=Art">Art</a>
										
									</li>
									<li>
										
											<a href="/browse?page=1&sort=1&category=Collectible">Collectible</a>
										
									</li>
								</ul>
							</div>
						</div>

						<div className="col-lg-2 col-sm-4">
							<div className="footer-widget ps-5">
								<h3>My Account</h3>
								<ul className="footer-list">
									<li>
										
											<a href="/create">Create NFT</a>
									
									</li>
									<li>
										
											<a href="/create/collection">Create Collection</a>
									
									</li>
									<li>
										
											<a href="/create/collection-add">Add to Collection</a>
										
									</li>
									<li>
										
											<a href="/mynfts">My Account</a>
										
									</li>
								</ul>
							</div>
						</div>

						<div className="col-lg-2 col-sm-4">
							<div className="footer-widget ps-5">
								<h3>Resources</h3>
								<ul className="footer-list">
									<li>
											<a href="/detail/nftdrop/1/0xDD36E5cCAc4abcBBE7f9998e8DdbAa4562d44CD0">Best Deal</a>
									</li>

									<li>	
											<a href="/browse/nftdrop?page=1&sort=8">Hot NFT Drops</a>
									</li>

									<li>
											<a href="/browse?page=1&sort=8">Hot NFTs</a>					
									</li>
                                    <li>
											<a href="/getting-started">Getting Started</a>				
									</li>
								</ul>
							</div>
						</div>

						<div className="col-lg-2 col-sm-4">
							<div className="footer-widget ps-5">
								<h3>Company</h3>
								<ul className="footer-list">
									<li>
											<a href="/about">About Us</a>			
									</li>
									<li>
											<a href="/request">Token Integration</a>			
									</li>
									<li>
											<a href="/request">NFTDrop Request</a>			
									</li>
									<li>
											<a href="/marketing">Marketing</a>			
									</li>

									
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-shape">
					<div className="footer-shape1">
						<img src="/images/home/shape-bg3.jpg" alt="Images" />
					</div>

				</div>
			</footer>
		</>
	);
};

export default Footer;