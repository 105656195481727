import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';  
import { createStore, applyMiddleware, compose } from 'redux'; 
import thunk from 'redux-thunk';

import { reducers } from './reducers';

import App from './App';


import './index.css';
import './bootstrap.min.css';
import './style.css';
import './style.css.map';
import './responsive.css';
//import './responsive.scss';
import './responsive.css.map';
// import { Buffer } from 'buffer';

//import './style.scss';

const store = createStore(reducers, {}, compose(applyMiddleware(thunk)));
// window.Buffer = window.Buffer || Buffer;

ReactDOM.render(
  
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

