import React, {useState} from 'react';
import { useWeb3React } from "@web3-react/core"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Currencies, SiteName} from '../../../utils/config'
import PageBannerStatic from '../../../components/Cards/PageBanner/PageBannerStatic';
import Snackalert from '../../../components/Buttons/Snackalert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const HelpCenterArea = () => {
  const {library} = useWeb3React();
  const [open, setOpen] = React.useState(false);
  const [snackmess, setSnackmess] = useState(undefined); 
  const [severity, setSeverity] = useState('success');
  const [opensnack, setOpensnack] = useState(false);
  const handleSnack = (mess, severity) => {
      setOpensnack(true);
      setSnackmess(mess);
      setSeverity(severity);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addNetwork = async(network)=>{
    if (network==='BNB'){
      try {
        await library.provider.request({
          method: 'wallet_addEthereumChain',
          params: [
          { chainId: '0x61', 
            chainName:'Binance Smart Chain Testnet',
            rpcUrls:['https://data-seed-prebsc-1-s1.binance.org:8545/'],                  
            blockExplorerUrls:['https://testnet.bscscan.com'],  
            nativeCurrency: { 
                symbol:'BNB',   
                decimals: 18}        
            }]
          })
        } catch (addError) {
          library?.connection.url==="metamast" ? console.log(addError) :
          handleSnack('You must have MetaMask browser wallet installed', 'error')
        }
    }else{
      try {
        await library.provider.request({
          method: 'wallet_addEthereumChain',
          params: [
          { chainId: '0x13881', 
            chainName:'Mumbai Polygon Testnet',
            rpcUrls:['https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78'],                  
            blockExplorerUrls:['https://mumbai.polygonscan.com'],  
            nativeCurrency: { 
                symbol:'MATIC',   
                decimals: 18}        
            }]
          })
        } catch (addError) {
          library?.connection.url==="metamast" ? console.log(addError): 
          handleSnack('You must have MetaMask browser wallet installed', 'error')
        }
    }
  }
  const pageinfo={page:'Getting Started With Testnet', file:'setuptestnet'}

  return (
    <>
      <PageBannerStatic page={pageinfo} />
      <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
      <div className='help-center-area pt-30 pb-70'>
        <div className='container'>
          <div className='section-title text-center'>
            <h2>HOW TO USE NFTHEV TESTNET</h2>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Step 1</h3>
                <p>
                  To get started, install <a href="https://metamask.io/download/" target="_blank" rel="noreferrer">MataMask</a> browser wallet on your browser.
                  We recommend using MetaMask for best result. Once you have MetaMask installed, the next step is to add the testnet network for Ethereum, Binance Smart Chain and Polygon to your wallet. After adding the networks, you will need to fund your wallet. 
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Step 2</h3>
                <p>
                  Add the testnet network to your MataMask by clicking the buttons below. Ensure you are logged into your Wallet and Connected.
                  <span className="btn btn-success" onClick={handleOpen}>Instructions to add Ethereum testnet</span>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                      MataMask comes preinstalled with Goerli network (Ethereum testnet) but may need to be activated
                      </Typography>
      
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        To activate, click the MataMask icon at the top right corner
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Click the drop down of networks and then click "Add Network" in the drop down
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        If you do not see Goerli network in the page that pops up, search for "Goerli Network"
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Select "Goerli Network" and click "Add a Network" button
                      </Typography>
                    </Box>
                  </Modal>
                  <span className="btn btn-primary" onClick={()=>{addNetwork('BNB')}}>Click to add Binance testnet to your wallet</span>
                  <span className="btn btn-warning" onClick={()=>{addNetwork('MATIC')}}>Click to add Polygon testnet to your wallet</span>
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Step 3</h3>
                <p>
                  <span>To fund your wallet with free testnet cryptocurrencies, go to the faucet site by clicking the links below. </span>
                  <span className="btn"> ETH - <a href="https://goerlifaucet.com/" target="_blank" rel="noreferrer">ETH Faucet Website</a></span>
                  <span className="btn"> BNB - <a href="https://testnet.bnbchain.org/faucet-smart" target="_blank" rel="noreferrer">BNB Faucet Website</a></span>
                  <span className="btn"> MATIC - <a href="https://mumbaifaucet.com/" target="_blank" rel="noreferrer">MATIC Faucet Website</a></span>
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Step 4</h3>
                <p>
                  Buy a NFT. click the 'explore' link at the top right to browse available NFT options and select a NFT you would like to purchase. Connect your wallet and click the 'buy' button. You will receive a prompt from your wallet to approve the transaction. Once that is approved, the NFT is transferred to your wallet.  
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Step 5</h3>
                <p>
                  Create a NFT. Click the <a href='/create'>Create</a> button on the top right corner of the screen.
                  Then click "Create a NFT", fill out the form, upload NFT file and click the create button. You will receive a prompt from your wallet to approve the transaction. Once that is approved, the NFT is minted and transferred to your wallet 
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Step 6</h3>
                <p>
                  Create a NFT collection. Click the <a href='/create'>Create</a> button on the top right corner.
                  Then click "Create a NFT Collection", fill out the form, upload NFT files and click the create button.
                  You will receive a prompt from your wallet to approve the transaction. Once that is approved, the NFT is minted and transferred to your wallet  
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenterArea;
