import React, {useState, useRef} from "react";
import {useSelector, useDispatch } from 'react-redux';
import { Button, Box, Avatar, Typography, TextField, TextareaAutosize, Switch, FormControlLabel, FormGroup } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FiUpload } from 'react-icons/fi';
import { MdOutlineDescription } from 'react-icons/md';
import { rProfileImagef, rBannerImagef, getCollectionImageSrc } from '../../../utils/formatImage';
import { uploadCollectionFile, updateOwnerCollection } from '../../../actions/collections'


const CollectionInfoCart = ({user, item, col})=>{
    //console.log(item)
    const {collection} = useSelector((state) => state.collections);
    //console.log(collection)
    const [open, setOpen] = useState(false);
    const [disableForm, setDisableForm] = useState(true);
    const [selectedfile, setSelectedfile] = useState('');
    const [selectedprofilefile, setSelectedprofilefile] = useState('');
    const [values, setValues] = useState({
        bannerimage:item?.banner, collectiondesc:item?.description, thumbnailimage:item?.thumbnail, collectionurl:item?.url_name
   });
   const dispatch = useDispatch();
   const ref = useRef();

    const addDefaultSrc = (ev)=>{
        ev.target.src = '/images/logo/NoNFTimage.png'
     }

    const handleClickModal = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setValues({ bannerimage:item?.banner, collectiondesc:item?.description, thumbnailimage:item?.thumbnail, collectionurl:item?.url_name});
        setOpen(false);
    };

    const handleChange = (prop) => (event) => {
            setValues({...values, [prop]: event.target.value})
        
        if(event.target.value.length!=0) {setDisableForm(false)} else {setDisableForm(true)}
    };

    const setprofilepic = (name, bol)=>{
        const fileext = String(name.split('.')[1]);
        if(bol === true){
                setValues({...values, bannerimage: `banner.png`});   
        }else{
            if(fileext != 'gif'){
                setValues({...values, thumbnailimage: `thumbnail.png`});
            }else {
                setValues({...values, thumbnailimage: `thumbnail.${fileext}`});
            }
        }
    }

    const editcollection = async (e)=>{
        e.preventDefault();
        if(selectedfile){
           if(selectedfile.type==='image/png' || selectedfile.type==='image/jpeg' || selectedfile.type==='image/jpg'){
              if(selectedfile.size<500000){
                const imageresized = await rBannerImagef(selectedfile);  
                const fd = new FormData();
                fd.append('files', imageresized);
                fd.append('name', values.bannerimage);
                fd.append('url', item.url_name);
                fd.append('banner', 1);
                dispatch(uploadCollectionFile(fd))
              } 
           }
        }
        if(selectedprofilefile){
            if(selectedprofilefile.type==='image/png' || selectedprofilefile.type==='image/jpeg' || selectedprofilefile.type==='image/jpg' || selectedprofilefile.type==='image/gif'){
                if(selectedprofilefile.size<500000){  
                    let profileimageresized;   
                    if(selectedprofilefile.type==='image/gif'){
                        profileimageresized = selectedprofilefile
                     }else{
                        profileimageresized = await rProfileImagef(selectedprofilefile); 
                     }                
                    //const profileimageresized = await rProfileImagef(selectedprofilefile);  
                    const fdprof = new FormData();
                    fdprof.append('files', profileimageresized);
                    fdprof.append('name', values.thumbnailimage);
                    fdprof.append('url', item.url_name);
                    fdprof.append('banner', 0);
                    dispatch(uploadCollectionFile(fdprof))
               } 
            }
         }
        dispatch(updateOwnerCollection(values));
        setOpen(false);
      }
  


    return (
        <>
            <div className={'col-lg-4 col-md-6'}>
                <div className="trending-item">
                    <div className="trending-img">
                
                            <a href={`/collection/${item?.url_name}`}>
                                <img 
                                src={getCollectionImageSrc(item, true)}
                                alt={item?.name} 
                                onError={addDefaultSrc}
                                />
                            </a>
                    

                        {/* <div className="trending-user">
                            
                                <a href="/author-profile" className="trending-user-option">
                                    <img
                                        src="../images/trending/trending-user1.jpg"
                                        alt="Images"
                                        onError={addDefaultSrc}
                                    />
                                    <span>Created by {user?.username}</span>
                                </a>
                        
                        </div> */}
                        <button
                            type="button"
                            className="default-btn border-radius-5"
                            //onClick={() => router.push("/add-wallet")}
                            onClick={handleClickModal}
                        >
                            Edit
                        </button>
                        {/* <div className="trending-title">
                            <span>
                                {item?.url_name}  12/14
                            </span>
                            <h3>Bid 80 ETH</h3>
                        </div> */}
                    </div>

                    <div className="content">
                        <h3>
                                <a href={`/collection/${item?.url_name}`}>{item?.url_name}</a>
                        </h3>
                        {/* <span>
                            <i className="ri-heart-line"></i> 340
                        </span> */}
                    </div>
                </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit {item?.name}'s Collection Information</DialogTitle>
                <DialogContent>
                    {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Avatar sx={{ width: 30, height: 30 }}> <FaUser/> </Avatar>
                        <TextField fullWidth sx={{marginLeft:1}} id="input-username" label="username" variant="standard" value={values.profileusername} onChange={handleChange('profileusername')}/>
                        <Typography variant="subtitle2"> Username</Typography>
                    </Box> */}

                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Avatar sx={{ width: 30, height: 30 }}> <MdOutlineDescription/> </Avatar>
                        <TextareaAutosize
                            fullwidth
                            minRows={4}
                            aria-label="maximum height"
                            placeholder="Tell us about this collection"
                            style={{ width: 400, marginTop: 30, marginLeft:7, marginRight:3 }}
                            value={values.collectiondesc} 
                            onChange={handleChange('collectiondesc')}
                        />
                        <Typography variant="subtitle2"> Description</Typography>
                    </Box>


                    {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Avatar sx={{ width: 30, height: 30 }}> <FaTwitter/> </Avatar>
                        <TextField fullWidth sx={{marginLeft:1}} id="input-twitter" label="twitter" helperText="link must start with https://" variant="standard" value={values.twithandle} onChange={handleChange('twithandle')}/>
                        <Typography variant="subtitle2"> Twitter</Typography>
                    </Box> */}

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop:3 }}>
                        <Avatar sx={{ width: 30, height: 30, marginRight:1 }}> <FiUpload/> </Avatar>
                        {/* <div><input type="file" name="fileupload" onChange={(e)=>{setValues({...values, profilepic:e.target.files[0]}); if(values.length!=0) {setDisableForm(false)} else {setDisableForm(true)} }} /></div> */}
                        <input ref={ref} fullwidth accept='image/png, image/jpg, image/jpeg' title=".png, .jpg, .jpeg only. 320X1440 picture dimension. 500KB Maximum file size" type="file" name="fileuploadbanner" onChange={(e)=>{setSelectedfile(e.target.files[0]); setprofilepic(e.target.files[0].name, true); if(values.length!=0) {setDisableForm(false)} else {setDisableForm(true)} }} />
                        <Typography variant="subtitle2"> Collection Page Banner Picture 500KB Maximum file size</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', marginTop:3 }}>
                        <Avatar sx={{ width: 30, height: 30, marginRight:1 }}> <FiUpload/> </Avatar>
                        {/* <div><input type="file" name="fileupload" onChange={(e)=>{setValues({...values, profilepic:e.target.files[0]}); if(values.length!=0) {setDisableForm(false)} else {setDisableForm(true)} }} /></div> */}
                        <input ref={ref} accept='image/gif, image/png, image/jpg, image/jpeg' fullwidth title=".gif, .png, .jpg, .jpeg only. 540X540 picture dimension. 500KB Maximum file size" type="file" name="fileupload" onChange={(e)=>{setSelectedprofilefile(e.target.files[0]); setprofilepic(e.target.files[0].name, false); if(values.length!=0) {setDisableForm(false)} else {setDisableForm(true)} }} />
                        <Typography variant="subtitle2"> Collection Profile Picture 500KB Maximum file size</Typography>
                    </Box> 
                    {/* <input type="hidden" name="_csrf" value="{{csrfToken}}"/> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button disabled={disableForm} onClick={(e)=>{editcollection(e)}}>Edit Collection</Button>
                    {/* <Button type='submit' disabled={disableForm}>Edit Profile</Button> */}
                </DialogActions>
            </Dialog>
        </>
    )
}


export default CollectionInfoCart;

