import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, OutlinedInput, MenuItem, Select, Chip, FormHelperText, FormControl} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useWeb3React } from "@web3-react/core"

import Loading from "../../../images/cardicons/loading.gif";
import Snackalert from '../../Buttons/Snackalert';

import { NewsLetterEl, Title, Sub, Input, ChBox, Btn, PL } from "./Styled";
import { EmailCheck } from '../../../utils/validate'
import {createWhitelister} from '../../../actions/whitelist'




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, category, theme) {
  return {
    fontWeight:
      category.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const WhitelistForm = ({whitelister}) => {
    console.log(whitelister);
      const [values, setValues] = useState({
        walletaddress:'', twitter:'', instagram:'', discord:'', youtube:'', reddit:'', medium:'', email:'', terms:''
      });
      const [disableForm, setDisableForm] = useState(true);
      const [isloading, setisLoading] = useState(false);
      const dispatch = useDispatch();
    
      const handleSubmit = (e)=>{
        e.preventDefault()
        if( EmailCheck(values.email) === true && values.terms===true){
          
          dispatch(createWhitelister(values));
          clear();
          
        }
      
      }
    
      const val = (prop, input)=>{
        const walletaddress = prop ==='walletaddress'? input : values.walletaddress;
        const termscheck = prop==='terms'?input : values.terms;
        const emailcheck = prop==='email'?input : values.email;
    
        if(input){
            if (walletaddress.length!=0 && termscheck===true && emailcheck.length!=0){
                setDisableForm(false)
            }else {
                setDisableForm(true)
            }
        }else{
            setDisableForm(true)
        }
      }
    
      const clear =()=>{
        setValues({walletaddress:'', twitter:'', instagram:'', discord:'', youtube:'', reddit:'', medium:'', email:'', term:''})
      }
    
   
    const theme = useTheme();
    const userprofile= JSON.parse(localStorage.getItem('profile')); 
    const { library } = useWeb3React();

    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = useState(false);
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };



    useEffect(() => {
        if(whitelister){
            setValues({walletaddress:whitelister.address, email:whitelister.email, twitter:whitelister.twitterinfo, instagram:whitelister.instagraminfo, discord:whitelister.discordinfo, youtube:whitelister.youtubeinfo, reddit:whitelister.redditinfo, medium:whitelister.mediuminfo})
        }
    }, [whitelister]);

    return (
    <>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='collection-form-area'>
            <div className='section-title'>
                <h2>Get Whitelisted</h2>
                <h6>Being whitelisted gives you advance access to super limited NFT drops with minters royalty. Whitelisted addresses get access to NFT drops 10 minutes before it officially starts. Additional 5 minutes head start will be given for each completed social media task. </h6>
            </div>

            <div className='collection-form'>
                <form onSubmit={handleSubmit}>      
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Wallet Address *</label>
                                <input
                                type='text'
                                name='address'
                                id='address'
                                className='form-control'
                                placeholder='enter wallet address'
                                onChange={(e)=>{setValues({...values, walletaddress:e.target.value}); val('walletaddress',e.target.value)}}
                                value={values.walletaddress}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values?.walletaddress?.length>0?true:false}>Enter Wallet Address</FormHelperText>
                            </div>
                        </div>


                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Email </label>
                                <input
                                type='text'
                                name='email'
                                id='email'
                                className='form-control'
                                placeholder='enter email to get advance notice of upcoming NFT drops'
                                onChange={(e)=>{setValues({...values, email:e.target.value}); EmailCheck(values.email) === true ? val('email',e.target.value) : val('email','')}}
                                value={values.email}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values?.email?.length>0?true:false}>Enter Email Address</FormHelperText>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Twitter handle - follow us on twitter and retwit any of our twits.</label>
                                <input
                                type='text'
                                name='twitter'
                                id='twiter'
                                className='form-control'
                                placeholder='enter twitter handle to verify task completion'
                                onChange={(e)=>{setValues({...values, twitter:e.target.value})}}
                                value={values.twitter}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Instagram handle - Follow us on instagram and repost our posts</label>
                                <input
                                type='text'
                                name='instagram'
                                id='instagram'
                                className='form-control'
                                placeholder='enter twitter handle so we can verify task completion'
                                onChange={(e)=>{setValues({...values, instagram:e.target.value})}}
                                value={values.instagram}
                                />
                                
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Reddit handle - join our reddit group </label>
                                <input
                                type='text'
                                name='reddit'
                                id='reddit'
                                className='form-control'
                                placeholder='enter reddit handle so we can verify task completion'
                                onChange={(e)=>{setValues({...values, reddit:e.target.value})}}
                                value={values.reddit}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Discord handle - join our discord group </label>
                                <input
                                type='text'
                                name='discord'
                                id='discord'
                                className='form-control'
                                placeholder='enter discord handle so we can verify task completion'
                                onChange={(e)=>{setValues({...values, discord:e.target.value})}}
                                value={values.discord}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Medium - subscribe to your medium page and comment on our articles. </label>
                                <input
                                type='text'
                                name='medium'
                                id='medium'
                                className='form-control'
                                placeholder='enter medium handle so we can verify task completion'
                                onChange={(e)=>{setValues({...values, medium:e.target.value})}}
                                value={values.medium}
                                />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Youtube - Subscribe to our channel and comment on our videos. </label>
                                <input
                                type='text'
                                name='medium'
                                id='medium'
                                className='form-control'
                                placeholder='enter youtube name so we can verify task completion'
                                onChange={(e)=>{setValues({...values, medium:e.target.value})}}
                                value={values.medium}
                                />
                            </div>
                        </div>

                        <ChBox>
                            <input checked={values.terms} onChange={(e) => {setValues({...values, terms:e.target.checked});  val('terms',e.target.checked)}} type="checkbox" id="terms" />
                            <label htmlFor="terms">
                            I have read and agree to the <PL href="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions </PL> and  <PL href="/policy" target="_blank" rel="noopener noreferrer">Privacy Policy</PL> of this site
                            </label>
                            <FormHelperText sx={{color:'red', textAlign: 'center'}} hidden={values.terms===false?false:true}>Must agree to Terms and Conditions and Privacy Policy</FormHelperText>
                        </ChBox>

                        <div className='col-lg-12 col-md-12'>
                            <button
                                type='submit'
                                className='default-btn border-radius-5'
                                disabled={isloading? isloading : disableForm}
                                style={{maxWidth:'140px'}}
                                onClick={handleSubmit}
                            >
                                {isloading?  <img src={Loading} style={{mixBlendMode: 'multiply', transform: 'scale(1.7)'}} className=""/> :"Submit"}
                            </button>
                        </div>
                                 
                    </div>  
                        
                       
                </form>
            </div>
        </div>
    </>
  );
};

export default WhitelistForm;
