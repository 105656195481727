import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem } from '@mui/material';

import { getCollection, getCollectionBySearch } from '../../../actions/nfts';

import { Link } from 'react-router-dom';

const Paginate = ({ page, sort, searchQuery, collection }) => {
  const { numberOfPages } = useSelector((state) => state.nfts);
  const dispatch = useDispatch();
 
  
  const pagelink = (itempage)=> {
    if(searchQuery){ 
        return (`/collection/${collection}?searchQuery=${searchQuery}&page=${itempage}&sort=${sort}`)
     }else {
       return (`/collection/${collection}?page=${itempage}&sort=${sort}`)
     }
  }

  useEffect(() => {
    if(searchQuery){
      dispatch(getCollectionBySearch({collection:collection, page:page, sort:sort, searchQuery:searchQuery}));
    }else {
      dispatch(getCollection({collection:collection, page:page, sort:sort}))
    }
  }, [dispatch, page, sort, searchQuery, collection]);

  return (
    <Pagination
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem {...item} component={Link} to={()=>pagelink(item.page)} />
      )}
    />
  );
};

export default Paginate;