import React from 'react';

import { useSelector } from 'react-redux';
import { getCollectionImageSrc } from '../../../utils/formatImage'

const PageBannerStatic = ({page}) => {
  // const { collection } = useSelector((state) => state.collections);

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimageBanner.png'
   }

  const dataimg = require(`../../../images/static/${page.file}.jpg`);

  return (
    <>
      <div className='inner-banner'>
        <img className='inner-banner-img'
            src={dataimg}
            alt='Banner'
            onError={addDefaultSrc}
        />
        <div className='container'>
          <div className='inner-title'>
            {/* <h3>{collection?.name}</h3> */}
            
            <h3><a href={`/${page.page}`}> {page.page}</a></h3>

            <div className='inner-shape'>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageBannerStatic;
