
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useWeb3React } from "@web3-react/core"
import { useHistory } from 'react-router-dom';
import { Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/ethereum-provider';
import { injected, walletconnect, resetWalletConnector, walletlink } from '../../../components/Blockchain/Connector';
import * as actionType from '../../../constants/actionTypes';
import { getConnectedUser } from '../../../actions/users';



const ConnectWallet = () => {
    const { account, activate, deactivate } = useWeb3React();
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = React.useState(false);
    const [metamask, setMetamask] = useState(false);
    const [connectWallet, setConnectWallet] = useState(false);
    const [connectLink, setConnectLink] = useState(false);
    const [walletConnectAcc, setWalletConnectAcc] = useState(localStorage.getItem('walletconnect')); 

    const history = useHistory();
    const dispatch = useDispatch();

    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };

    const ConnectWalletConnect = async()=> {
        try {
			resetWalletConnector(walletconnect);
			await activate(walletconnect);
            setConnectWallet(true);
            dispatch({ type: actionType.CONNECT, payload:'connected' });
            localStorage.setItem('walletconnect', 'engaged');
            account&& history.push('/');
		} catch (ex) {
			console.log(ex);
		}
      }

    const ConnectWallet = async()=> {
        try {
            await activate(injected)
            setMetamask(true);
            dispatch({ type: actionType.CONNECT, payload:'connected' });
            localStorage.setItem('metamask', 'engaged');
            history.push('/');
        } catch (ex) {
            console.log(ex)
        }

    }

    const ConnectWalletLink = async()=> {
        try {
            await activate(walletlink);
            dispatch({ type: actionType.CONNECT, payload:'connected' });
            localStorage.setItem('walletlink', 'engaged');
            setConnectLink(true);
            history.push('/');
        } catch (ex) {
            console.log(ex)
        }

    }

    const connectWaletConnect = async () => {
		try {
			const RPC_URLS = {
				1: 'https://mainnet.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4',
				4: 'https://rinkeby.infura.io/v3/55d040fb60064deaa7acc8e320d99bd4'
			};
			const provider = new WalletConnectProvider({
				rpc: {
					1: RPC_URLS[1],
					4: RPC_URLS[4]
				},
				qrcode: true,
				pollingInterval: 15000
			});
            //dispatch({ type: actionType.PROVIDER, payload:provider });
            localStorage.setItem('walletconnect-provider', JSON.stringify(provider.toString()));
			//setWalletConnectProvider(provider);
			const accounts = await provider.enable();
			const account = accounts[0];

            
			const library = new Web3Provider(provider, 'any');
            //dispatch({ type: actionType.LIBRARY, payload:library });
            dispatch({ type: actionType.CONNECT, payload:'connected' });
            localStorage.setItem('walletconnect-library', library);
            setConnectWallet(true);
			// console.log('library');
			// console.log(library);
			//setWeb3Library(library);
			//setWeb3Account(account);
		} catch (ex) {
			console.log(ex);
		}
	};

    const disconnectWalletconnect = ()=>{
      const walletconnectProvider = localStorage.getItem('walletconnect'); 
      //let datainfo = JSON.parse(walletconnectProvider)
      var myFunc = eval('(' + walletconnectProvider + ')');
      //console.log(myFunc)
      myFunc.disconnect()
		//connectProvider.disconnect()
		// setWalletConnectProvider(null);
       // dispatch({ type: actionType.PROVIDER, payload:null });
	}


    const logout = async () => {
        await deactivate();
        dispatch({ type: actionType.LOGOUT });
        handleSnack('Please connect wallet.','error');
        setMetamask(false)
        setConnectWallet(false)
        setConnectLink(false);
        history.push('/');

    };

    const redirect = ()=>{
        account && history.push('/')
    }
    //redirect()
   
    useEffect(() => {
        if(account){
            
          dispatch(getConnectedUser(account));
          //localStorage.setItem('profile', JSON.stringify(account));
          localStorage.getItem('metamask') && setMetamask(localStorage.getItem('metamask'))
          localStorage.getItem('walletconnect') && setMetamask(localStorage.getItem('walletconnect'))
          localStorage.getItem('walletlink') && setMetamask(localStorage.getItem('walletlink'))
          
        }else{
            //localStorage.clear();
           // dispatch({ type: actionType.LOGOUT });
            handleSnack('Please connect to MetaMask.','error');
            setMetamask(false)
            setConnectWallet(false)
            //localStorage.clear();
            //disconnectWalletconnect()
         
        }
      }, [account]);
    return (  
                <div className='wallet-area pt-70 pb-70'>
                    <div className='container'>
                        <div className='section-title'>
                            <h2>Connect Your wallet</h2>
                            <p>
                            We support a wide variety of wallet options. Connect with almost any desktop and browser wallet that supports the Ethereum, Polygon and Binance ecosystem. 
                            </p>
                        </div>

                        <div className='row pt-45'>
                            <div className='col-lg-4 col-6' style={{cursor:'pointer'}} onClick={account ? logout : ConnectWallet}>
                                <div className='wallet-card' >
                                    <img src='./images/connect/metamask-fox.svg' height='70px' width='70px' alt='Metamask Logo' />
                                    <h3>
                                        {metamask ? 'Disconnect Metamask' : 'Connect Metamask'}
                                       
                                    </h3>
                                    <p>
                                        {/* {metamask ===true && 'Account Connected: ' +account?.split('').splice(0, 10).join('')}{'...'}{account?.split('').splice(32)} */}
                                   
                                    </p>
                                    {/* <div className='top-btn'>Simple</div> */}
                                </div>
                            </div>

                            <div className='col-lg-4 col-6' style={{cursor:'pointer'}} onClick={walletConnectAcc ? disconnectWalletconnect : connectWaletConnect}>
                                <div className='wallet-card'>
                                    <img src='./images/connect/ConnectWallet-Logo.svg' height='70px' width='70px' alt='Wallet Connect Images' />
                                    <h3>
                                        {walletConnectAcc ? 'Disconnect WalletConnect' : 'WalletConnect'}
                                        
                                    </h3>
                                    <p>
                                        {/* {account && 'Account Connected: ' +account.split('').splice(0, 10).join('')}{'...'}{account.split('').splice(32)} */}
                                    </p>
                                    {/* <div className='top-btn'>Credit Card Follow</div> */}
                                </div>
                            </div>

                            <div className='col-lg-4 col-6'  style={{cursor:'pointer'}} onClick={account ? logout : ConnectWalletLink}>
                                <div className='wallet-card'>
                                    <img src='./images/connect/Coinbase-Logo.png' height='70px' width='70px' alt='Coinbase Logo' />
                                    <h3>
                                        {connectLink ? 'Disconnect Coinbase' : 'Coinbase'}
                                    
                                    </h3>
                                    <p>
                                     {/* {account && 'Account Connected: ' +account.split('').splice(0, 10).join('')}{'...'}{account.split('').splice(32)} */}
                                    </p> 
                                    {/* <div className='top-btn'>Simple</div>*/}
                                </div>
                            </div>

                            {/* <div className='col-lg-4 col-6'>
                                <div className='wallet-card'>
                                    <img src='../images/wallet-img/wallet-img4.png' alt='Images' />
                                    <h3>
                                    <a href='contact.html'> Jenmar</a>
                                    </h3>
                                    <p>
                                    Connect with your google, facebook, twitter or discord all
                                    social media
                                    </p>
                                </div>
                            </div> */}

                            {/* <div className='col-lg-4 col-6'>
                                <div className='wallet-card'>
                                    <img src='../images/wallet-img/wallet-img5.png' alt='Images' />
                                    <h3>
                                    <a href='contact.html'>Denky</a>{' '}
                                    </h3>
                                    <p>
                                    Connect with your google, facebook, twitter or discord all
                                    social media
                                    </p>
                                </div>
                            </div> */}


                            {/* <div className='col-lg-12'>
                                <div className='wallet-text'>
                                    <p>
                                    We do not own your private keys and cannot access your funds
                                    without your confirmation.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

    );
};

export default ConnectWallet;