import styled from "styled-components";
import { Colors, Devices } from "../../Theme/KittyNftTheme/Theme";
import Button from "../../Theme/KittyNftTheme/Button";


export const NewsLetterEl = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: url("/images/subscribe.png");
  background-color: ${Colors.Gray};
  background-size: cover;
  background-repeat: no-repeat;
  padding: 6rem 1rem;
  gap: 1rem;
  @media ${Devices.MobileL} {
    padding: 6rem 4rem;
  }
  @media ${Devices.Tablet} {
    padding: 6rem;
  }
  @media ${Devices.Laptop} {
    padding: 6rem 30%;
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 5vw;
  @media ${Devices.Laptop} {
    font-size: 4vw;
  }
`;
export const Sub = styled.span`
  font-size: 1.12rem;
`;
export const Input = styled.div`
  display: flex;
  height: 3rem;
  width: 100%;
  border: outlined;
  > input {
    border: none;
    border-radius: 25px 0 0 25px;
    padding: 0.5rem 1rem;
    font-size: 1.05rem;
    flex: 1;
  }
`;
export const Btn = styled(Button)`
  border-radius: 0 25px 25px 0;
  font-weight: 600;
  font-size: 1.01rem;
`;
export const Text = styled.p`
  font-size: 0.85rem;
`;
export const PL = styled.a`
  color: ${Colors.Red};
`;
export const ChBox = styled.div`
  font-size: 0.85rem;
  input {
    margin-right: 1rem;
  }
`;