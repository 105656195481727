import * as api from '../api/index.js'  
import { FETCH_USER, END_LOADING, START_LOADING, FETCH_CONNECTED_USER, FETCH_OWNER } from '../constants/actionTypes.js';

export const updateUser = (user) => async(dispatch) => {
    try{
       
        await api.updateUser(user);
        
        const { data: data } = await api.getUser(user.profileacc);
        dispatch({ type: FETCH_CONNECTED_USER, payload: {data}});
        dispatch({ type: END_LOADING });
    

    }catch(error){
        console.log(error.message);
    }
}

export const createUser = (user) => async(dispatch) => {

    try{
      
        await api.createUser(user);
        
    }catch(error){
        console.log(error.message);
    }
}

export const getUser = (acc) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data:  data  } = await api.getUser(acc);
     
      dispatch({ type: FETCH_USER, payload: {data} });
      dispatch({ type: END_LOADING });
  
    } catch (error) {
      console.log(error);
    }
};

export const getOwner = (acc) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data:  data  } = await api.getUser(acc);
      
      dispatch({ type: FETCH_OWNER, payload: {data} });
      dispatch({ type: END_LOADING });
  
    } catch (error) {
      console.log(error);
    }
};

export const getConnectedUser = (acc) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data: data } = await api.getUser(acc);
    
      dispatch({ type: FETCH_CONNECTED_USER, payload: {data}});
      dispatch({ type: END_LOADING });
  
    } catch (error) {
      console.log(error);
    }
};

export const uploadUserImage = (file) => async (dispatch) =>{
  try{
    dispatch({ type: START_LOADING });
   
    const data = await api.uploadimage(file);
    dispatch({ type: END_LOADING });
    return data

  }catch(error){
    console.log(error);
  }
}

