import React from 'react';
import {fDate} from '../../../utils/formatTime'
import {fShortenNumber} from '../../../utils/formatNumber'
import { FaShoppingCart } from "react-icons/fa";
import { GiBlacksmith } from 'react-icons/gi'
import Transactinicon from "../../../images/cardicons/minticon.png";
import Copy from '../../Buttons/Copy';
import moment from 'moment';

const PageDetailsTransactions = ({item}) => {
  if(item.length===0){return (
    <div className='item-details-user-area'>
        <h3>NFT Activities </h3>
          <div className='item-details-user-into'>
            <div className='row'>
               <div className='col-lg-12'>
                  <div className='item-details-user-card'>
                     No transaction
                  </div>
                </div>
            </div>
          </div>
     </div>
     
  )
  }

  return (
    <>
      <div className='item-details-content'>
        <h3>NFT Activities </h3>
          <div key='id1' className='item-details-into'>
            <div key='id2' className='row'>
              {item.map((prop, index) => (
               
                <div key={index+'A'.toString()} className='col-lg-12'>
                  <div key={index+'B'.toString()} className='item-details-card'>
                    
                    <div key={index+'C'.toString()} className='item-details-card-img'>
                      {prop.type==='BUY'&&<i key={index+'D'.toString()}><FaShoppingCart key={index+'E'.toString()}/> Sold </i> }
                     
                      {prop.type==='MINT'&&<i key={index+'F'.toString()}><GiBlacksmith key={index+'G'.toString()}/> Mint </i>}
                     
                    </div>
                    <div key={index+'H'.toString()} className='item-details-card-content'>
                      <h3 key={index+'I'.toString()}>
                        To: <b key={index+'J'.toString()}>{prop.to_acc.split('').splice(0, 8).join('')}{'...'}{prop.to_acc.split('').splice(35)} <Copy text={prop.to_acc} /></b>
                      </h3>
                      <span key={index+'K'.toString()}>
                        From: <b key={index+'L'.toString()}>{prop.fromacc.split('').splice(0, 8).join('')}{'...'}{prop.fromacc.split('').splice(35)} <Copy text={prop.fromacc} /></b>
                      </span>
                    </div>
                    <div key={index+'M'.toString()} className='work-hours'>
                      <h3 title={prop.price} key={index+'N'.toString()}>{fShortenNumber(prop.price)} {prop.chain}</h3>
                      <span key={index+'O'.toString()}>{moment(prop.date).fromNow()}</span>
                    </div>
                   
                  </div>
                </div> 
               
              ))}
            </div>
          </div>
      </div>
    </>
  );
};

export default PageDetailsTransactions;


