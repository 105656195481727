import React, { useEffect, useState } from 'react';
import NFTDropPageDetail from "../../../components/Cards/PageDetailNFTDropDescription/NFTDropPageDetail";
import PageDetailTransactions from "../../../components/Cards/PageDetailTransactions/PageDetailsTransactions";
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { likeNFTDrop, getNFTdrop } from '../../../actions/nfts';
import { getNFTDropTransactions } from '../../../actions/transactions';
import { getUser, getConnectedUser } from '../../../actions/users';
import { getNFTImageSrc } from '../../../utils/formatImage';
import { useParams } from 'react-router-dom';
import { BiHeart } from "react-icons/bi";
import MediaDisplay from '../../../components/Cards/MediaDisplay/MediaDisplay';

const PageDetailNFTDrop= () => {
  const { isLoading, nftdrop } = useSelector((state) => state.nfts);
  const {user, connecteduser} = useSelector((state) => state.users);
  const { droptransaction } = useSelector((state) => state.transactions);
  const {connectData} = useSelector((state) => state.connect);
  const dispatch = useDispatch();
  const { id, contract } = useParams();
  const userprofile= JSON.parse(localStorage.getItem('profile'));
  const [likecount, setLikecount] = useState(''); 

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimageBig.png'
  }

  // const likereload = async (nftdropid, dropid)=>{
  //   await dispatch(likeNFTDrop(nftdropid));
  //   console.log(id)
  //   dispatch(getNFTdrop(dropid));
  // }

  const Likes = () => { 
      if(connectData){
          return connecteduser?.dropslikes?.split(',').find((like) => like === nftdrop?.id.toString()) 
          ? (
                  <><p>
                  <i title='you have liked this item'><BiHeart /> </i>
                  {/* {nftdrop?.likes} */}
                  {likecount? likecount : nftdrop?.likes}
                  </p>
                  </>
          ) : (
            !likecount?
              <><p>
                  <i type='button' onClick={() => dispatch(likeNFTDrop(nftdrop.id, setLikecount))}><BiHeart /> </i>
                  {/* <i type='button' onClick={() => likereload(nftdrop.id, id)}><BiHeart /> </i> */}
                  {/* {nftdrop?.likes} */}
                  {likecount? likecount : nftdrop?.likes}
                  </p>
                  </>
              :
              <><p>
                <i title='you have liked this item'><BiHeart /> </i>
                {likecount? likecount : nftdrop?.likes}
                </p>
              </>
          )
      }
      return  <><p>
                  <i title='connect wallet to like item'><BiHeart/> </i>
                  {/* {nftdrop?.likes} */}
                  {likecount? likecount : nftdrop?.likes}
                </p></>;
  };

  useEffect(() => {
    dispatch(getNFTdrop(id));
  }, [id, dispatch]);

  
 

  useEffect(()=>{
    const trandata = {contract:contract, dropid:id};
    dispatch(getNFTDropTransactions(trandata))
    dispatch(getUser(nftdrop?.owner));
    dispatch(getConnectedUser(userprofile));
  }, [nftdrop, dispatch])

  if (!nftdrop || !droptransaction) return null;

  if (isLoading || !nftdrop || !droptransaction) {
    return (
      <div className='item-details-area pt-100 pb-70'>
        <div className='container'>
          <div className='row'>
           <CircularProgress size="5em" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='item-details-area pb-70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='item-details-left-side pr-20'>
                <div className='item-details-img'>
                  <MediaDisplay item={nftdrop} drop={true} detail={true}/>
                  {/* <img className='trimdetail'
                    src={getNFTImageSrc(nftdrop)}
                    alt={nftdrop?.name} 
                    onError={addDefaultSrc}
                  /> */}
                  <span>
                    <Likes/>
                  </span>
                </div>

                <PageDetailTransactions item={droptransaction} />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='item-details-dsce'>
                <NFTDropPageDetail item={nftdrop} creatordata={user} />

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default PageDetailNFTDrop;