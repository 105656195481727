import React from 'react';
import { MyNFTcontainer } from '../containers';


const MyNFTs = () => {
   
    return(
        <div>
            <MyNFTcontainer/>
        </div>
    );

}

export default MyNFTs;
