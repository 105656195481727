import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';

import PaginationCollection from './PaginationCollection';
import DiscoverSidebar from '../discoverSidebar/DiscoverSidebar';
import DiscoverSidebarCollection from '../discoverSidebar/DiscoverSidebarCollection';
import NFTCard from "../../../components/Cards/NFTCard/NFTCard";
import DropCard from "../../../components/Cards/NFTDrop/NFTDrop";
import NFTTopCollection from "../../../components/Cards/NFTTopCollection/NFTTopCollection";
import ActiveandUpcoming from "../../../components/Buttons/ActiveandUpcoming";

const DiscoverArea = () => {
  const { nfts, nftdrops, isLoading } = useSelector((state) => state.nfts);
  const { collections, isLoadingCollection } = useSelector((state) => state.collections);
  const query = useQuery();
  const page = query.get('page') || 1;
  const sort = query.get('sort') || 1;
  const cat = query.get('category');
  const searchQuery = query.get('searchQuery');
  const pathname = useLocation().pathname.split('/')[2];
  const blockchain = query.get('blockchain');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  return (
    <>
      <div className='discover-area pt-70 pb-70'>
        <div className='container'>
          <div className='section-title'>
            <h2>{pathname==="nftdrop" ? "NFT Drops" : pathname==="collections"? "Discover NFT Collections" : "Discover NFT Assets"}</h2>
          </div>
          <div >
            <h6>{pathname==="nftdrop" && (
            <ActiveandUpcoming  page={page}/>
             
            )}</h6>
          </div>

          <div className='row pt-45'>

            <div className='col-lg-3'>
              {pathname==='collections'?(<DiscoverSidebarCollection />)
              :(<DiscoverSidebar />)}
              {/* <DiscoverSidebar /> */}
            </div>
            
            <div className='col-lg-9'>
              {pathname ==='nftdrop' ? (
                <div className='row '>
                      {nftdrops?.map((nftdrop) => {    
                               return(<DropCard key={nftdrop.id} item={nftdrop} col={4} />);
                               
                      })}   
                </div>
              ) :
              (pathname==='collections'?(
                <div className='row'>
                      {collections?.map((collection) => {    
                              return(<NFTTopCollection key={collection.id} item={collection} col={4} />);
                      })}
                  
                </div>
              )
              :(
                <div className='row'>
                      {nfts?.map((nft) => {    
                              return(<NFTCard key={nft.id} item={nft} col={4} />);
                      })}
                  
                </div>
              ))}
                  <PaginationCollection page={page} sort={sort} category={cat} searchQuery={searchQuery} blockchain={blockchain} />
              
            </div>

            
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverArea;
