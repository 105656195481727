import React, { useEffect, useState} from 'react';
import PageDetailDescription from "../../../components/Cards/PageDetailDescription/PageDetailsDescription";
import PageDetailTransactions from "../../../components/Cards/PageDetailTransactions/PageDetailsTransactions";
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getNFT, likePost } from '../../../actions/nfts';
import { getTransactions } from '../../../actions/transactions';
import { getUser, getOwner, getConnectedUser } from '../../../actions/users';
import { getNFTImageSrc } from '../../../utils/formatImage';
import { useParams } from 'react-router-dom';
import { BiHeart } from "react-icons/bi";
import MediaDisplay from '../../../components/Cards/MediaDisplay/MediaDisplay';

const PageDetailsOverall = () => {
  const { nft, isLoading } = useSelector((state) => state.nfts);
  const {user, connecteduser, nftowner} = useSelector((state) => state.users);
  const { transaction } = useSelector((state) => state.transactions);
  const {connectData} = useSelector((state) => state.connect);
  const [likecount, setLikecount] = useState('');
  const dispatch = useDispatch();
  const { contract, tokenid } = useParams();
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
 
  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimageBig.png'
  }

  

  // const onLikeClick = async (nftid, setLikecount)=>{
  //   // const newNFTData = await dispatch(likePost(nftid, setLikecount));
  //   dispatch(likePost(nftid, setLikecount));
  //  // console.log(newNFTData.likes)
  //   //setLikecount(newNFTData.likes)

  // }

  const Likes = () => { 
      if(connectData){
          return connecteduser?.likes?.split(',').find((like) => like === nft?.id.toString())
          ? (
                  <><p>
                  <i title='you have liked this item'><BiHeart /> </i>
                  {/* {nft?.likes} */}
                  {likecount? likecount : nft?.likes}
                  </p>
                  </>
          ) : (
            !likecount?
              <><p>
                  <i type='button' onClick={() => dispatch(likePost(nft?.id, setLikecount))}><BiHeart /> </i>
                  {/* <i type='button' onClick={() => onLikeClick(nft?.id, setLikecount)}><BiHeart /> </i> */}
                  {/* {nft?.likes} */}
                  {likecount? likecount : nft?.likes}
                  </p>
                  </>
                :
                <><p>
                  <i title='you have liked this item'><BiHeart /> </i>
                  {/* {nft?.likes} */}
                  {likecount? likecount : nft?.likes}
                  </p>
                </>

          )
      }
      return  <><p>
                  <i title='connect wallet to like item'><BiHeart/> </i>
                  {/* {nft?.likes} */}
                  {likecount? likecount : nft?.likes}
                </p></>;
  };
  
 

  useEffect(() => {
    dispatch(getNFT(contract, tokenid));
  }, [contract, tokenid, dispatch]);

  const trandata = {contract:nft?.contract, tokenid:nft?.tokenid};
  
  useEffect(()=>{
    dispatch(getTransactions(trandata))
    dispatch(getUser(nft?.creator));
    dispatch(getOwner(nft?.owner));
    dispatch(getConnectedUser(userprofile));
  }, [nft, dispatch])

  if (!nft || !transaction) return null;

  if (isLoading || !nft || !transaction) {
    return (
      <div className='item-details-area pt-100 pb-70'>
        <div className='container'>
          <div className='row'>
           <CircularProgress size="5em" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='item-details-area pt-70 pb-70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='item-details-left-side pr-20'>
                <div className='item-details-img'>
                  <MediaDisplay item={nft} drop={false} detail={true}/>
                  {/* <img className='trimdetail'
                    src={getNFTImageSrc(nft)}
                    alt={nft?.name} 
                    onError={addDefaultSrc}
                  /> */}
                  <span>
                    <Likes/>
                  </span>
                </div>

                <PageDetailTransactions item={transaction} />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='item-details-dsce'>
                <PageDetailDescription item={nft} creatordata={user} ownerdata={nftowner} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageDetailsOverall;
