import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import AuthorProfile from '../../../components/Cards/AuthorProfile/AuthorProfile';
import CreateNFTCollectionCardAdd from '../../../components/Cards/CreateNFTCollectionCard/CreateNFTCollectionCardAdd'
import { useWeb3React } from "@web3-react/core"
import { useHistory } from 'react-router-dom';
import { getUser } from '../../../actions/users';



const CreateNFCollectioAreaAdd = () => {
  const { account } = useWeb3React();
  const history = useHistory();
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const {connecteduser} = useSelector((state) => state.users);
  const dispatch = useDispatch();
  
  if(!account){
        history.push(`/create`);
  }
  
  const redirectcreateaccount = ()=>{
    history.push(`/profile/create`);
  }
  
  useEffect(() => {
     dispatch(getUser(userprofile));
  }, [userprofile]);

  if(!connecteduser){
    return(
      <div className='collection-widget-area pt-70 pb-70'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-3'>
                  <CircularProgress color="secondary" />
                  {redirectcreateaccount()}
                </div>
              </div>
            </div>
      </div>
    )
  }

  return (
    <>
      <div className='collection-widget-area pt-70 pb-70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3'>
              <AuthorProfile />
            </div>
            <div className='col-lg-9'>
              <CreateNFTCollectionCardAdd />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNFCollectioAreaAdd;
