import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
// import './introduction.css';
import Intro from "../../../components/Cards/Intro/BannerArea1";

const Introduction = ()=> {
    const { nftdrops } = useSelector((state) => state.nfts);

    if (!nftdrops) {
        return (
          <div className='item-details-area pt-70 pb-70'>
            <div className='container'>
              <div className='row'>
              <CircularProgress size="5em" />
              </div>
            </div>
          </div>
        );
    }

    return (
        <div>
            <Intro item={nftdrops[3]}/>
        </div>
    )   
};

export default Introduction;