import React from 'react';
import { useSelector } from 'react-redux';
import { getCollectionImageSrc } from '../../../utils/formatImage'
const randomnum = Math.floor(Math.random() * 3);

const NftDropPageBanner = () => {
  const { collection } = useSelector((state) => state.collections);
  const {nftowner} = useSelector((state) => state.users);

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimageBanner.png'
   }
  
  const dataimg = require(`../../../images/nftdrop-banner/${randomnum}.jpg`);
 
  return (
    <>
      <div className='inner-banner'>
        <img className='inner-banner-img'
            src={dataimg}
            alt='Banner'
            onError={addDefaultSrc}
        />
        <div className='container'>
          <div className='inner-title'>
            <h3>{collection?.name}</h3>
            <h3> NFT DROP <a href={`/user/${nftowner?.wallet_address}`}> {nftowner?.username}</a></h3>
          
            <div className='inner-shape'>
        
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NftDropPageBanner;
