import React from 'react';

const TermsArea = () => {
  return (
    <>
      <div className='about-area pt-70 pb-70'>
        <div className='container'>
          <div className='section-title text-center'>
            
            <h2 className='m-auto'> Terms & Conditions</h2>
          </div>
                <div className='single-content'>
                  <h3>01. Introduction</h3>
                  <p>
                  Epin LLC, doing business as NFTHev ("NFTHev," "we," "us," or "our") owns and runs this website. The NFTHev website(s), our APIs, our mobile application (the "App"), and any other software, tools, features, or functionalities provided on or in connection with our services (collectively, the "Service") are subject to these terms of service ("Terms"). This includes, without limitation, using our services to view, explore, and create NFTs and using our tools to connect directly with others to buy, sell, or transfer NFTs on public blockchains.
In these Terms, "NFT" refers to a non-fungible token or comparable digital object implemented on a blockchain (such as the Ethereum blockchain), which makes use of smart contracts to link to or otherwise be connected to certain or data.
                  </p>
                  <p>
                  "User," "you," and "your" refer to you as a Service user for the purposes of these Terms.
You represent and warrant that (a) you are the authorized representative of the entity with the authority to bind the entity to these Terms and (b) you agree to these Terms on the entity's behalf if you use the Service on behalf of a company or other entity.

Because they affect your legal rights and contain important information, PLEASE READ THESE TERMS OF SERVICE CAREFULLY.
THEY INCLUDE A MANDATORY ARBITRATION AGREEMENT AND A CLASS ACTION WAIVER THAT, WITH LIMITED EXCEPTIONS, REQUIRES ANY DISPUTES BETWEEN US TO BE RESOLVED THROUGH INDIVIDUAL ARBITRATION RATHER THAN A JUDGE OR JURY IN COURT AS COMPARED TO A CLASS ACTION. THIS IS OUTLINED IN SECTION 16 BELOW. 
                  </p>
                  <p>
                  YOU AGREE TO BE BOUND BY THESE TERMS AND ALL TERMS INCORPORATED HEREIN BY REFERENCE BY CLICKING TO ACCEPT AND/OR USING OUR SERVICE. YOU MAY NOT ACCESS OR USE THE SERVICE IF YOU DO NOT AGREE TO THESE TERMS.
                  </p>
                  <p>
                  NFTHev does not act as a creditor, exchange, broker, financial institution, or wallet provider. Via its peer-to-peer web3 service, NFTHev, users can easily find and communicate with NFTs that are accessible on public blockchains. We do not execute or effectuate purchases, transfers, or sales of NFTs, nor do we have custody over or control over the NFTs or blockchains you are engaging with. You must utilize a third-party wallet that enables you to conduct transactions on public blockchains in order to use our Service. 
                  </p>
                  <p>
                  No agreement between any users includes NFTHev as a party. We make no representations regarding the identity, legitimacy, functionality, or authenticity of users or NFTs (and any content associated with such NFTs) visible on the Service, and you are solely responsible for confirming the identity, legitimacy, and authenticity of NFTs that you purchase from third-party sellers using the Service.
                  </p>
                  <p>
                  We occasionally need to provide extra conditions for particular services due to our expanding list of offerings (and such services are regarded to be part of the "Service" hereunder and shall likewise be subject to these Terms). If you use the appropriate service, the additional terms and conditions that are accessible with it will then be a part of your contract with us. Any extra applicable terms we may provide for a particular service that conflict with these Terms shall govern that particular service in the event of a dispute.
                  </p>
                  <p>
                  These Terms are subject to modification at any moment and in NFTHev's sole discretion. If we make significant changes to these Terms, we'll make a good faith attempt to notify you of those changes. For example, we might do so by posting a notice on the Service or amending the "Last Updated" date at the top of these Terms. By continuing to access or use the Service after these Terms have been modified, you agree to be bound by the new Terms and all terms that have been incorporated therein by reference. It is your sole obligation to periodically examine the Terms to see if there have been any changes and to make sure you are aware of the rules that will be followed when you access or use the Service.
                  </p>

                </div>

                <div className='single-content'>
                  <h3>02. Using The Service</h3>
                  <p>
                  Your blockchain address serves as your NFTHev identification, similar to much of web3.
As a result, in order to utilize the Service, you will need a blockchain address and a third-party wallet.
Your blockchain address will be linked to your account on the service (your "Account"), but you can customize your NFTHev identity by adding details to your Account, including a profile photo.

                  </p>
                  <p>
                  Your linked blockchain address will be connected to your NFTHev account and show the NFTs for that address (and, if applicable, any content associated with such NFTs).
You acknowledge that you are using your wallet in accordance with the terms and conditions of the relevant wallet provider while using it in conjunction with the Service. Wallets are not managed by, operated by, or associated with NFTHev, and NFTHev has no custody or control over, or authority to get access to, the contents of your wallet.
With relation to your use of a wallet, NFTHev disclaims all duty and responsibility to you, and it also makes no guarantees or statements about the compatibility of the Service with any particular wallet.
You should never divulge your wallet's credentials or seed phrase to anyone because you are solely responsible for keeping it secure.
Please get in touch with your wallet's provider if you see a problem. Similarly, you alone are in charge of your Account and any connected wallet. We are not responsible for any actions or inactions you take related to your Account or as a result of the compromise of your Account or wallet.
You consent to alerting us right away if you learn of or have any other suspicions regarding any security problems with the Service or your Account (you can contact us here).
 
                  </p>
                  <p>
                  When using the Service, you also represent and warrant that you will abide by all applicable laws (such as local, state, federal, and other laws).
Without limiting the aforementioned, by making use of the Service, you represent and warrant that: (a) you are not situated in, normally resident in, or formed under the laws of any jurisdiction that is the subject to a broad U.S. Government embargo ("Embargoed Jurisdiction"); (b) You are not owned or controlled, directly or indirectly, by any person who is subject to Sanctions, who is located in, ordinarily resides in, or is organized under the laws of any Embargoed Jurisdiction; (c) You are not subject to any sanctions administered by an agency of the U.S. Government, any other government, or the United Nations (collectively, "Sanctions"); (d) None of your officers, managers, directors, shareholders, or authorized representatives is
You further promise that the aforementioned will be accurate for the duration of this contract.
If you access or use the Service outside of the United States, it is your sole responsibility to ensure that your access and use of the Service comply with all applicable local, national, and international laws.
                  </p>
                  <p>
                  In certain situations, such as at the request of any governmental body, as required by any applicable law or regulation, or to investigate a possible breach of these Conditions, NFTHev may ask you for additional information and documentation.
In such circumstances, NFTHev may, in its sole discretion, disable your Account and prevent you from using the Service until such time as NFTHev has processed the relevant supplementary information and documentation. In the event that you do not respond to such a request with complete and correct information, NFTHev may decline to reinstate your access to the Service.
                  </p>
                  <p>
                  Your use of the Service may occasionally be stopped for a variety of reasons, including but not limited to equipment failure, routine upgrading, maintenance, or repair work, or other steps that NFTHev, in its sole discretion, may decide to take.
You acknowledge that NFTHev may disable your Account or change your username or related url if it is dormant for six months or more, as assessed by NFTHev in its sole discretion.
                  </p>
                  <p>
                  All used are required to be 18 years of age. You may only use NFTHev through a parent or guardian's Account and with their agreement and supervision if you are at least 13 years old but under the age of 18.
You are accountable to that account holder for your use of the Account.
If you are younger than 13 years old, you are not permitted to use our Service.
                  </p>


                </div>

                <div className='single-content'>
                  <h3>03. Ownership</h3>
                  <p>
                    The NFTHev logo as well as all other designs, text, graphics, pictures, data, software, sound files, other files, and their arrangement and selection are the sole property of NFTHev or our affiliates or licensors. The Service also includes its "look and feel" (such as text, graphics, images, logos, page headers, button icons, and scripts), proprietary content, information, other materials, and you promise not to do anything that conflicts with their ownership interests.
All rights in relation to the Service and its content are reserved by us and our affiliates, licensors, and users, as appropriate. This includes, without limitation, the sole right to produce derivative works.

                  </p>
                  <p>
                  The names, designs, logos, and slogans of any NFTHev products or services, as well as the NFTHev brand, logo, trademarks, and our affiliates' or licensors' intellectual property, may not be duplicated, imitated, or used in any way without our prior written consent in each case.
Without our prior written consent, you may not use any "hidden text" or metatags that contain "NFTHev" or any other name, trademark, product, or service name of NFTHev, our affiliates, or licensors. Also, NFTHev's "look and feel" of the Service is its service mark, trademark, or trade dress, and it cannot be mimicked, copied, or used in any way without our prior written consent.
                  </p>
                  <p>
                  The Service may contain references to other third-party trademarks, registered trademarks, and product names. These third-party trademarks, registered trademarks, and product names are the property of the respective owners, and they may not be copied, imitated, or used, in whole or in part, without the owner's express permission.
Any mention of goods, services, procedures, or other data, whether by title, trademark, manufacturer, supplier, or otherwise, does not indicate or represent support from NFThev. 
                  </p>
                  <p>
                  We appreciate any comments, suggestions, and feedback regarding how to make the Service better ("Feedback").
You expressly agree and accept that you do not and will not get any rights, titles, or interests in the Service or in any such Feedback as a result of your donation of any feedback.
You acknowledge and agree that NFTHev may use and distribute your feedback in any way, for any purpose, without additional permission from you, without payment of any kind, and without retaining any proprietary or other rights or claims.
You hereby transfer to NFTHev all right, title, and interest in and to any and all property, including but not limited to any and all patents, copyrights, trade secrets, trademarks, know-how, moral rights, and other intellectual property rights that you may have. 
                  </p>

                </div>

                <div className='single-content'>
                  <h3>04. Permission to Use Our Service and Content</h3>
                  <p>
                  A limited, nonexclusive, nontransferable, nonsublicensable, and personal license is now given to you for accessing and using the Service, provided that you comply with these Terms.
If any software, content, or other materials owned by, controlled by, or licensed to us are provided to you as part of your use of the Service, we hereby grant you a non-commercial, personal, non-assignable, non-sublicensable, non-transferable right and license to access and display those software, content, and materials (and the right to download one copy of the App onto your applicable equipment or device), in each case for the sole purpose of enabling you to use the Service as permitted by these Terms, provided that your license in any content linked to or associated with any NFTs is solely as set forth by the applicable seller or creator of such NFT.
                  </p>
                </div>

                <div className='single-content'>
                  <h3>05. Third-Party Services and Content</h3>
                  <p>
                  NFTHev is a peer-to-peer web3 service that enables you to engage with various blockchains and discover NFTs made by other parties.
You are responsible for confirming the legitimacy, authenticity, and legality of NFTs that you buy from third-party sellers. NFTHev makes no representations or warranties about the third-party content that is accessible through our Service, including any content linked to NFTs displayed on the Service.
Additionally, we cannot promise that any NFTs displayed on NFTHev will always be displayed and/or available for purchase, sale, or transfer.
                  </p>
                  <p>
                  NFTs may be subject to agreements made directly between buyers and sellers over how to use the content of the NFT and any advantages that come with it  (“Purchase Terms”). For instance, you might see a third-party link to the creator's website when you click to view more information about any of the NFTs displayed on NFTHev.
You may be forced to abide by Buying Conditions on such a website that govern how the NFT may be used.
Any Buying Terms are strictly between the buyer and the seller; NFTHev is not a party to any such Purchase Terms.
Purchase Conditions shall be communicated, adopted, agreed upon, and enforced solely by the Buyer and the Seller.
It is entirely your responsibility to go through any Buying Conditions.
                  </p>
                  <p>
                  The Service may also include links or other features that allow access to or use of third-party websites and applications, or that otherwise display, include, or make available content, data, information, services, applications, or materials materials from third parties (“Third-Party Materials”). Whilst we might not alert you that you have left our Service, when you click on a link to, access, or use a Third-Party Website or Third-Party Application, you are subject to the terms and conditions (including privacy policies) of a different website or destination.
These third-party websites, applications, and materials are not under NFTHev's control and may be "open" applications for which there is no legal remedy.
Any Third-Party Websites, Third-Party Apps, and Third-Party Content are not the property of, or under the control of, NFTHev.
As a courtesy, NFTHev offers links to these Third-Party Websites and Third-Party Apps, but does not examine, authorize, monitor, endorse, warrant, or otherwise make any claims about these Third-Party Websites and Third-Party Applications, or their goods or services or or associated Third-Party Materials. You use all links in Third-Party Websites, Third-Party Applications, and Third-Party Materials at your own risk.  
                  </p>
                </div>

                <div className='single-content'>
                  <h3>06. User Behavior</h3>
                  <p>
                  One of our most cherished values is openness, and we're dedicated to giving web3 users from all backgrounds and skill levels a unique perspective on various blockchains.
However, we reserve the right to take action, with or without prior notification, if we believe you have broken these Conditions in order to protect our community and adhere to our legal duties.
The following actions could be taken in response: removing the ability to view specific NFTs on the Service or use our Service to interact with the NFTs; preventing the use of the Service in conjunction with the purchase, sale, or transfer of NFTs that are available on blockchains; preventing your access to our Service; and/or other measures.
                  </p>

                  <p>
                    You promise not to break any agreements, laws, rules governing intellectual property, or other rights of third parties rights, and that you are solely responsible for your conduct and content, while accessing or using the Service. You also agree that you will not:
                    <ul>
                      <li>
                      Use or make an effort to use another user's account without that user's permission;
                      </li>
                      <li>
                      Use a wallet or a person's identity to conduct a transaction on NFTHev that is entirely or partially owned or controlled by another individual;
                      </li>
                      <li>
                      Claim an NFTHev username with the intent to sell it, cause confusion, profit from the goodwill of others, or participate in other name-squatting activities;
                      </li>
                      <li>
                      If we've prohibited any of your other blockchain addresses from accessing the Service, access the Service via a different blockchain address unless you have our prior written consent;
                      </li>
                      <li>
                      Send undesired NFTs to other users in order to spread spam;
                      </li>
                      <li>
                      Use the Service in a way that could harm, disable, overtax, or degrade its performance, including by sharing any software or communicating with any API. 
                      </li>
                      <li>
                      Employ any automated tool or interface, such as a data mining robot, crawler, spider, or offline reader, that is not approved by us to access the Service, extract data, or otherwise tamper with or change how the Service renders its pages or functionality;
                      </li>
                      <li>Reverse engineering, duplicating, decompiling, disassembling, or decoding any part of the service, or doing anything else that could lead to the source code being revealed, as well as getting around or around security measures put in place to block or restrict access to any service, area, or code of the service;</li>
                      <li>Sell the Service, resell it, or try to get around any NFTHev fee mechanisms;</li>
                      <li>Participate in actions that artificially boost view counts or cause an item or collection to appear at the top of search results, either with the goal or as a result of those actions. </li>
                      <li>Use the service or information obtained from it for any advertising or direct marketing endeavors (including, but not limited to, telemarketing, SMS, and email marketing);</li>
                      <li>Use the service in any way that is related to breaking any law or regulation that applies to you or NFTHev, including for purposes of money laundering, terrorism funding, or other illegal financial activities;</li>
                      <li>Use the Service directly or indirectly for, on behalf of, or in the interest of: (a) any natural person or entity that is subject to sanctions; (b) any natural person or entity that is physically present in, ordinarily resides in, or is otherwise constituted under the laws of, any Embargoed Jurisdiction; or (c) any legal entity that is directly or indirectly owned or controlled by any natural or legal person located in, ordinarily resident in, or organized under the laws of, any Embargoed Jurisdiction. </li>
                      <li>Utilize the service to conduct any financial transactions requiring registration or authorization, such as the creation, offering, sale, or purchase of securities, commodities, options, or debt instruments;</li>
                      <li>Utilize the service to produce, sell, or purchase NFTs, as well as other products that can be redeemed for securities, commodities, or other financial instruments or that grant owners the opportunity to participate in ICOs or other securities offerings;</li>
                      <li>Utilize the service for fraudulent or other deceptive, misleading, or manipulative activities, such as price gouging;</li>
                      <li>Use the service to purchase, sell, or transfer stolen goods, goods gained fraudulently, goods seized without consent, or any other illegally acquired goods;</li>
                      <li>Breach or infringe against another's intellectual property rights or any other rights; </li>
                      <li>Publish or exhibit unlawful content, such as material that might entail child sex exploitation; </li>
                      <li>Produce or show NFTs or other materials that incite hatred or violence against others or that dox someone;</li>
                      <li>Use the service for any illicit or unauthorized activities, such as the production or dissemination of content that could entail the exploitation of children, or the encouragement or promotion of any behavior that is prohibited by the Terms of Service;</li>
                      <li>Utilize the service in a way that might prevent other users from fully appreciating it, disrupt it, or have a negative impact on it.</li>
                    </ul>
                  </p>
                  <p>Users are permitted to upload not suitable for work NSFW content, however that content must be labelled as NSFW and may be treated differently from non-NSFW content in navigation menus and search results. </p>
                  <p>Last but not least, by utilizing the Service, you are aware of the value of DYOR, or completing your own study.
You are solely responsible for investigating any NFT, collection, or account that you view or otherwise interact with in connection with our Service to ensure its veracity, legitimacy, identity, and other pertinent information.
Regarding the identity, legality, or authenticity of any NFT, collection, or account on the Service, we make no guarantees or commitments.
</p>


                </div>

                <div className='single-content'>
                  <h3>07. Rights to intellectual property</h3>
                  <p>
                  You are entirely responsible for how you use the Service and for any data you submit, including for adhering to all applicable laws, rules, and ordinances as well as these Terms and the aforementioned guidelines for acceptable user conduct.
                  </p>
                  <p>
                  By making use of the Service in connection with publishing, marketing, or displaying material, or by adhering to NFTHev's metadata standards in your metadata API responses, you grant us a worldwide, non-exclusive, transferable, sublicensable, royalty-free license to use, copy, modify, and display any content, including without limitation text, materials, images, files, communications, comments, feedback, suggestions, ideas, concepts, questions, data, or other, that you submit or post on or through the Service for our present and future business purposes, including to provide, promote, and improve the Service.
This includes any digital file, work of art, or other content connected to or related to any NFTs shown on the Service.
                  </p>
                  <p>
                  NFTHev makes no claims to ownership of the content by virtue of submitting, posting, or showing it on or through the Service.
We don't claim to own it. We're simply saying we might use it and show it off a bit.
                  </p>
                  <p>For any content that you create, publish, post, promote, or display on or through the Service, you represent and warrant that you own, or have obtained, all rights, licenses, consents, permissions, power, and/or authority necessary to grant the rights granted herein.
If you don't have the required authorization or are otherwise legally able to post the content and grant NFTHev the license outlined above, you represent and warrant that it does not contain any material that is protected by copyright, trademark, publicity, or other intellectual property rights. You also guarantee that the content complies with all applicable laws.</p>
                  <p>
                  In response to Digital Millennium Copyright Act ("DMCA") takedown notifications and/or other allegations of intellectual property infringement, NFTHev will remove works and terminate a user's use of the Service if it is found that the user repeatedly infringes.
Please send a written notification to our designated copyright agent at the following email address if you believe that your work has been duplicated in a way that constitutes copyright or trademark infringement, infringes your right to privacy or other intellectual property rights.
<mailto>copyright@nfthev.io</mailto>
                  </p>
                  <p>
                  You must be the rightsholder or have permission from the rightsholder before we can process your infringement claim regarding material on the Service.
We encourage you to fill out our form to assist make sure your notification contains the necessary details.
If you decide to write to us by physical mail or electronic mail, your notice must contain the following information: 
                  <ul>
                    <li>A list of the allegedly infringed-upon copyrighted work(s), trademarks, publicity rights, or other intellectual property rights;</li>
                    <li>A description of the precise location (i.e., urls) on the Service of the purportedly infringing content that is requested to be deleted, so that we may find the content; identification of the allegedly infringing content that is requested to be removed;</li>
                    <li>Your contact information, including at the very least your email address and full legal name (not a nickname);</li>
                    <li>A declaration that fully satisfies all of the criteria:</li>
                    <ul>
                      <li>A declaration that you genuinely believe the use of the content in the manner complained of is prohibited by the law, the owner of the intellectual property rights, or the owner's representative;</li>
                      <li>A certification that the details in the notice are true; and </li>
                      <li>A declaration made under penalty of perjury that you are authorized to speak or act on behalf of the owner of the allegedly infringing intellectual property.</li>
                    </ul>
                    <li>Your signature, whether written or digital (of your full legal name).</li>
                  </ul>
                  </p>
                  <p>Please be aware that we will send the party whose content will be withdrawn your notice of intellectual property violation, along with your contact information, so they will understand why it will no longer be available on NFTHev and can also contact you to address any dispute.</p>
                </div>

                <div className='single-content'>
                  <h3>08. Preferences in Communication</h3>
                  <p>
                  You agree to receive electronic communications from NFTHev by creating an Account (e.g., via email, push notification, text messages, or other types of messages).
These messages are part of your connection with us and may contain notices regarding your Account (such as transactional information). We might also email you with promotional materials we believe you'll find useful.
You understand that using the Service does not require you to give this consent, and you may choose not to receive these communications by following the unsubscribe instructions provided on the Service or in the operating system of your mobile device (with the possible exception of crucial service announcements and administrative messages).
                </p>
      
                </div>

                <div className='single-content'>
                  <h3>09. Terms of App Usage</h3>
                  <p>
                  In order to download, install, and use the App, you must provide your own mobile device, wireless service plan, software, Internet connections, and/or other necessary hardware or services.
We make no promises regarding the compatibility of the App with a specific device or service plan.
We do not guarantee that the App or Service will be available in any particular geographic location.
                  </p>
                  <p>
                  Only if you download the program from the Apple App Store do the accompanying terms and conditions apply to you:
The terms and conditions in this paragraph take precedence over any other terms and conditions in these Terms that are more restrictive or conflict in some other way, but only with regard to your use of the App you downloaded from the Apple App Store.
You understand and accept that Apple has no control over the App's content and that these Terms are strictly between you and NFTHev.
The applicable terms of use from the App Store must be followed when using the App. You agree that Apple is under no obligation to provide any maintenance or support services for the App.
You can tell Apple in the event that the App does not meet any applicable warranty requirements, and Apple will refund your purchase money, if any, for the App.
To the fullest extent permitted by applicable law, Apple shall have no other warranty obligation with respect to the App, and these Terms alone will govern any claims, losses, obligations, damages, costs, or expenditures that may arise from the failure of any warranty to be met.
You agree that Apple is not liable for addressing any claims you or a third party may have with respect to the App, your ownership of the App, and/or your use of the App, including, but not limited to: (a) product liability claims; (b) any allegation that the App does not meet any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or other similar laws.
You understand that NFTHev, not Apple, will be entirely responsible for the investigation, defense, settlement, and discharge of any third-party claim that the App or your possession and use of the App violates that third party's intellectual property rights to the degree required by these Terms. When using the App, you must abide by any applicable third-party terms of agreement.
As it relates to your use of the App, you understand and agree that Apple and Apple's subsidiaries are third-party beneficiaries of these Terms. As a result of your acceptance of these Terms, Apple will have the right (and be deemed to have accepted the right) to enforce these Terms against you.   
                  </p>
                </div>

                <div className='single-content'>
                  <h3>10. Indemnification</h3>
                  <p>
                  You agree to indemnify, defend, and hold harmless NFTHev and our respective past, present, and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively, the "NFTHev") to the fullest extent permitted by applicable law by accepting these Terms and using the Service. This includes but not limited to all actual or alleged claims, damages, awards, judgments, losses, liabilities, obligations, taxes, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses), and costs (including, without limitation, court costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract, or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to 
                  (a) the manner in which you use or misuse the Service, content, NFTs, or content connected to or associated with any NFTs
(b) any Feedback you submit; (c) your violation or breach of any provision of these Terms or applicable law; (d) your violation of another user's or a third party's rights or obligations; and (e) your carelessness or willful misconduct.
You consent to give NFTHev immediate notice of such Claims and to assist the NFTHev Parties in rebutting any Claims.
You also concur that the NFTHev Parties will be in charge of any Claims' defense or resolution.
This indemnity is in addition to any other indemnities specified in a written agreement between you and NFTHev, not in place of them. 
                  </p>
                </div>

                <div className='single-content'>
                  <h3>11. Disclaimers</h3>
                  <p>
                  YOU ACCESS THE SERVICE AT YOUR OWN RISK AND USE IT AT YOUR OWN RISK.
                  YOU UNDERSTAND AND AGREE THAT NFTHEV EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND THAT THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE."
                  NFTHEV (AND ITS SUPPLIERS) DISCLAIM ALL LIABILITY AND MAKE NO WARRANTY OR REPRESENTATION THAT THE SERVICE WILL (A) MEET YOUR REQUIREMENTS, (B) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR (C) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.
                  NFTHEV DISCLAIMS ALL OTHER EXPRESS AND IMPLIED WARRANTIES AND CONDITIONS, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. ARISING OUT OF ANY ACTION TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION CONTAINED ON THE SERVICE, NFTHEV SHALL NOT BE RESPONSIBLE FOR ANY LOSS OF ANY KIND.
                  While HFTHev makes every effort to ensure your access to and use of the service is secure, NFTHEV CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICE, CONTENT, CONTENT LINKED TO OR ASSOCIATED WITH ANY NFTS, OR ANY NFTS YOU INTERACT WITH USING OUR SERVICE OR THE SERVERS OF OUR SERVICE PROVIDERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                  WE CANNOT ENSURE THAT ANY DATA YOU DISCLOSE ONLINE IS SAFE.
                  NO INFORMATION OR ADVICE RECEIVED FROM THE NFTHEV PARTIES OR THROUGH THE SERVICE, WHETHER ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN. You acknowledge the inherent security risks associated with providing information and transacting online over the internet, and you agree not to hold NFTHev liable for any security breach.
                  </p>
                  <p>
                  NFTS, content, and/or content linked to or associated with NFTS are not subject to our responsibility or liability in any way, including, but not limited to, any losses, damages, or claims resulting from: (A) user error, incorrectly constructed transactions, or misspelled addresses; (B) server failure or data loss; (C) unauthorized access or use; (D) any unauthorized third-party activity.  
                  </p>
                  <p>
                  Only the ownership record maintained in the associated block chain allows NFTS to exist (E.G., ETHEREUM NETWORK).
ALL SALES OR TRANSFERS TAKE PLACE ON THE RELATED BLOCKCHAIN (E.G., ETHEREUM).
THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS OR UNDERLYING OR ASSOCIATED CONTENT OR ITEMS MAY NOT BE AFFECTED BY NFTHEV AND/OR ANY OTHER NFTHEV PARTY OR CONTROLLED IN ANY OTHER MANNER.
                  </p>
                  <p>
                  ANOTHER NFTHEV PARTY IS NOT RESPONSIBLE OR LIABLE FOR ANY SUSTAINED LOSSES OR DAMAGE CAUSED BY VULNERABILITY, ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE (INCLUDING WALLET, SMART CONTRACT), BLOCKCHAINS, OR ANY OTHER NFTS FEATURES.
                  NO NFTHEV PARTY SHALL BE HELD LIABLE FOR ANY LOSS OR INJURY RESULTING FROM LATE REPORTS BY DEVELOPERS OR REPRESENTATIVES (OR FROM NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE NFTS, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR ANY OTHER ISSUES RESULTING IN LOSS OR INJURY.
                  </p>
                  <p>
                  The foregoing exclusion may not apply to you since some jurisdictions do not permit the exclusion of implied warranties in contracts with consumers.
                  </p>
                </div>
                <div className='single-content'>
                  <h3>12. Acceptance of Risk</h3>
                  <p>
                  You consent to and recognize:
                  <ul>
                    <li>
                    An NFT's worth is arbitrary. NFT prices are prone to volatility, and changes in the price of cryptocurrencies can also have a considerably negative impact on NFT prices. You admit that you are fully aware of this subjectivity, volatility, and possibility of financial loss.
                    </li>
                    <li>
                    Regardless of whether a successful transaction occurs, you acknowledge that you are responsible for any fees associated with using the Service, including for activities you perform on the blockchain, and that these fees are final and irrevocable.
                    </li>
                    <li>Lack of public use or interest in the establishment and growth of distributed ecosystems could have a detrimental effect on those ecosystems' development and the corresponding applications they support, which could thus have a detrimental effect on the potential utility of NFTs.</li>
                    <li>The legislative environment governing non-fungible tokens, cryptocurrencies, and other crypto-based products is hazy, and new laws or rules could have a fundamentally negative impact on how the Service develops and how useful NFTs are.</li>
                    <li>You are solely responsible for deciding whether taxes, if any, are applicable to your transactions and for making sure that the right sums of taxes are withheld, collected, reported, and sent to the proper tax authorities. Any taxes that are applicable to your NFTs or the sale or purchase of your NFTs must be determined, withheld, collected, reported, and sent by you; NFTHev is not in charge of doing this.</li>
                    <li>Through peer-to-peer transactions, there are risks involved with buying items connected to third-party content, such as the possibility of buying items that are fake, mislabeled, susceptible to metadata deterioration, based on flawed smart contracts, or items that might lose their transferability. Before making any decisions regarding the sale, purchase, transfer, or other interaction, you represent and warrant that you have done adequate research.</li>
                    <li>We do not manage or own the smart contracts that third parties have implemented, thus we cannot be held accountable for how they operate or function. </li>
                    <li>We have no authority over the public blockchains you are using, and we have no control over certain smart contracts and protocols that might be necessary for you to be able to conduct transactions on these public blockchains. Additionally, since blockchain transactions are final, NFTHev is unable to undo any blockchain transactions.</li>
                    <li>Utilizing blockchain- and Internet-based products comes with risks, such as those related to hardware, software, and Internet connections, the introduction of malicious software, and the possibility that third parties could gain unauthorized access to your third-party accounts or wallets. You agree and accept that NFTHev, regardless of the reason, will not be held accountable for any communication breakdowns, interruptions, mistakes, distortions, or delays you may encounter when using the Service or any Blockchain network.</li>
                    <li>The Service is dependent on vendors and/or third-party platforms. Access to and use of the Service will suffer if we are unable to maintain a positive working relationship with such platform providers and/or vendors, if the terms and conditions or pricing of such platform providers and/or vendors change, if we violate or are unable to comply with such platforms' and/or vendors' terms and conditions, or if any such platforms or vendors lose market share, fall out of favor, or are inaccessible for an extended period of time.</li>
                    <li>In the event that any of these concerns or other issues arise, NFTHev reserves the right to hide collections, contracts, and other assets. On NFTHev, purchased items could stop working. Any claim against NFTHev shall not be based on any inability to view items on NFTHev or an inability to use the Service in connection with the purchase, sale, or transfer of items available on any blockchains.</li>
                    <li>You release us from claims, demands, and damages of every kind and nature, known and unknown, resulting from or connected in any way with disputes you may have with one or more users. By agreeing to this release, you expressly renounce any legal or other protections that may otherwise limit its scope to include just claims that you may know about or suspect to be in your favor at the time this release is agreed to.</li>
                  </ul>
                  </p>
                 
                </div>

                <div className='single-content'>
                  <h3>13. Restrictions on Liability</h3>
                  <p>
                  YOU AGREE THAT NFTHEV AND ITS SERVICE PROVIDERS SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY 
                  (A) FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES RESULTING FROM THESE TERMS OR THE SERVICE, PRODUCTS, OR THIRD-PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVING 
                  (B) FOR ANY OTHER CLAIM, DEMAND, OR DAMAGES OF ANY KIND WHATSOEVER RELATING TO OR RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF DELIVERY, USE, OR PERFORMANCE OF THE SERVICE.
Accessing and using the service, products, or third-party websites and products is done so at your own risk, and you alone are responsible for any resulting computer system or mobile device damage or data loss. 
                  </p>
                  <p>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF NFTHEV ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS TO AND USE OF THE SERVICE, CONTENT, NFTS, OR ANY NFTHEV PRODUCTS OR SERVICES EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT RECEIVED BY NFTHEV FOR ITS SERVICE TO YOU DIRECTLY RELATING TO THE ITEMS THAT ARE THE SUBJECT OF THE CLAIM.
The limitations listed above will still be in effect even if the aforementioned remedy fails to achieve its intended result.
                  </p>
                  <p>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you. This limitation may not apply to personal injury claims since some countries additionally restrict disclaimers or limitations of liability for personal damage caused by consumer products. This limitation may not apply to personal injury claims since some countries additionally restrict disclaimers or limitations of liability for personal damage caused by consumer products.</p>

                </div>

                <div className='single-content'>
                  <h3>14. Privacy Policy</h3>
                  <p>
                  For details on how we gather, utilize, and share information from and/or about you, please see our Privacy Policy ("Your Information").
                  You fully consent to the collection, use, and sharing of Your Information in accordance with the Privacy Policy by providing Your Information through our Service, and you agree to the terms of our Privacy Policy.
                  </p>
                  
                </div>

                <div className='single-content'>
                  <h3>15. Adjustments to the Service</h3>
                  <p>
                  We reserve the right, in our sole discretion, to change, suspend, or end the Service (or any features or portions thereof), permanently or temporarily, at any time and without incurring any obligation. 
                  </p>
                 
                </div>

                <div className='single-content'>
                  <h3>16. Settlement of Disputes; Arbitration</h3>
                  <p>
                  <em>Settlement of disputes.</em>
                    Please pay close attention to the accompanying arbitration agreement ("Arbitration Agreement") in this section.
                    It restricts how you can get help from us and mandates that you resolve problems with NFTHev.
                    Conflicts between users or between users and third parties are not governed by this section.
                    For such conflicts, NFTHev does not offer dispute resolution services; instead, the parties are required to settle them directly.
                  </p>
                  <p>
                  <em>The Arbitration Agreement's Applicability.</em>
You acknowledge and agree that any dispute, controversy, or claim relating in any way to your use or access of the Service, to any goods sold or distributed through the Service, or to any part of your relationship with NFTHev, will be settled by binding arbitration rather than in court, with the following exceptions:  
                 (1) You or NFTHev may bring claims in small claims court, but only if the claims meet the requirements, the claims remain only in such court, and the claims remain on an individual, non-representative, and non-class basis; and (2) You or NFTHev may bring claims for injunctive or equitable relief in a court with appropriate jurisdiction if the claim relates to intellectual property infringement or other legal issues.
                  </p>
                  <p><em>Process for resolving disputes.</em>
Prior to starting an arbitration, small claims court case, or seeking equitable relief for intellectual property infringement, you and NFTHev both concur to make good-faith attempts to settle differences. You must begin this dispute resolution procedure by writing to NFTHev, Attn: Legal Department, @ legaldisputes@nfthev.io to describe the specifics of your claim and the intended outcome.
In order to discuss the dispute and attempt in good faith to reach a resolution that benefits both parties without incurring additional costs, the parties agree to meet and confer in person, over the phone, or via videoconference (hereinafter the "Conference"). This will help avoid arbitration or, where appropriate, litigation costs.
You agree to actively engage in the Conference even if you are being represented by legal counsel, who may attend as well.
The same applies if NFTHev is represented by counsel; nonetheless, NFTHev agrees to have a corporate representative actively engage in the Conference. While the parties participate in the Conference and informal dispute resolution procedure required by this paragraph, the statute of limitations and any filing fee deadlines shall be tolled. In the event that the parties are unable to settle their differences within thirty (30) days of the start of the dispute resolution procedure, either party may begin arbitration proceedings, bring a lawsuit in small claims court, or, if the circumstances warrant, file a claim for injunctive or equitable relief in a court with appropriate jurisdiction for cases involving intellectual property infringement. </p>
                  <p><em>Arbitration Forum and Rules.</em> This Arbitration Agreement shall be interpreted and enforced in accordance with the Federal Arbitration Act. You must write a letter requesting arbitration and outlining your claim to our registered agent at NFTHev via legaldisputes@nfthev.io, Attn: Legal Department, in order to start an arbitration case after engaging in the dispute resolution process. JAMS, a recognized provider of alternative dispute resolution, will manage the arbitration. Streamlined Arbitration Rules and JAMS Consumer Minimum Standards in effect at the time will apply to disputes involving claims and counterclaims under $250,000, not including attorneys' fees, interest, claimed punitive or other damages, or any claim greater than the genuine verifiable value of actual lost property.
                  The JAMS Consumer Minimum Standards and the most recent revision of JAMS's Comprehensive Arbitration Rules and Procedures shall apply to all other claims. You can see JAMS's guidelines by visiting jamsadr.com or by contacting JAMS at 800-352- 5267. The parties will choose a different arbitration venue if JAMS is not available to arbitrate. If you followed the above-described procedures for dispute resolution and the arbitrator determines that you cannot afford to pay JAMS filing, administrative, hearing, and/or other fees and cannot get a waiver from JAMS, NFTHev will pay the expenses on your behalf. Additionally, for claims totalling less than $10,000, NFTHev shall pay any applicable JAMS filing, administrative, hearing, and/or other expenses, unless (a) the arbitrator finds the claims are frivolous or you did not follow the dispute resolution process outlined above, except that if you initiated the arbitration claim, you will still be required to pay the lesser of $250 or the maximum amount permitted under the JAMS Rules for arbitration claims initiated by you. (b) the arbitrator finds the claims are frivolous or you did not comply with the dispute resolution process outlined above. You are still responsible for paying any additional expenses you make during the arbitration, such as legal or expert witness fees. On the basis of written submissions, you may elect to have the arbitration conducted over the phone or by video conference.
                  in person at another mutually agreed upon place that is reasonably close to you (if you reside in the United States) or in your hometown area. Any judgment on the arbitrator's decision may be entered in any court with the necessary jurisdiction.. 
                  </p>
                  <p><em>The power of the arbitrator.</em> The arbitrator shall have sole authority to: (a) define the scope and enforceability of this Arbitration Agreement; and (b) settle any controversy relating to the interpretation, applicability, enforceability, or formation of this Arbitration Agreement, including, but not limited to, any claim that all or any portion of this Arbitration Agreement is void or voidable. The outcome of the arbitration will determine your and NFTHev's rights and obligations, if any.
                  There shall be no joining or consolidation of the arbitration case with any other cases or parties. The arbitrator shall have the power to grant motions that would result in the dismissal of all or a portion of any claim. The arbitrator shall have the power to award money damages and to provide any non-financial relief or non-monetary remedy that a party may be entitled to under applicable law, the rules of the arbitral venue, and these Terms. A written award and statement of judgment, including the amount of any damages awarded, must be issued by the arbitrator. It must include all pertinent findings and conclusions. Similar to how a judge in a court of law would be able to grant relief on an individual basis, the arbitrator has this power. 
                  The arbitrator's decision is final and enforceable against both you and us. </p>
                </div>
                <p>
                <em>Jury trial waiver.</em> You and NFTHev hereby relinquish any constitutional and statutory rights to file a lawsuit and have a jury or judge decide your case. Instead, except as provided in the second paragraph of this Section 16 above ("Applicability of Arbitration Agreement"), you and NFTHev are choosing that all claims and disagreements shall be settled by arbitration under the terms of this Arbitration Agreement. An arbitrator is bound by these Terms and has the same authority to award damages and other relief on an individual basis as a court would. However, in arbitration there is no judge or jury, and the scope of a court's review of an arbitration ruling is relatively limited.
                </p>
                <p><em>Class Action and Class Arbitration Waiver.</em> ALL CLAIMS AND DISPUTES WITHIN THE TERMS OF THIS ARBITRATION AGREEMENT SHALL BE ARBITRATED INDIVIDUALLY, AND NOT IN A CLASS, REPRESENTATIVE, OR COLLECTIVE ACTION FACILITY (INCLUDING, WITHOUT LIMITATION, PAGA). Only individual relief is available; claims from multiple users, individuals, or entities cannot be arbitrated or combined with those of any other users, individuals, or entities. As a result, in accordance with the arbitration procedures described in this section, an arbitrator may not combine or consolidate the claims of more than one party without the approval in writing of each party to the arbitration. Without limiting the generality of the aforementioned, you and NFTHev concur that no issue shall be resolved through class arbitration absent written approval from all affected parties.
                A claim for relief must be severed from the arbitration and brought in the state or federal courts based in New York County in the State of New York if a ruling is made saying that the limitations of this paragraph cannot be enforced as to a particular claim for relief. Arbitration will be used for all other claims.</p>
                <p>
                <em>Severability</em>. Aside from what is specified in this Section, if any portion(s) of this Arbitration Agreement are determined by the law to be invalid or unenforceable, such portion(s) shall be severed and shall have no further force or effect, while the remainder of the Arbitration Agreement shall remain in full force and effect.
                </p>
                <p>
                <em>Maintaining the Agreement.</em> When your partnership with NFTHev ends, this arbitration agreement will remain in effect.
                </p>
                <p>
                  <em>Modification.</em> Regardless of any clause to the contrary in these Terms, we acknowledge that you may reject any future material changes made by NFTHev to this Arbitration Agreement by sending a letter to NFTHev at the following address: NFTHev, Attn: Legal Department, legaldisputes@nfthev.io.
                </p>
                <div className='single-content'>
                  <h3>17. Rule of Law and Place</h3>
                  <p>
                  Without respect to New York's conflict of law rules or principles, the laws of the State of New York shall govern these Terms, your access to and use of the Service, and their interpretation and enforcement. 
                  or any other area that might result in the application of any other area's laws. The state or federal courts of New York County in the State of New York, and the United States, respectively, sitting in the State of New York, must be used to resolve any disagreement between the parties that is not subject to arbitration as specified in Section 16 or cannot be heard in small claims court.
                  </p>
                </div>

                <div className='single-content'>
                  <h3>18. Termination</h3>
                  <p>
                  All rights given by NFTHev will automatically expire if you violate any of these Terms. In addition, despite anything else stated in these Terms, we reserve the right to suspend, restrict, disable, terminate, or delete your Account and/or your ability to access or use the Service (or any of the aforementioned) at any time and for any reason. You acknowledge and agree that we shall have no liability or obligation to you in such an event, and that you will not be entitled to a refund of any paid amounts. You continue to own your NFTs even if we close your Account or restrict your use of the Service. You can still access your NFTs using other web3 wallets, platforms, public blockchains and/or websites.
                  </p>
                  
                </div>

                <div className='single-content'>
                  <h3>19. Severability</h3>
                  <p>
                  Any term, clause, or provision of these Terms that is determined to be invalid or unenforceable shall be deemed severable from these Terms and shall not affect the validity or enforceability of any portion of such term, clause, or provision or any other term, clause, or provision of these Terms.
                  </p>
                  
                </div>

                <div className='single-content'>
                  <h3>20. Prohibitive Relief</h3>
                  <p>
                  You acknowledge that if these Terms are violated, NFTHev will suffer irreparable harm for which monetary compensation would not be an adequate remedy. As a result, NFTHev shall be entitled to equitable relief in addition to any other available legal or contractual remedies, and without the need for a bond, other security, or evidence of damages.
                  </p>
                
                </div>

                <div className='single-content'>
                  <h3>21. Residents of California</h3>
                  <p>
                  If you live in California, you may file a complaint with the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in accordance with Cal. Civ. Code 1789.3 by sending a letter to that address or calling (800) 952-5210 if you live outside of California.
                  </p>
               
                </div>

                <div className='single-content'>
                  <h3>22. Export Regulations</h3>
                  <p>You agree that you will not, directly or indirectly, export or re-export the Service or any other information or materials provided by NFTHev hereunder to any country for which the United States or any other relevant jurisdiction at the time of export requires an export license or other governmental approval. In particular, but not exclusively, the Service may not be exported or reexported (a) into any countries that the United States has embargoed or that the United States has designated as "terrorist supporting" countries, or (b) to anyone on any U.S. Government list of prohibited or restricted parties, including the list of Specially Designated Nationals maintained by the U.S. Treasury Department or the Denied Person's List or Entity List maintained by the U.S. Department of Commerce. You represent and warrant that you are not situated in any such country or on any such list by using the Service. You are in charge of adhering to all applicable United States export rules and regulations, and you hereby undertake to do so at your own expense.
                  </p>
               
                </div>

                <div className='single-content'>
                  <h3>23. Survival</h3>
                  <p>After and despite any termination of these Terms by NFTHev or you, those provisions that by their nature should survive the termination of these Terms will remain in full force and effect. The termination will not affect any of NFTHev's other legal or equitable rights or remedies.
                  </p>
               
                </div>

                <div className='single-content'>
                  <h3>24. Miscellaneous</h3>
                  <p>The entire agreement between you and NFTHev regarding your access to and use of the Service is set forth in these Terms (and any other applicable terms or policies included by reference in these Terms). You may not assign or transfer these Terms or any rights or licenses granted hereunder without NFTHev's prior written consent, and NFTHev's omission to exercise or enforce any right or provision under these Terms shall not be construed as a waiver of such right or provision.
                  No waiver of a breach or default under this agreement by either party shall be deemed a waiver of any breach or default that occurred earlier or later. The section headers used here are solely for reference and should not be interpreted as having any kind of legal significance.
                  </p>
                  <p>We run the Service from the United States. Anyone choosing to access the Service from a place outside of the United States does so on their own initiative and is in charge of adhering to any local laws that may be in force there. The interpretation and construction of these Terms will not be governed by the United Nations Convention on Contracts for the International Sale of Goods, as agreed by both you and NFTHev.</p>
                  <p>These Terms are only for the benefit of the parties, unless specifically stated differently. No other person or entity is intended to have third-party beneficiary rights.</p>
                </div>
        </div>
      </div>
    </>
  );
};

export default TermsArea;
