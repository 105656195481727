import Resizer from 'react-image-file-resizer';

export const rProfileImagef = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        540,
        540,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"    
      );
  });

export const rBannerImagef = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1440,
        320,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"    
      );
  });

export const getImageSrc = (acc)=>{
  if(acc){
    const checkhttp = acc.image?.split('://')[0]
    const checklocal = acc.imagefile?.split('.')[0]
    const checkext = acc.imagefile?.split('.')[1]
    
    if(checkhttp ==="https"){
      return acc.image
    }else if (checkhttp==="ipfs"){
      return `https://infura-ipfs.io/ipfs/${acc.image.split('//')[1]}`
    }else if (checklocal ==="profilepic"){
      return `https://fileserver.nfthev.io/images/profiles/${acc.wallet_address}/profilepic.${checkext}`
    }else{
      return '/images/logo/NoNFTimage.png'
    }
  }else{
    return '/images/logo/NoNFTimage.png'
  }
  
}

export const getNFTImageSrc = (acc)=>{
  if(acc){
    const checkhttp = acc.imageurl.split('://')[0]
    if(checkhttp ==="https"){
      return acc.imageurl
    }else if (checkhttp==="ipfs"){
      // return `https://infura-ipfs.io/ipfs/${acc.imageurl.split('//')[1]}`
      
      return `https://ipfs.io/ipfs/${acc.imageurl.split('//')[1]}`

    }else{
      return '/images/logo/NoNFTimage.png'
    }
  }else{
    return '/images/logo/NoNFTimage.png'
  }
  
}

export const getCollectionImageSrc = (collection, thumbnail)=>{
  if(thumbnail === true){
    if(collection){
      const checkhttp = collection.thumbnail.split('://')[0]
      const checklocal = collection.thumbnail.split('.')[0]
      
      if(checkhttp ==="https"){
        return collection.thumbnail
      }else if (checkhttp==="ipfs"){
        return `https://infura-ipfs.io/ipfs/${collection.thumbnail.split('//')[1]}`
      }else if (checklocal){
        return `https://fileserver.nfthev.io/images/collections/${collection.url_name}/${collection.thumbnail}`
      }else{
        return '/images/logo/NoNFTimage.png'
      }
    }else{
      return '/images/logo/NoNFTimage.png'
    }
  }else{
    if(collection){
      const checkhttp = collection.banner.split('://')[0]
      const checklocal = collection.banner.split('.')[0]
      
      if(checkhttp ==="https"){
        return collection.banner
      }else if (checkhttp==="ipfs"){
        return `https://infura-ipfs.io/ipfs/${collection.banner.split('//')[1]}`
      }else if (checklocal){
        return `https://fileserver.nfthev.io/images/collections/${collection.url_name}/${collection.banner}`
      }else{
        return '/images/logo/NoNFTimage.png'
      }
    }else{
      return '/images/logo/NoNFTimage.png'
    }
  }
  
}

export const getNFTMediaImageSrc = (acc)=>{
  if(acc){
    const checkhttp = acc.mediaimageurl.split('://')[0]
    if(checkhttp ==="https"){
      return acc.mediaimageurl
    }else if (checkhttp==="ipfs"){
      //  return `https://infura-ipfs.io/ipfs/${acc.mediaimageurl.split('//')[1]}`
      
      return `https://ipfs.io/ipfs/${acc.mediaimageurl.split('//')[1]}`

    }else{
      return '/images/logo/NoNFTimage.png'
    }
  }else{
    return '/images/logo/NoNFTimage.png'
  }
  
}