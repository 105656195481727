import {Contract } from 'ethers';
import { updatedNFTsalestatus, mintNFT, updatedNFTpurchases, updatedNFTsaleprice, updateNFTDropQuantityInfo } from '../../actions/nfts';
import { createTransaction } from '../../actions/transactions';

     
export const MintDropEvent = async(nft, library, content, transaction, updatequantity, setisLoading, handleSnack, dispatch)=>{
  let i = 1;
  try{
        const signer = library.getSigner();   
        const kittycatnftInstance = new Contract(
          nft.contract,
          [{"anonymous":false,"inputs":[{"indexed":true,"name":"minter","type":"address"},{"indexed":false,"name":"price","type":"uint256"},{"indexed":false,"name":"nftID","type":"uint256"},{"indexed":false,"name":"oldnftID","type":"uint256"},{"indexed":false,"name":"uri","type":"string"}],"name":"Minted","type":"event"},
          {
            inputs: [],
            name: 'currentTokenId',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        library
        );
        const lasttokenid = await kittycatnftInstance.currentTokenId();
        let info
        let filterMintByAddress = kittycatnftInstance.filters.Minted(nft.connectData, null, null, null, null);
        kittycatnftInstance.on(filterMintByAddress, (minter, price, nftID, oldnftID, uri)=>{
            info={
              minter: minter,
              price: price, 
              nftID: nftID,
              oldnftID: oldnftID,
              uri: uri
          }
         
          if(info.uri===nft.metadataurl && info.oldnftID._hex=== lasttokenid._hex ){
            let nftidfromevent = Number(info.oldnftID.toString())+1;
         
            if(i===1){
              for(let r=nftidfromevent; r<=Number(info.nftID.toString()); r++){
                const newcontent={...content, 'nfttokenid':r}
                dispatch(mintNFT(newcontent));
               
                const newtransaction= {...transaction, 'nfttokenid': r }
                dispatch(createTransaction(newtransaction));
                dispatch(updateNFTDropQuantityInfo(updatequantity))
              }
              handleSnack('Transaction successful', 'success')

              setisLoading(false)
  
              i++;
              kittycatnftInstance.off(filterMintByAddress)
              return info.nftID.toString()
            }
         }
        
        }) 
} catch(e){
  return false
}
};


export const MintSharedEvent = async(nft, library, content, transaction, setisLoading, handleSnack, clear, dispatch)=>{
    let i = 1;
    try{
          const kittycatnftInstance = new Contract(
            nft.contract,
            [{"anonymous":false,"inputs":[{"indexed":true,"name":"minter","type":"address"},{"indexed":false,"name":"price","type":"uint256"},{"indexed":false,"name":"nftID","type":"uint256"},{"indexed":false,"name":"oldnftID","type":"uint256"},{"indexed":false,"name":"uri","type":"string"}],"name":"Minted","type":"event"},
            {
              inputs: [],
              name: 'currentTokenId',
              outputs: [
                {
                  internalType: 'uint256',
                  name: '',
                  type: 'uint256',
                },
              ],
              stateMutability: 'view',
              type: 'function',
            },
          ],
          library
          );
          const lasttokenid = await kittycatnftInstance.currentTokenId();
          let info
          let filterMintByAddress = kittycatnftInstance.filters.Minted(nft.connectData, null, null, null, null);
          kittycatnftInstance.on(filterMintByAddress, (minter, price, nftID, oldnftID, uri)=>{
              info={
                minter: minter,
                price: price, 
                nftID: nftID,
                oldnftID: oldnftID,
                uri: uri
            }
          if(info.uri===nft.metadataurl && info.oldnftID._hex=== lasttokenid._hex ){ 
        
            let nftidfromevent = Number(info.oldnftID.toString())+1;
            if(i===1){
              for(let r=nftidfromevent; r<=Number(info.nftID.toString()); r++){
                const newcontent={...content, 'nfttokenid':r}
                dispatch(mintNFT(newcontent));
                const newtransaction= {...transaction, 'nfttokenid': r }
                dispatch(createTransaction(newtransaction));
              }
            
              handleSnack('Transaction successful', 'success')
              setisLoading(false)
              clear();
              i++;
              kittycatnftInstance.off(filterMintByAddress)
              return info.nftID.toString()
            }
           }
          
          }) 
  } catch(e){
   
    return false
  }
};


export const MintSharedCollectionEvent = async(nft, library, content, transaction, setisLoading, handleSnack, clear, dispatch, setDowhilecounter, dowhilecounter, totalmintcycle)=>{
 
  let i = 1;
  try{
        const kittycatnftInstance = new Contract(
          nft.contract,
          [{"anonymous":false,"inputs":[{"indexed":true,"name":"minter","type":"address"},{"indexed":false,"name":"price","type":"uint256"},{"indexed":false,"name":"nftID","type":"uint256"},{"indexed":false,"name":"oldnftID","type":"uint256"},{"indexed":false,"name":"uri","type":"string"}],"name":"Minted","type":"event"},
          {
            inputs: [],
            name: 'currentTokenId',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        library
        );
        
        const lasttokenid = await kittycatnftInstance.currentTokenId();
        
        let info
        let filterMintByAddress = kittycatnftInstance.filters.Minted(nft.connectData, null, null, null, null);
        kittycatnftInstance.on(filterMintByAddress, (minter, price, nftID, oldnftID, uri)=>{
            info={
              minter: minter,
              price: price, 
              nftID: nftID,
              oldnftID: oldnftID,
              uri: uri
          }
         
        if(info.uri===nft.metadataurl && info.oldnftID._hex>= lasttokenid._hex ){
       
          let nftidfromevent = Number(info.oldnftID.toString())+1;
          if(i===1){
            
              const newcontent={...content, 'nfttokenid':nftidfromevent}
              dispatch(mintNFT(newcontent));
              const newtransaction= {...transaction, 'nfttokenid': nftidfromevent }
              dispatch(createTransaction(newtransaction));
          
            
            if(totalmintcycle === (dowhilecounter+1)){
           
              handleSnack('Transaction successful', 'success')
              setisLoading(false)
              clear();
            }else{
              let counter = dowhilecounter
              setDowhilecounter(counter + 1)
             
            }
            i++;
            kittycatnftInstance.off(filterMintByAddress)
            return info.nftID.toString()
          }
         }
        
        }) 
} catch(e){
 
  return false
}
};



export const BuySharedEvent = async(nft, library, updatedata, transaction, setisLoading, handleSnack, dispatch)=>{
  let i = 1;
  try{
        const kittycatnftInstance = new Contract(
          nft.contract,
          [{"anonymous":false,"inputs":[{"indexed":true,"name":"previousOwner","type":"address"},{"indexed":true,"name":"newOwner","type":"address"},{"indexed":false,"name":"price","type":"uint256"},{"indexed":false,"name":"nftID","type":"uint256"},{"indexed":false,"name":"uri","type":"string"}],"name":"Purchase","type":"event"},
        ],
        library
        );
        let info
        let filterPurchasedByAddresses = kittycatnftInstance.filters.Purchase(transaction.seller, transaction.buyer, null, null, null);
        
        kittycatnftInstance.on(filterPurchasedByAddresses, (previousOwner, newOwner, price, nftID, uri)=>{
            info={
              previousOwner: previousOwner,
              price: price, 
              nftID: nftID,
              newOwner: newOwner,
              uri: uri
          }
         
        if(Number(info.nftID._hex.toString())===Number(nft.id)){
          if(i===1){
              dispatch(updatedNFTpurchases(updatedata));
              dispatch(createTransaction(transaction));
              handleSnack('Transaction successful', 'success')
              setisLoading(false)
              i++;
              kittycatnftInstance.off(filterPurchasedByAddresses)
              return info.nftID.toString()
          }
         }
        
        }) 
} catch(e){
  
  return false
}
};



export const PriceUpdateStatusEvent = async(priceupdate, library, userprofile, setLoading, handleSnack, setValues, setOpen, dispatch)=>{
  let i = 1;    
  try{
          const kittycatnftInstance = new Contract(
            priceupdate.contract,
            [   
             {
                    "anonymous": false,
                    "inputs": [
                      {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                      },
                      {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "oldPrice",
                        "type": "uint256"
                      },
                      {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "newPrice",
                        "type": "uint256"
                      },
                      {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "nftID",
                        "type": "uint256"
                      }
                    ],
                    "name": "PriceUpdateStatus",
                    "type": "event"
                  }
                ],
            library
          );
          let PriceUpdateByAddress = kittycatnftInstance.filters.PriceUpdateStatus(userprofile, null, null, null);
          kittycatnftInstance.on(PriceUpdateByAddress, async (owner, oldPrice, newPrice, nftID)=>{
            let info={
               owner: owner,
               oldPrice:oldPrice,
               newPrice:newPrice,
               nftID: nftID
           }
          
          if(Number(info.nftID._hex.toString())===Number(priceupdate.tokenid)){
            if(i===1){
              await dispatch(updatedNFTsaleprice(priceupdate))
              handleSnack('Transaction successful', 'success')
              setValues({amount:''})
              setOpen(false)
              setLoading(false)
              i++;
              kittycatnftInstance.off(PriceUpdateByAddress)
              window.setTimeout( function() {
                window.location.reload();
              }, 3000);
             
              return info.nftID.toString()
           }
          
          }
          
         }) 
         
  } catch(e){
    
    return false
   
  }
};



export const MarketWithdrawalEvent = async(statusupdate, library, userprofile, setLoading, handleSnack, dispatch)=>{
  let i = 1;  
  try{
          const kittycatnftInstance = new Contract(
            statusupdate.contract,
            [{
                    "anonymous": false,
                    "inputs": [
                      {
                        "indexed": true,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                      },
                      {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "nftID",
                        "type": "uint256"
                      },
                      {
                        "indexed": false,
                        "internalType": "bool",
                        "name": "isListed",
                        "type": "bool"
                      }
                    ],
                    "name": "NftListStatus",
                    "type": "event"
                  }
                  ],
            library
          );
          
          let filterNFTListStatusByOwner = kittycatnftInstance.filters.NftListStatus(userprofile, null, null);
          kittycatnftInstance.on(filterNFTListStatusByOwner, (owner, nftID, isListed)=>{
            let info={
                owner: owner,
                isListed: isListed,
                nftID: nftID
              } 
             
            if(Number(info.nftID._hex.toString())===Number(statusupdate.tokenid)){
              if(i===1){
                  dispatch(updatedNFTsalestatus(statusupdate));
                  handleSnack('Transaction successful', 'success')
                  setLoading(false)
                  i++;
                  kittycatnftInstance.off(filterNFTListStatusByOwner)
                  window.setTimeout( function() {
                    window.location.reload();
                  }, 3000);
                  return info.nftID.toString()
              }
            }
           
          })
          
  } catch(e){
   
    return false

  }
};

