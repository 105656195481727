import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import CollectionCard from '../../../components/Cards/Collections/CollectionCard';
import NFTCard from '../../../components/Cards/NFTCard/NFTCard';
import Pagination from './Pagination';
import { useHistory, useLocation } from 'react-router-dom';


const Collection = () => {
    const history = useHistory();
    const query = useQuery();
    const page = query.get('page') || 1;
    const sort = query.get('sort') || 1;
    const searchQuery = query.get('searchQuery');
    const pathname = useLocation().pathname.split('/')[2];
    const { nftcontracts } = useSelector((state) => state.nfts);
    const [search, setSearch] = useState('');
    
   
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const sortdata = (e)=>{
        const page = query.get('page') || 1
        const searchQuery = query.get('searchQuery');

        if(searchQuery){
            history.push(`/collection/${pathname}?searchQuery=${searchQuery}&page=${page}&sort=${e}`);
        }else {
            history.push(`/collection/${pathname}?page=${page}&sort=${e}`);
        }
    }

    const searchNFT = (e) => {
        e.preventDefault();
        if (search.trim()) {
            const page = query.get('page') || 1;
            const sort = query.get('sort') || 1;
            history.push(`/collection/${pathname}?searchQuery=${search || 'none'}&page=${page}&sort=${sort}`);
        } else {
            history.push(`/collection/${pathname}?page=${page}&sort=${sort}`);
        }
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
        searchNFT(e);
        }
    };

    const getFloor = (nftarray) => {
        let newarray = [];
        if(nftarray){
            nftarray?.map((nft) => (
                newarray.push(nft.mintprice)               
             ))
        }
    
        return Math.min(...newarray)
    };
    
    
 
    return (
        
    <>
       
        <div className='collection-widget-area pb-70'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <CollectionCard  floor={getFloor(nftcontracts)}/>
                        <div className='side-bar-area pl-20'>
                            <div className='side-bar-widget'>
                            <h3 className='title'>
                                
                                Filter<a href={`/collection/${pathname}?page=1&sort=1`}>Clear All</a>
                            </h3>
                            <form className='search-form' onSubmit={searchNFT}>
                           
                                <input
                                type='search'
                                className='form-control'
                                placeholder='Search keyword'
                                onKeyDown={handleKeyPress}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                />
                                <button type='submit'>
                                <i className='ri-search-line'></i>
                                </button>
                             
                            </form>
                        </div>

                        <div className='side-bar-widget'>
                            <h3 className='title'>Sort By</h3>
                            <div className='form-group select-group'>
                                <select className='form-select form-control' onChange={(e)=>sortdata(e.target.value)}>
                                <option value='0' data-display='Sort By (Default)'>
                                    Sort By (Most Relevant)
                                </option>
                                <option value='1'>Recently Sold</option>
                                <option value='2'>Name: A-Z</option>
                                <option value='3'>Name: Z-A</option>
                                <option value='4'>Price: High to Low</option>
                                <option value='5'>Price: Low to High</option>
                                <option value='6'>Recently Listed</option>
                                <option value='7'>Oldest Listing</option>
                                <option value='8'>Most Liked</option>
                                </select>
                            </div>
                        </div>
                    </div>


                    </div>

                    <div className='col-lg-9'>
                        <div className='row justify-content-center'>
                        {nftcontracts?.map((nft) => (
                            <NFTCard key={nft.id} item={nft} col={4}/>
                        ))}
                        </div>

                         <Pagination page={page} sort={sort} searchQuery={searchQuery} collection={pathname} />

                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default Collection;