import React from 'react';
import { Container } from '@mui/material';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import KittycatNFTHome from './pages/home';
import KittycatNFTBrowse from './pages/browser';
import KittycatNFTDetail from './pages/page_detail';
import KittycatMYNFTS from './pages/mynfts';
import KittycatUser from './pages/user';
import NFTHEVRequest from './pages/sendrequest';

import KittycatCollections from './pages/collectionpage';
import KittycatNFTSCreate from './pages/createnft';
import KittycatProfileCreate from './pages/createprofile';
import NFTHEVStatic from './pages/static'
import { Header, Footer, Footerinfo } from './containers';
import useStyles from './styles';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers'
// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
// import { Web3Modal } from '@web3modal/react'
// import { configureChains, createConfig, WagmiConfig } from 'wagmi'
// import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
// import { arbitrum, mainnet, polygon, bsc, bscTestnet, polygonMumbai, goerli } from 'wagmi/chains'

// const chains = [arbitrum, mainnet, polygon, bsc, bscTestnet, polygonMumbai, goerli]
// const projectId = process.env.REACT_APP_WEBCONNECT_PRODUCTID;
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, chains }),
//   publicClient
// })
// const ethereumClient = new EthereumClient(wagmiConfig, chains)


function getLibrary(provider) {
    return new ethers.providers.Web3Provider(provider);
}

const App = ()=> {
    const classes = useStyles();


    return(   
        <BrowserRouter>
            <Container className={classes.container} disableGutters maxWidth={false}>
                <Web3ReactProvider getLibrary={getLibrary}>
                {/* <WagmiConfig config={wagmiConfig}>  */}
                <Header/> 
            
                <Switch> 
                <Route path="/" exact component={KittycatNFTHome} />
                <Route path="/connect" exact component={NFTHEVStatic}/>
                <Route path="/about" exact component={NFTHEVStatic}/>
                <Route path="/policy" exact component={NFTHEVStatic}/>
                <Route path="/terms" exact component={NFTHEVStatic}/>
                <Route path="/marketing" exact component={NFTHEVStatic}/>
                <Route path="/help-center" exact component={NFTHEVStatic}/>
                <Route path="/getting-started" exact component={NFTHEVStatic}/>
                <Route path="/request" exact component={NFTHEVRequest}/>
                <Route path="/topnfts" exact component={KittycatNFTBrowse}/>
                <Route path="/topnftdrops" exact component={KittycatNFTBrowse}/> 
                <Route path="/bestdeals" exact component={KittycatNFTBrowse}/>           
                <Route path="/browse" exact component={KittycatNFTBrowse} />
                <Route path="/browse/nftdrop" exact component={KittycatNFTBrowse} />
                <Route path="/browse/collections" exact component={KittycatNFTBrowse} />
                <Route path="/detail/:contract/:tokenid" exact component={KittycatNFTDetail} />
                <Route path="/detail/nftdrop/:id/:contract" exact component={KittycatNFTDetail} />
                <Route path="/mynfts" exact component={KittycatMYNFTS} />
                <Route path="/mynfts/collections" exact component={KittycatMYNFTS} />
                <Route path="/user/:user" exact component={KittycatUser} />
                <Route path="/collection/:collection" exact component={KittycatCollections} />
                <Route path="/profile/create" exact component={KittycatProfileCreate}/>
                <Route path="/create" exact component={NFTHEVStatic}/>
                <Route path="/create/nft" exact component={KittycatNFTSCreate}/>
                <Route path="/create/collection" exact component={KittycatNFTSCreate}/>
                <Route path="/create/collection-add" exact component={KittycatNFTSCreate}/> 
                <Route component={KittycatNFTHome}/>
                </Switch> 
                {/* </WagmiConfig>
                <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />  */}
                </Web3ReactProvider>
                <Footerinfo/>
                <Footer/> 
            </Container>
        </BrowserRouter>
    )
};

export default App;

