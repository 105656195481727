import React from 'react';
import { CircularProgress } from '@mui/material';
import NFTTopCollections from "../../../components/Cards/NFTTopCollection/NFTTopCollection";
import { useSelector } from 'react-redux';


const Topcollections = ()=> {
    const { topfeaturedcollections } = useSelector((state) => state.collections);
    
    if (!topfeaturedcollections) {
      return (
        <div className='item-details-area pt-100 pb-70'>
          <div className='container'>
            <div className='row'>
            <CircularProgress size="5em" />
            </div>
          </div>
        </div>
      );
    }

    return (
        
      <div className='featured-area pt-100 pb-70'>
        <div className='container'>
          <div className='tab featured-tab-area'>
            <div className='row align-items-center'>
              <div className='col-lg-8 col-md-6'>
                <div className='section-title'>
                  <h2>Top NFT Collections</h2>
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='auction-btn text-end'>
                    <a  href='/browse/collections?page=1&sort=1' className='default-btn border-radius-5'>Explore More</a>
                </div>
              </div>


            </div>

            <div className='row justify-content-center pt-45'>
              {topfeaturedcollections?.map((collection) => {    
                      return(<NFTTopCollections key={collection.id} item={collection} col={3} />);
              })}
            </div>

         </div>
        </div>
      </div>
    )
};

export default Topcollections;