import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, OutlinedInput, MenuItem, Select, Chip, FormHelperText, FormControl} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NFTStorage, File } from 'nft.storage'
import { useWeb3React } from "@web3-react/core"
import { Blockchains } from '../../../utils/selectOptions'
import { getCats } from '../../../actions/nfts';
import { getNetworkBySymbol } from '../../../actions/network';
import { getCollectionByUrlBol, addCollection, uploadCollectionFile } from '../../../actions/collections'; 
import { MintNFTShared } from '../../Blockchain/MintBuySell';
import { MintSharedCollectionEvent } from '../../Blockchain/Events';
import {rProfileImagef, rBannerImagef } from '../../../utils/formatImage'

import Snackalert from '../../Buttons/Snackalert';
import Loading from "../../../images/cardicons/loading.gif";
import {nftstoragecall} from '../../../actions/apicalls';
import axios from 'axios';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, category, theme) {
  return {
    fontWeight:
      category.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const CreateNFTCard = () => {
    const [category, setCategory] = useState([]);
    const { cats } = useSelector((state) => state.nfts);
    const [values, setValues] = useState({
        nftname:'', nfturlname:'', nftcat:'', nftfiles:'', nftdesc:'', nftquantity:'', nftproperties:'', nftnetwork:'', nftcontract:''
    });
    const [collectionform, setCollectionform] = useState({
        collectbanner:'', collectprofilepic:''
    });
    const [displayname, setDisplayname] = useState('');
    const [displaynameBanner, setDisplaynameBanner] = useState('');
    const [displaynameProfilepic, setDisplaynameProfilepic] = useState('');
    const [displayProfileFile, setDisplayProfileFile] = useState('');
    const [fee, setFee] = useState('');
    const [network, setNetwork] = useState('');
    const [disableForm, setDisableForm] = useState(true);
    const [imageURLs, setImageURLs] = useState([]);
    const [imageURLsBanner, setImageURLsBanner] = useState([]);
    const [imageURLsProfilepic, setImageURLsProfilepic] = useState([]);
    const [formaterror, setFormaterror] = useState('');
    const [persedJSON, setPersedJSON] = useState('');
    const [isloading, setisLoading] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();
    const userprofile= JSON.parse(localStorage.getItem('profile')); 
    const { library } = useWeb3React();
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = useState(false);
    const [dowhilecounter, setDowhilecounter]=useState(0);
    const [collectionid, setCollectionid] = useState();
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };
   
    let propvalue = ''
 
    let addedcollectioninfo;
    let totalmintcycle
    
   

    const handleChange = (event) => {
        const {
        target: { value },
        } = event;
        setCategory(
       
        typeof value === 'string' ? value.split(',') : value,
        );
        setValues({...values, nftcat: value});
        val('nftcat', value)
       
    };
    
    const val = (prop, input)=>{
        const name = prop ==='nftname'? input : values.nftname;
        const descript = prop ==='nftdesc'? input : values.nftdesc;
        const nftfiles = prop ==='nftfiles'? input : values.nftfiles;
        const nftcat = prop ==='nftcat'? input : values.nftcat;
        const properties = prop === 'nftproperties' ? input : values.nftproperties;
        const nftfilesbanner = prop ==='collectbanner'? input : collectionform.collectbanner;
        const nftfilesprofilepic = prop ==='collectprofilepic'? input : collectionform.collectprofilepic;
        
        const nftnetwork = prop === 'nftnetwork' ? input : values.nftnetwork;
        if(input){
            if (name.length!=0 && descript.length!=0 && nftfiles.length!=0 && nftcat.length!=0 && nftfilesbanner.length!=0 && nftfilesprofilepic.length!=0 && properties.length!=0 && nftnetwork.toString().length!=0 ){
                setDisableForm(false)
            }else {
                
                setDisableForm(true)
            }
        }else{
            setDisableForm(true)
        }
    }

    const uploadNFTCollectionpic = (nftfiles, pictype)=>{
        let filename=nftfiles[0].name;
     
        if(nftfiles.length>1){
            for (let i = 1; i < nftfiles.length; i++) {
                        filename = filename+', '+nftfiles[i].name
                }
        }
        pictype ==='collectbanner'? setDisplaynameBanner(filename) : setDisplaynameProfilepic(filename);

      
        const imagefiles = Array.from(nftfiles);
        const previewurl = imagefiles.map((imagefile)=>(
            URL.createObjectURL(imagefile)
        ))
        pictype ==='collectbanner'? setImageURLsBanner(previewurl) : setImageURLsProfilepic(previewurl);

        pictype ==='collectbanner'? setCollectionform({...collectionform, collectbanner: nftfiles[0]}) : setCollectionform({...collectionform, collectprofilepic: nftfiles[0]});
    }

    const uploadNFTpic = (nftfiles)=>{
        let filename=nftfiles[0].name;
  
        if(nftfiles.length>1){
            for (let i = 1; i < nftfiles.length; i++) {
                        filename = filename+', '+nftfiles[i].name
                }
        }
        setDisplayname(filename);

        const imagefiles = Array.from(nftfiles);
        const previewurl = imagefiles.map((imagefile)=>(
            URL.createObjectURL(imagefile)
        ))
        setImageURLs(previewurl);

        
        val('nftfiles', nftfiles)
        setValues({...values, nftfiles: nftfiles});
    }

    const uploadPropertyFile = (nftfiles)=>{
        let filetype=nftfiles[0].type;
        if(filetype.split('/')[1]==='json'){
            setDisplayProfileFile(nftfiles[0].name);
        

            const fileReader = new FileReader();
            fileReader.readAsText(nftfiles[0], "UTF-8");
            fileReader.onload = e => {
                const parsedfile = JSON.parse(e.target.result);
                setPersedJSON(parsedfile);
           
                const namearray = displayname.split(', ');
                if(namearray.length>0){
                    namearray.map((arraykey)=>{
                        let arraymatch = arraykey.split('.')[0].toString()
                        if(!parsedfile.properties[arraymatch]){
                          
                            return setFormaterror('JSON File not formatted properly. Ensure name of NFT files match file names entered in the json file.');
                        }else if(!parsedfile.properties[arraymatch].name){
                            return setFormaterror('JSON File not formatted properly. Ensure name is a child of the exact file name in the json file.');
                        }else if(!parsedfile.properties[arraymatch].attributes){
                            return setFormaterror('JSON File not formatted properly. Ensure attributes is a child of the file name entered in the json file.');
                        }else if (!parsedfile.properties[arraymatch].description){
                            return setFormaterror('JSON File not formatted properly. Ensure description is a child of the file name entered in the json file.');
                        }else if (!parsedfile.properties[arraymatch].price){
                            return setFormaterror('JSON File not formatted properly. Ensure price is a child of the file name entered in the json file.');
                        }else if (!parsedfile.properties[arraymatch].creatorsfee){
                            return setFormaterror('JSON File not formatted properly. Ensure creatorsfee is a child of the file name entered in the json file.');
                        }
                           
                    })
                }
               
                val('nftproperties', nftfiles)
                setValues({...values, nftproperties: nftfiles[0]});
            };
            

        }else{
            setFormaterror('Upload only json file.')
         
        }
    }


    const handleName = async(name)=>{
        if(name){
            const urlname = name.split(' ').join('_')
            let urlnamecheck = await dispatch(getCollectionByUrlBol(urlname));
            if(urlnamecheck===false){
               setValues({...values, nfturlname:urlname, nftname:name }); 
            }else{
                let i=1;
                do{
                    i++;
                    const rename= name+'_'+i;
                    let renamecheck = await dispatch(getCollectionByUrlBol(rename));
                    if(!renamecheck){
                        i=200;
                        setValues({...values, nfturlname:rename, nftname:name}); 
                    }
                    
                }while(i<100)
           }
           val('nftname',name);

        }else{
            setValues({...values, nfturlname:name, nftname:name}); 
        }
    }

 
    const handleSubmitCollection= async(e)=>{
        e.preventDefault()
        setisLoading(true)
       
        const bannername = 'banner.png';
        const thumbnailname = 'thumbnail.png';
        
        const profilefileext = displaynameProfilepic.split('.')[1];
        let resizeprofilepic;
        if(profilefileext==='gif') {resizeprofilepic=collectionform.collectprofilepic}else{resizeprofilepic = await rProfileImagef(collectionform.collectprofilepic);}
        const resizebannerpic = await rBannerImagef(collectionform.collectbanner);
      
        const fd = new FormData();
        fd.append('files', resizebannerpic);
        fd.append('name', bannername);
        fd.append('url', values.nfturlname);
        fd.append('banner', 1);
        dispatch(uploadCollectionFile(fd));
    
        const filedata = new FormData();
        filedata.append('files', resizeprofilepic);
        filedata.append('name', thumbnailname);
        filedata.append('url', values.nfturlname);
        filedata.append('banner', 0);
        dispatch(uploadCollectionFile(filedata));

        const jd = new FormData();
        jd.append('files', values.nftproperties);
        jd.append('name', values.nftproperties.name);
        jd.append('url', values.nfturlname);
        jd.append('banner', 1);
        dispatch(uploadCollectionFile(jd));
       
        totalmintcycle = displayname.split(', ').length
      
        const collectioncontent = {collectionname: values.nftname, collectionurl: values.nfturlname, collectiondesc: values.nftdesc, collectionbannar: bannername, collectionprofpic: thumbnailname, collectionowner: userprofile}; 
         addedcollectioninfo = await dispatch(addCollection(collectioncontent));

        setCollectionid(addedcollectioninfo)
      
            let arraymatch = values.nftfiles[dowhilecounter].name.split('.')[0].toString();
            propvalue = '';
            Object.values(persedJSON.properties[arraymatch].attributes).forEach(function(values, i) {
                if(i === 0){
                    propvalue = values.trait_type+':'+values.value+':'+values.percent
                }else{
                    propvalue = propvalue+','+values.trait_type+':'+values.value+':'+values.percent
                }  
            });
           
            const fdnft = new FormData();
            fdnft.append('files', values.nftfiles[dowhilecounter]);
            fdnft.append('nftname', persedJSON.properties[arraymatch].name);
            fdnft.append('nftdesc', persedJSON.properties[arraymatch].description);
            fdnft.append('nftroyalty', persedJSON.properties[arraymatch].creatorsfee);
            fdnft.append('newarray', JSON.stringify(persedJSON.properties[arraymatch].attributes));
            const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                const metadatlink = metadatavalue.url.split('://')
                await axios.get(`https://ipfs.io/ipfs/${metadatlink[1]}`).then(async(response)=>{
                    const metadataimageurl = response.data.image; 
            // const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                    const nftinfo = {creatorfee: persedJSON.properties[arraymatch].creatorsfee, contract: values.nftcontract, metadataurl: metadatavalue.url, price: persedJSON.properties[arraymatch].price, connectData: userprofile, quantity: 1};
                    const mint = await MintNFTShared(nftinfo, library)
                    
                    if(mint === 'error'){
                        handleSnack('Transaction was not successful', 'warning')
                        setisLoading(false)
                    }else{
                        
                        const content = {nftprojectname: persedJSON.properties[arraymatch].name, nftcontract: values.nftcontract, nftprice: persedJSON.properties[arraymatch].price, nftdesc: persedJSON.properties[arraymatch].description, cid: metadataimageurl, meta: metadatavalue.url, nftcategory: values.nftcat.toString(), nftino: 0, nftstatus:1, nftcreatedby: userprofile, nftownedby:userprofile, nftprop:propvalue, nftcollectionname:values.nfturlname, nftcollectionid:addedcollectioninfo, nftblockchain: values.nftnetwork};
                        const transactioninfo = {nftaddress: values.nftcontract, txtype: 'MINT', nftmintprice: persedJSON.properties[arraymatch].price, nftamount: 1, seller: userprofile, buyer: userprofile, date:new Date()};
                        let MintNFTid = await MintSharedCollectionEvent(nftinfo, library, content, transactioninfo, setisLoading, handleSnack, clear, dispatch, setDowhilecounter, dowhilecounter, totalmintcycle);
                        
                    }
                })
            })
            
  
    }

    const handleSelectBlockchain = async (event) =>{
        let abrv = {symb:event.target.value, contracttype: "Shared", mainnet:0, active:1 }
        let contractinfo = await dispatch(getNetworkBySymbol(abrv));
        setNetwork(event.target.value);
        setValues({...values, nftnetwork: event.target.value, nftcontract: contractinfo[0].contract });
        val('nftnetwork', event.target.value)
    }

   
    
    const clear = ()=>{
        setValues({nftname:'', nfturlname:'', nftcat:'', nftfiles:'', nftdesc:'', nftquantity:'', nftproperties:'', nftnetwork:'', nftcontract:''});
        setCollectionform({collectbanner:'', collectprofilepic:''});
        setDisplayname('');
        setDisplaynameBanner('');
        setDisplaynameProfilepic('');
        setDisplayProfileFile('');
        setNetwork('');
        setDisableForm(true);
        setImageURLs([]);
        setImageURLsBanner([]);
        setImageURLsProfilepic([]);
        setFormaterror('')
        setPersedJSON('')
        setDowhilecounter(0)
        setCategory([]);
        setCollectionid()
        propvalue = '';
        totalmintcycle = null;
    }

    const dowloadsamplefile = ()=>{
        fetch('/images/collections/samplenftcollection.json').then(response=>{
            response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Samplejsonfile.json';
            alink.click();
           })
        })
    }

    useEffect(() => {
        dispatch(getCats(0));
    }, []);

    useEffect(()=>{
        async function nftmindloader(){
            totalmintcycle = displayname.split(', ').length
            let filenameinfo = values.nftfiles ? values.nftfiles[dowhilecounter].name.split('.')[0].toString() : "";
            if(dowhilecounter>0 && dowhilecounter<totalmintcycle){
               
                const fdnft = new FormData();
                fdnft.append('files', values.nftfiles[dowhilecounter]);
                fdnft.append('nftname', persedJSON.properties[filenameinfo].name);
                fdnft.append('nftdesc', persedJSON.properties[filenameinfo].description);
                fdnft.append('nftroyalty', persedJSON.properties[filenameinfo].creatorsfee);
                fdnft.append('newarray', JSON.stringify(persedJSON.properties[filenameinfo].attributes));
                const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                    const metadatlink = metadatavalue.url.split('://')
                    await axios.get(`https://ipfs.io/ipfs/${metadatlink[1]}`).then(async(response)=>{
                        const metadataimageurleffect = response.data.image; 
                // const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                        const nftinfo = {creatorfee: persedJSON.properties[filenameinfo].creatorsfee, contract: values.nftcontract, metadataurl: metadatavalue.url, price: persedJSON.properties[filenameinfo].price, connectData: userprofile, quantity: 1};
                        const mint = await MintNFTShared(nftinfo, library)
                        
                        if(mint === 'error'){
                            handleSnack('Transaction was not successful', 'warning')
                            setisLoading(false)
                        }else{
                            propvalue = '';
                            Object.values(persedJSON.properties[filenameinfo].attributes).forEach(function(values, i) {
                                if(i === 0){
                                    propvalue = values.trait_type+':'+values.value+':'+values.percent
                                }else{
                                    propvalue = propvalue+','+values.trait_type+':'+values.value+':'+values.percent
                                }  
                            });
                        
                            const content = {nftprojectname: persedJSON.properties[filenameinfo].name, nftcontract: values.nftcontract, nftprice: persedJSON.properties[filenameinfo].price, nftdesc: persedJSON.properties[filenameinfo].description, cid: metadataimageurleffect, meta: metadatavalue.url, nftcategory: values.nftcat.toString(), nftino: 0, nftstatus:1, nftcreatedby: userprofile, nftownedby:userprofile, nftprop:propvalue, nftcollectionname:values.nfturlname, nftcollectionid:collectionid, nftblockchain: values.nftnetwork};
                            const transactioninfo = {nftaddress: values.nftcontract, txtype: 'MINT', nftmintprice: persedJSON.properties[filenameinfo].price, nftamount: 1, seller: userprofile, buyer: userprofile, date:new Date()};
                            let MintNFTid = await MintSharedCollectionEvent(nftinfo, library, content, transactioninfo, setisLoading, handleSnack, clear, dispatch, setDowhilecounter, dowhilecounter, totalmintcycle);
                            
                        }
                    })
                })
            }
        }
        nftmindloader()
    },[dowhilecounter])

    return (
    <>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='collection-form-area'>
            <div className='section-title'>
                <h2>Create New NFT Collection</h2>
            </div>

            <div className='collection-form'>
                <form onSubmit={handleSubmitCollection}>
                    <div className='profile-outer'>
                        <h3>Upload Collection Banner Picture (1440px X 320px, 500KB max size)*</h3>
                        <div className='profileButton'>
                            <input
                            className='profileButton-input'
                            type='file'
                            name='attachments[]'
                            accept='image/png, image/jpg, image/jpeg'
                            id='uploadBanner'
                            onChange={(e)=>{uploadNFTCollectionpic(e.target.files, 'collectbanner') }}
                            />
                            <label
                            className='profileButton-button ripple-effect'
                            htmlFor='uploadBanner'
                            >
                            {displaynameBanner ? displaynameBanner :'png, jpg and jpeg only'}
                            </label>
                            <span className='profileButton-file-name'></span>
                        </div>
                        <FormHelperText sx={{color:'red'}} className='danger' hidden={collectionform.collectbanner.length>0?true:false}>Upload Collection Banner Picture (1440px X 320px, 500KB max size)</FormHelperText>
                    </div>

                    <div className='preview-box' hidden={imageURLsBanner.length>0?false:true}>
                        <h3>Preview Banner</h3>

                        <div className='previewButton'>
                            <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                            {imageURLsBanner.map((imageURLBanner, index)=>(     
                                    <img key={index} src={imageURLBanner} className="img-thumbnail col-3"/>
                            ))}
                            </div>
                        </div>
                    </div>

                    <div className='profile-outer'>
                        <h3>Upload Collection Profile Picture (540px X 540px, 500KB max size)*</h3>
                        <div className='profileButton'>
                            <input
                            className='profileButton-input'
                            type='file'
                            name='uploadProfilepic'
                            accept='image/png, image/jpg, image/jpeg'
                            id='uploadProfilepic'
                            onChange={(e)=>{uploadNFTCollectionpic(e.target.files, 'collectprofilepic') }}
                            />
                            <label
                            className='profileButton-button ripple-effect'
                            htmlFor='uploadProfilepic'
                            >
                            {displaynameProfilepic ? displaynameProfilepic :'png, jpg and jpeg only'}
                            </label>
                            <span className='profileButton-file-name'></span>
                        </div>
                        <FormHelperText sx={{color:'red'}} className='danger' hidden={collectionform.collectprofilepic.length>0?true:false}>Upload Collection Profile Picture (540px X 540px, 500KB max size)</FormHelperText>
                    </div>

                    <div className='preview-box' hidden={imageURLsProfilepic.length>0?false:true}>
                        <h3>Preview Profile Picture</h3>

                        <div className='previewButton'>
                            <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                            {imageURLsProfilepic.map((imageURLProfilepic, index)=>(     
                                    <img key={index} src={imageURLProfilepic} className="img-thumbnail col-3"/>
                            ))}
                            </div>
                        </div>
                    </div>



                    <div className='profile-outer'>
                        <h3>Upload NFT Files *</h3>
                        <div className='profileButton'>
                            <input
                            className='profileButton-input'
                            type='file'
                            name='upload'
                            accept='image/png, image/jpg, image/jpeg, image/gif'
                            id='upload'
                            onChange={(e)=>{uploadNFTpic(e.target.files) }}
                            multiple
                            />
                            <label
                            className='profileButton-button ripple-effect'
                            htmlFor='upload'
                            >
                            {displayname ? displayname :'png, jpg, jpeg and gif only'}
                            </label>
                            <span className='profileButton-file-name'></span>
                        </div>
                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.nftfiles.length>0?true:false}>Upload File. Total NFT files uploaded must match the total number of NFTs you intend to create in the collection. </FormHelperText>
                    </div>

                    <div className='preview-box' hidden={imageURLs.length>0?false:true}>
                        <h3>Preview</h3>

                        <div className='previewButton'>
                            <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                            {/* <ImageList sx={{ width: '100%', height: 350 }}> */}  
                            {imageURLs.map((imageURL, index)=>(     
                                    <img key={index} src={imageURL} className="img-thumbnail col-3"/>
                            ))}
                            </div>
                        </div>
                    </div>

                    <div className='collection-category'>
                        <h3>Choose Item Category *</h3>
                        <FormControl sx={{ m: 1, width: '100%'}} color='error'>
                    
                        <Select
                            labelId="demo-simple-select-chip"
                            id="demo-multiple-chip"
                            multiple
                            value={category}
                            sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="category" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, borderRadius:'15px', border:'none', borderStyle:'none' }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                            {cats?.map((cat) => (
                                <MenuItem
                                key={cat.id}
                                value={cat.name}
                                sx={{border:'none'}}
                                style={getStyles(cat.name, category, theme)}
                                >
                                {cat.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{color:'red'}} hidden={values.nftcat.length>0?true:false}>Select Categories</FormHelperText>
                        </FormControl>
                    </div>


                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>NFT Collection Name *</label>
                                <input
                                type='text'
                                name='name'
                                id='name'
                                className='form-control'
                                placeholder='e. g. “walking in the air”'
                                onChange={(e)=>{handleName(e.target.value)}}
                                value={values.nftname}
                                />
                                <FormHelperText sx={{color:'red'}} hidden={values.nftname?true:false}>Enter NFT Name</FormHelperText>
                            </div>                        
                        </div>

                        <div className='col-lg-12 col-md-12'>
                            <div className='form-group'>
                                <label>Description *</label>
                                <textarea
                                name='description'
                                className='form-control'
                                id='description'
                                cols='30'
                                rows='5'
                                placeholder='e. g. “after purchasing you will able to get the real product”'
                                onChange={(e)=>{setValues({...values, nftdesc:e.target.value}); val('nftdesc',e.target.value)}}
                                value={values.nftdesc}
                                ></textarea>
                                <FormHelperText sx={{color:'red'}} hidden={values.nftdesc.length>0?true:false}>Enter Description</FormHelperText>
                            </div>
                        </div>
                      

                        <div className='collection-category'>
                            <h3>Select Blockchain *</h3>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth color='error'>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                                        value={network}
                            
                                        onChange={handleSelectBlockchain}
                                        >
                                            {Blockchains.map((network, i)=>(
                                                <MenuItem sx={{border:'none'}} key={i} value={network}>{network}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                        <FormHelperText sx={{color:'red'}} hidden={values.nftnetwork.toString().length>0?true:false}>Select Blockchain to mint NFT</FormHelperText>
                            
                                    </FormControl>
                                </Box>

                        </div>
 
                        
                        <div className='profile-outer'>
                            <h3>Upload JSON File With Properties * <FormHelperText  onClick={dowloadsamplefile} sx={{ cursor: 'pointer', color: 'blue' }}>Click here to download a sample JSON file</FormHelperText></h3> 
                            <div className='profileButton'>
                                <input
                                className='profileButton-input'
                                type='file'
                                name='jsonfile'
                                accept='application/json' //make this json file
                                id='uploadPropFile'
                                onChange={(e)=>{uploadPropertyFile(e.target.files) }}
                                disabled = {displayname!='' ? false:true }
                                />
                                <label
                                className='profileButton-button ripple-effect'
                                htmlFor='uploadPropFile'
                                >
                                {displayname ? displayProfileFile ? displayProfileFile :'JSON only' : 'Upload NFT files for ability to upload json file'}
                                </label>
                                <span className='profileButton-file-name'></span>
                            </div>
                            <FormHelperText sx={{color:'red'}} className='danger' hidden={values.nftproperties.length>0?true:false}>Upload file with properties </FormHelperText>
                            <FormHelperText sx={{color:'red'}} className='danger' hidden={formaterror.length>0?false:true}>{formaterror} </FormHelperText>
                        </div>

                        <div className='col-lg-12 col-md-12'>
                            <button
                                type='submit'
                                className='default-btn border-radius-5'
                                disabled={disableForm}
                                style={{maxWidth:'140px'}}
                            >
                                 {isloading?  <img src={Loading} style={{mixBlendMode: 'multiply', transform: 'scale(1.7)'}} className=""/> :"Create"}
                                
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
};

export default CreateNFTCard;
