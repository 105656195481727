import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import { NewsLetterEl, Title, Sub, Input, ChBox, Btn, PL } from "./Styled";
import { FormHelperText} from '@mui/material';
import { EmailCheck } from '../../../utils/validate'
import {createSubscriber} from '../../../actions/subscribe'





export default function JoinCommunity() {
  const [emailvalue, setEmailvalue] = useState({
      email:'', terms:''
  });
  const [validate, setValidate] = useState({
    emailinfo:'', terms:''
  });
  const dispatch = useDispatch();

  const handleSubmit = (e)=>{
    e.preventDefault()
    if( EmailCheck(emailvalue.email) === true && emailvalue.terms===true){
      console.log('submit')
      dispatch(createSubscriber(emailvalue));
      clear();
      
    }else if(EmailCheck(emailvalue.email) != true && emailvalue.terms===true){
      setValidate({emailinfo:false, terms:true})
    }else if(EmailCheck(emailvalue.email) === true && emailvalue.terms!=true){
      setValidate({emailinfo:true, terms:false})
    }else(
      setValidate({emailinfo:false, terms:false})
    )
  
  }

  const clear =()=>{
    setEmailvalue({email:'', terms:''})
    setValidate({emailinfo:'', terms:''})
  }

  return (
    <NewsLetterEl>
      <Title>Don't miss out </Title>
      <Sub>Subscribe to our newletter for the latest drops, hot NFTs, News & more</Sub>
      <Input>
        <input onChange={(e) => {setEmailvalue({ ...emailvalue, email: e.target.value }); setValidate({...validate, emailinfo: EmailCheck(e.target.value)})}} value ={emailvalue.email} type="email" placeholder="Email" />
        <Btn onClick={handleSubmit}>Subscribe</Btn>
      </Input>
      <FormHelperText sx={{color:'red', textAlign: 'center'}} hidden={validate.emailinfo===false?false:true}>Must be email</FormHelperText>
      <ChBox>
        <input checked={validate.terms} onChange={(e) => {setEmailvalue({ ...emailvalue, terms: e.target.checked }); setValidate({...validate, terms: e.target.checked})}} type="checkbox" id="NewsSub" />
        <label htmlFor="NewsSub">
          I have read and agree to the <PL href="#">Terms and Conditions </PL> and  <PL href="#">Privacy Policy</PL> of this site
        </label>
        <FormHelperText sx={{color:'red', textAlign: 'center'}} hidden={validate.terms===false?false:true}>Must agree to Terms and Conditions and Privacy Policy</FormHelperText>
      </ChBox>
    </NewsLetterEl>
  );
}