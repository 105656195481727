import { FETCH_TRANSACTIONS, FETCH_DROPTRANSACTIONS } from '../constants/actionTypes';

const transactions = (state = { isLoading: true }, action) => {
  switch (action.type) {  

    case 'START_LOADING':
      return { ...state, isLoading: true };
      
    case 'END_LOADING':
      return { ...state, isLoading: false };

    case FETCH_TRANSACTIONS:
      return {
        ...state,
        transaction: action.payload.data,
      };

    case FETCH_DROPTRANSACTIONS:
      return {
        ...state,
        droptransaction: action.payload.data,
      };
  
    default:
      return state;
  }
};

export default transactions;