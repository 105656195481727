import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import DropCard from "../../../components/Cards/NFTDrop/NFTDrop";



const Nftdrops = () => {

  const { nftdrops } = useSelector((state) => state.nfts);


  useEffect(() => {
    setInterval(() => {
    }, 1000);
  }, []);
  return (
    <>
      <div className='auctions-area-three pt-100 pb-70'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-8 col-md-6'>
              <div className='section-title'>
                <h2>NFT Drops</h2>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='auction-btn text-end'>
                  <a  href='/browse/nftdrop?page=1&sort=1' className='default-btn border-radius-5'>Explore More</a>
              </div>
            </div>
          </div>

          <div className='row justify-content-center pt-45'>
            {nftdrops?.map((nftdrop) => {    
                    return(<DropCard key={nftdrop.id} item={nftdrop} col={3} />);
            })}    

          </div>
        </div>
      </div>
    </>
  );
};

export default Nftdrops;
