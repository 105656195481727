
import React from 'react';
import { useDispatch } from 'react-redux';
import { useWeb3React } from "@web3-react/core"
import { useHistory } from 'react-router-dom';
// import { Web3Provider } from '@ethersproject/providers';
// import WalletConnectProvider from '@walletconnect/ethereum-provider';
import { injected } from '../../../components/Blockchain/Connector';
import * as actionType from '../../../constants/actionTypes';
// import { getConnectedUser } from '../../../actions/users';
// import AuthorProfile from '../../../components/Cards/AuthorProfile/AuthorProfile';
// import CreateNFTCollectionCard from '../../../components/Cards/CreateNFTCollectionCard/CreateNFTCollectionCard'





const CreatePage = () => {
    const { account, activate} = useWeb3React();
    // const [snackmess, setSnackmess] = useState(undefined); 
    // const [severity, setSeverity] = useState('success');
    // const [opensnack, setOpensnack] = React.useState(false);
    // const [metamask, setMetamask] = useState(false);
    // const [connectWallet, setConnectWallet] = useState(false);
    // const [connectLink, setConnectLink] = useState(false);
    // const [walletConnectAcc, setWalletConnectAcc] = useState(localStorage.getItem('walletconnect')); 
    const dispatch = useDispatch();
    const history = useHistory();

    // if(!account){
    //     history.push(`/browse`);
    // }
    const openSingleNFT = () => {
        history.push(`/create/nft`);
    };

    const openCollection = () => {
        history.push(`/create/collection`);
    };

    const openAddCollection = () => {
        history.push(`/create/collection-add`);
    };

    const connectWallet = async()=> {
        try {
            await activate(injected)
            dispatch({ type: actionType.CONNECT, payload:'connected' });
        } catch (ex) {
            console.log(ex)
        }
    }

    return (
                <div className='wallet-area pt-70 pb-70'>
                    <div className='container'>
                        {/* <div className='section-title'>
                            <h2>Connect Your wallet</h2>
                            <p>
                            We support a wide variety of wallet options. Connect with almost any desktop and browser wallet that supports the Ethereum, Polygon and Binance ecosystem. 
                            </p>
                        </div> */}

                        <div className='row pt-45'>
                            <div className='col-lg-4 col-6' style={{cursor:'pointer'}} onClick={account? openSingleNFT: connectWallet}>
                                <div className='wallet-card' >
                                    <img src='../images/createpage/single-nft.svg' height='70px' width='70px' alt='Images' />
                                    <h3>
                                        {account ? "Create a NFT" : "Connect Wallet"}
                                       
                                    </h3>
                                    <p>
                                        {/* {metamask ===true && 'Account Connected: ' +account?.split('').splice(0, 10).join('')}{'...'}{account?.split('').splice(32)} */}
                                   
                                    </p>
                                    {/* <div className='top-btn'>Simple</div> */}
                                </div>
                            </div>

                            <div className='col-lg-4 col-6' style={{cursor:'pointer'}} onClick={account ? openCollection : connectWallet}>
                                <div className='wallet-card'>
                                    <img src='../images/createpage/nft-collection.svg' height='70px' width='70px' alt='Images' />
                                    <h3>
                                        {account ? "Create NFT Collection" : "Connect Wallet"}
                                        
                                    </h3>
                                    <p>
                                        {/* {account && 'Account Connected: ' +account.split('').splice(0, 10).join('')}{'...'}{account.split('').splice(32)} */}
                                    </p>
                                    {/* <div className='top-btn'>Credit Card Follow</div> */}
                                </div>
                            </div>

                            <div className='col-lg-4 col-6'  style={{cursor:'pointer'}} onClick={account ? openAddCollection : connectWallet}>
                                <div className='wallet-card'>
                                    <img src='../images/createpage/nft-add-collection.svg' height='70px' width='70px' alt='Images' />
                                    <h3>
                                    {account ? "Add to Existing NFT Collection" : "Connect Wallet"}
                                    
                                    </h3>
                                    <p>
                                     {/* {account && 'Account Connected: ' +account.split('').splice(0, 10).join('')}{'...'}{account.split('').splice(32)} */}
                                    </p> 
                                    {/* <div className='top-btn'>Simple</div>*/}
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>

    );
};

export default CreatePage;