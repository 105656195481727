import React from 'react';

const RequestDescription = ({item}) => {
 
    return (
      <>
        <div className='author-profile-sidebar  mr-20'>
            <div className='section-title text-center'>
              <h4>NFT Drop or Cryptocurrency Integration Request</h4>
            </div>
            <div>
              <p>
                 Do you run a cryptocurrency community? Are you looking to expand the utility of your cryptocurrency? Would you like to use your cryptocurrency to purchase NFTs? If so, then look no further. As a leading NFT marketplace, we can integrate any ERC20 (Ethereum or Polygon) or BEP20 token as a currency to purchase NFTs on our platform. We can works with your community to offer a wide range of unique NFTs that can add value to your cryptocurrency community. From collectibles and digital art to gaming items and real estate, we can help provide NFTs for every interest or investment strategy to improve community engagement and growth. Our team is dedicated to helping you create the right NFT to meet your goals, so if you're ready to enhance the utility of your cryptocurrency, reach out to us today and start exploring the world of NFTs
                
              </p>
              <p>
                 If you have a great idea for an NFT drop, we want to hear from you! We are always on the lookout for innovative and exciting NFT drop projects to feature on our platform. Please send us a message through our contact form, and let's bring your NFT drop idea to life!
         
              </p>
            </div>
         </div>

     
      </>

    )



  
};

export default RequestDescription;
