import React, {useEffect, useState} from 'react';
import { Link, Divider, Grid, Paper, Box } from '@mui/material';
import {fDate} from '../../../utils/formatTime'
import { useDispatch, useSelector } from 'react-redux';
import { GiCheckMark } from "react-icons/gi";
import { getUser } from '../../../actions/users';
import Copy from '../../Buttons/Copy';
import Loading from "../../../images/cardicons/loading.gif";
import { BuyBNB, BuyKYC, CheckPrice } from '../../Blockchain/MintBuySell';
import { BuySharedEvent } from '../../Blockchain/Events';
import { getImageSrc }  from '../../../utils/formatImage';
import { fNumber2decimal, fShortenNumber } from '../../../utils/formatNumber'
import { updatedNFTpurchases } from '../../../actions/nfts';
import { useWeb3React } from "@web3-react/core"
import { injected } from '../../Blockchain/Connector';
import * as actionType from '../../../constants/actionTypes';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Snackalert from '../../Buttons/Snackalert';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
}));

const darkTheme = createTheme({ palette: { mode: 'dark' } });
const lightTheme = createTheme({ palette: { mode: 'light' } });


const PageDetailsDescription = ({item, creatordata, ownerdata}) => {
  const { user } = useSelector((state) => state.users);
  const {connectData} = useSelector((state) => state.connect);
  const [loadingBNB, setLoadingBNB] = useState(false);
  const [loadingKYC, setLoadingKYC] = useState(false);
  const {library, activate} = useWeb3React();
  const dispatch = useDispatch();
  const [snackmess, setSnackmess] = useState(undefined); 
  const [severity, setSeverity] = useState('success');
  const [opensnack, setOpensnack] = React.useState(false);
  const [kycprice, setKycprice] = useState('');
  const userprofile= JSON.parse(localStorage.getItem('profile')); 

  const handleSnack = (mess, severity) => {
      setOpensnack(true);
      setSnackmess(mess);
      setSeverity(severity);
  };

  const buyWithBNB = async(saledata)=>{
        setLoadingBNB(true);
        const nftinfo = {contract: saledata.contract, id: saledata.tokenid, mintprice: saledata.mintprice};
        const updatedata = {nftcontract: saledata.contract, nfttokenid: saledata.tokenid, nftmintprice: saledata.mintprice, nftstatus:0, newowner:connectData };
        await BuyBNB(nftinfo, library).then(async(res)=>{
            if(!res || res!=true){
                res.data? handleSnack(res.data.message, 'error') : handleSnack('Transaction unsuccessful', 'warning')
                setLoadingBNB(false);
            } else{
            
                const updatedata = {nftcontract: saledata.contract, nfttokenid: saledata.tokenid, nftmintprice: saledata.mintprice, nftstatus:0, newowner:userprofile };    
                const transactioninfo = {nftaddress: saledata.contract, nfttokenid: saledata.tokenid, txtype: 'BUY', nftmintprice: saledata.mintprice, nftamount: 1, seller: saledata.owner, buyer: userprofile, date: new Date()};
                await BuySharedEvent(nftinfo, library, updatedata, transactioninfo, setLoadingBNB, handleSnack, dispatch);

            }    
        });
    }

  const buyWithKYC = async(saledata)=>{
      setLoadingKYC(true);
      const nftinfo = {contract: saledata.contract, id: saledata.tokenid, mintprice: saledata.mintprice};
      const updatedata = {nftcontract: saledata.contract, nfttokenid: saledata.tokenid, nftmintprice: saledata.mintprice, nftstatus:0, newowner:connectData };
      await BuyKYC(nftinfo, library).then(
        async(res)=>{
            if(!res || res!=true){
                res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
                setLoadingKYC(false);
            } else{
                const updatedata = {nftcontract: saledata.contract, nfttokenid: saledata.tokenid, nftmintprice: saledata.mintprice, nftstatus:0, newowner:userprofile };    
                const transactioninfo = {nftaddress: saledata.contract, nfttokenid: saledata.tokenid, txtype: 'BUY', nftmintprice: saledata.mintprice, nftamount: 1, seller: saledata.owner, buyer: userprofile, date: new Date()};
                await BuySharedEvent(nftinfo, library, updatedata, transactioninfo, setLoadingKYC, handleSnack, dispatch);

            }
      });
  }

  const ConnecttoWallet = async()=> {
      try {
          await activate(injected)
          dispatch({ type: actionType.CONNECT, payload:'connected' });
      } catch (ex) {
        console.log(ex)
      }
  }

  const getKYCPrice = async(price)=>{
    if(price){
        let NFTprice;
        NFTprice = await CheckPrice(price, library);
        NFTprice = fNumber2decimal(NFTprice)
        setKycprice(NFTprice)
    }
  }

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimage.png'
   }



  // useEffect(() => {
  //   if (connectData) {
  //    getKYCPrice(item.mintprice)
  //   }
  // }, [item.mintprice, connectData]);
  
  return (
    <>
      <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
      <div className='section-title'>
        <h2>{item.name}</h2>
        <p>
          {item.projdesc}
        </p>
      </div>

      <div className='row'>
        <div className='col-lg-6 col-6'>
          <div className='item-details-user'>
            <h3>Creator</h3>
            <div className='content'>
              <div className='images'>
                <img
                  src={getImageSrc(creatordata)}
                  alt={creatordata?.username} 
                  onError={addDefaultSrc}
                />
                {creatordata?.verified===1&&<i><GiCheckMark/></i>}
              </div>

              <span>{creatordata?.username}</span>
            </div>
          </div>
        </div>

        <div className='col-lg-6 col-6'>
          <div className='item-details-user'>
            <h3>Item Owner</h3>
            <div className='content'>
              <div className='images'>
                <img
                  src={getImageSrc(ownerdata)}
                  alt={ownerdata?.username} 
                  onError={addDefaultSrc}
                />
                {ownerdata?.verified===1&&<i><GiCheckMark/></i>}
              </div>

              <span>{ownerdata?.username}</span>
            </div>
          </div>
        </div>
      </div>

      {item.salestatus === 1 ? 
        <div className='d-flex justify-content-between'>  
        <div className='item-details-in-content'>
          <div><h2> BUY </h2></div>
          <div className='item-right'>
            <div type='button' className='default-btn border-radius-50' style={{pointerEvents: loadingBNB && 'none'}} onClick={()=>{connectData ? loadingBNB === false && buyWithBNB(item) : ConnecttoWallet()}}>
         
                {loadingBNB ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData ? <span title={item.mintprice}>{fShortenNumber(item.mintprice)} {item.chain}  </span>: <h6>Connect Wallet</h6>}
            </div>
          </div>
          {/* {item.chain==='BNB' &&
          <div className='item-right'>
            <div type='button' className='default-btn border-radius-50' style={{pointerEvents: loadingKYC && 'none'}} onClick={()=>{connectData ? loadingKYC === false && buyWithKYC(item) : ConnecttoWallet()}}>
          
              {loadingKYC ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData ? <span title={kycprice}>{fShortenNumber(kycprice)} KYC</span> : <h6>Connect Wallet</h6>}

            </div>
          </div>
          }      */}
        </div>
        </div>

      :
        <div className='item-details-in-content'>
          <div><h3> Not for sale. Make offer tool coming soon </h3></div>
          
        </div>

      }


      <div className='item-details-price'>
        <ul>
          <li>
            Contract
            <b>{item.contract.split('').splice(0, 8).join('')}{'...'}{item.contract.split('').splice(35)} <Copy text={item.contract} /></b>
          </li>
          <li>
            Token ID
            <b>{item.tokenid}</b>
          </li>
          <li>
            Created
            <b>{fDate(item.createdat)}</b>
          </li>
          <li>
            Category
            <b title={item.cat.split(',').join(', ')}>{item.cat.split(',').join(', ').split('').splice(0,25).join('')}{item.cat.split(',').join(', ').length > 25 && '...' }</b>
          </li>
          {item.collection&&
            <li>
              Collection
              <b><a href={`/collection/${item.collection}`} title={item.collection} target='_blank'>{item.collection.split('').splice(0, 25).join('')}{item.collection.split('').length > 25 && '...' }</a></b>
            </li>
          }
        </ul>
      </div>

      <h3>{item.props&&'Properties'}</h3>
      <div className='item-details-user-item'>
      
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ThemeProvider theme={lightTheme}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: 'background.default',
                  display: 'grid',
                  gridTemplateColumns: { md: '1fr 1fr 1fr' },
                  gap: 2,
                  border_radius: '5px',
                }}
              >
              
                {item?.props?.split(',').map((prop, i) => (
                  <div key={i}>
                  
                    <Item key={i+6} elevation={7}>
                        <div key={i+1}><b key={i+2}>{prop.split(':')[0]}</b></div>
                        <i key={i+3}>{prop.split(':')[1]}</i>
                        <div key={i+4}><h6 key={i+5}>{prop.split(':')[2]}</h6></div>
                    </Item>
                 
                  </div>
                ))}
              </Box>
            </ThemeProvider>
          </Grid>
          
        </Grid>
      </div>

     




     
  

    </>
  );
};

export default PageDetailsDescription;
