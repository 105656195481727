import React from 'react';
import './footer.css';
import Footer from '../../components/Footer/Footer';

const Footerinfo = ()=> {

    return (
        <Footer/>
    )
};

export default Footerinfo;