
import * as actionType from '../constants/actionTypes';

const initialState = {
  loading: false,
  account: null,
  connectLibrary: null,
  connectProvider: null,
  connectData:null,
  error: null,
};

const connectReducer = (state=initialState, action) => {
  switch (action.type) {
    case actionType.CONNECT:
      return { ...state, connectData: action.payload, loading: false, errors: null };
    
    case actionType.LOGOUT:
      localStorage.clear();

      return { ...state, connectData: null, loading: false, errors: null };

    case actionType.LIBRARY:
        return {...state, connectLibrary: action.payload, loading: false, errors: null };

    case actionType.PROVIDER:
      return {...state, connectProvider: action.payload, loading: false, errors: null };

    default:
      return state;
  }
};

export default connectReducer;