import React from 'react';

import { useSelector } from 'react-redux';
import { getCollectionImageSrc } from '../../../utils/formatImage'

const PageBanner = () => {
  const { collection } = useSelector((state) => state.collections);
  const {nftowner} = useSelector((state) => state.users);

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimageBanner.png'
   }

  return (
    <>
      <div className='inner-banner'>
        <img className='inner-banner-img'
            src={getCollectionImageSrc(collection, false)}
            alt='Banner'
            onError={addDefaultSrc}
        />
        <div className='container'>
          <div className='inner-title'>
            <h3>{collection?.name}</h3>
            <h5> By <a href={`/user/${nftowner?.wallet_address}`}> {nftowner?.username}</a></h5>

            <div className='inner-shape'>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageBanner;
