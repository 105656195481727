import React, {useState, useEffect} from 'react';

import { BiHeart } from "react-icons/bi";
import { Link, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { likePost, updatedNFTpurchases, getNFT } from '../../../actions/nfts';
import { createTransaction } from '../../../actions/transactions';
import { getConnectedUser } from '../../../actions/users';
import { getImageSrc, getNFTImageSrc }  from '../../../utils/formatImage';
import { fNumber2decimal, fShortenNumber } from '../../../utils/formatNumber'

import Transactinicon from "../../../images/cardicons/minticon.png";
import Loading from "../../../images/cardicons/loading.gif";
import { BuyBNB, BuyKYC, CheckPrice } from '../../Blockchain/MintBuySell';
import { BuySharedEvent } from '../../Blockchain/Events';
import { useWeb3React } from "@web3-react/core"
import { injected } from '../../Blockchain/Connector';
import * as actionType from '../../../constants/actionTypes';
import Snackalert from '../../Buttons/Snackalert';
import { getUser, getTransactions } from '../../../api/index.js';  
import MediaDisplay from '../MediaDisplay/MediaDisplay';




export default function NFTCard({ item, col }) {
    const {user, connecteduser, nftowner} = useSelector((state) => state.users);
    const { nft, nfts, isLoading } = useSelector((state) => state.nfts);
    const {connectData} = useSelector((state) => state.connect);
    
    const [loadingBNB, setLoadingBNB] = useState(false);
    const [loadingKYC, setLoadingKYC] = useState(false);
    const [kycprice, setKycprice] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch(); 
    const {library, activate} = useWeb3React();
    const userprofile= JSON.parse(localStorage.getItem('profile')); 
   
    const transactiondata = {contract:item.contract, tokenid:item.tokenid};
    const [userData, setUserData]= useState(undefined);
    const [transactionData, setTransactionData] = useState(undefined);
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = React.useState(false);
    const [likecount, setLikecount] = useState('');
   
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };

   

    const Likes = () => { 
        if(connectData){
            return connecteduser?.likes?.split(',').find((like) => like === item.id.toString()) 
            ? (
                    <><p style={{display: 'inline-flex'}}>
                    <i title='you have liked this item'><BiHeart /> </i>
                    {/* {item.likes} */}
                    {likecount? likecount : item?.likes}
                    </p>
                    </>
            ) : (
                !likecount?
                <><p style={{display: 'inline-flex'}}>
                    <i type='button' onClick={() => dispatch(likePost(item.id, setLikecount))}><BiHeart /> </i>
                    {/* {item.likes} */}
                    {likecount? likecount : item?.likes}
                    </p>
                    </>
                :
                <><p>
                  <i title='you have liked this item'><BiHeart /> </i>
                  {likecount? likecount : item?.likes}
                  </p>
                </>
            )
        }
        return  <><p style={{display: 'inline-flex'}}>
                    <i title='connect wallet to like item'><BiHeart/> </i>
                    {/* {item.likes} */}
                    {likecount? likecount : item?.likes}
                 </p></>;
    };

    const addDefaultSrc = (ev)=>{
          ev.target.src = '/images/logo/NoNFTimage.png'; 
    }

    const buyWithBNB = async(saledata)=>{
        setLoadingBNB(true);
        const nftinfo = {contract: saledata.contract, id: saledata.tokenid, mintprice: saledata.mintprice};
        await BuyBNB(nftinfo, library).then(async(res)=>{
            if(!res || res!=true){
                res.data? handleSnack(res.data.message, 'error') : handleSnack('Transaction unsuccessful', 'warning')
                setLoadingBNB(false)
            } else{
                const updatedata = {nftcontract: saledata.contract, nfttokenid: saledata.tokenid, nftmintprice: saledata.mintprice, nftstatus:0, newowner:userprofile };    
                const transactioninfo = {nftaddress: saledata.contract, nfttokenid: saledata.tokenid, txtype: 'BUY', nftmintprice: saledata.mintprice, nftamount: 1, seller: saledata.owner, buyer: userprofile, date: new Date()};
                await BuySharedEvent(nftinfo, library, updatedata, transactioninfo, setLoadingBNB, handleSnack, dispatch);

            }
              
        });
        
    }

    const buyWithKYC = async(saledata)=>{
        setLoadingKYC(true);
        const nftinfo = {contract: saledata.contract, id: saledata.tokenid, mintprice: saledata.mintprice};
        await BuyKYC(nftinfo, library).then(
            async (res)=>{
                if(!res || res!=true){
                    res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
                    setLoadingKYC(false);
                } else{
                    const updatedata = {nftcontract: saledata.contract, nfttokenid: saledata.tokenid, nftmintprice: saledata.mintprice, nftstatus:0, newowner:userprofile };   
                    const transactioninfo = {nftaddress: saledata.contract, nfttokenid: saledata.tokenid, txtype: 'BUY', nftmintprice: saledata.mintprice, nftamount: 1, seller: saledata.owner, buyer: userprofile, date: new Date()};
                    await BuySharedEvent(nftinfo, library, updatedata, transactioninfo, setLoadingKYC, handleSnack, dispatch);

                }
        });
    }

    const ConnecttoWallet = async()=> {
        try {
            await activate(injected)
            dispatch({ type: actionType.CONNECT, payload:'connected' });
        } catch (ex) {
            console.log(ex)
        }

    }
   
    const getKYCPrice = async(price)=>{
        if(price){
            let NFTprice;
            NFTprice = await CheckPrice(price, library);
            NFTprice = fNumber2decimal(NFTprice)
        
            setKycprice(NFTprice)
 
        }
    }
        

    // useEffect(() => {
    //    if (connectData) {
        
    //     dispatch(getConnectedUser(userprofile));
    //     getKYCPrice(item.mintprice)
    //    }
    // }, [item.mintprice, connectData]);

    useEffect(() => {
        if (connectData) {
         
         dispatch(getConnectedUser(userprofile));
        
        }
     }, [connectData]);

    useEffect(() => {
      async function getuserinformation (){
            if(item.creator.length>0){     
                const userinfo = await getUser(item.creator);
                setUserData(userinfo.data)
            }
            const transInfo = {contract:item.contract, tokenid:item.tokenid};
            const transData = await getTransactions(transInfo);
     
            setTransactionData(transData.data)
        }
        getuserinformation()
     }, [dispatch, item.creator]);


    return (
    <div className={col===4? 'col-lg-4 col-md-6' :col===3?'col-lg-3 col-md-6':'col-lg-12 col-md-12 p-1'} > 
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='featured-item featured-card box-shadow'>
            <div className='featured-item-img'>
              
                    <a href={`/detail/${item.contract}/${item.tokenid}`} className='featured-item-preview'>
                        <MediaDisplay item={item} drop={false} detail={false}/>
                    {/* <img className='feature-item-preview-img trim'
                       
                        src={getNFTImageSrc(item)}
                        alt={item.name} 
                        onError={addDefaultSrc}
                    /> */}
                    </a>
             
                <div className='featured-user'>
                   
                    <a href={`/user/${userData?.wallet_address}`} className='featured-user-option'>
                      
                        <img
                            src={getImageSrc(userData)}
                            alt={userData?.username} 
                            onError={addDefaultSrc}
                        />
                        <span>Created by {userData?.username?.split('').splice(0, 15).join('')}{userData?.username?.split('').length > 15 && '...' }</span>
                    </a>
                   
                </div>
              
            </div>

            <div className='content'>
                <h3>
                
                <a href={`/detail/${item.contract}/${item.tokenid}`}><span style={{overflow: 'hidden',WebkitLineClamp: 1, display: '-webkit-box',WebkitBoxOrient: 'vertical'}}>{item.name}</span></a>
                        
                </h3>
                
                {item.salestatus === 1 ? 
                  <div>
                    <div type='button' className='content-in' style={{pointerEvents: loadingBNB && 'none'}} onClick={()=>{connectData ? loadingBNB === false && buyWithBNB(item) : ConnecttoWallet()}}>
                        <span title={item.mintprice}>{fShortenNumber(item.mintprice)} {item.chain} </span>
                        
                        {loadingBNB ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData?<h4>BUY</h4>:<h4>Connect Wallet</h4>}
                    </div>
                    
                    {/* <Divider orientation="vertical" flexItem /> */}
                    {/* {item.chain==='BNB' &&
                    <div type='button' className='content-in default-btn' style={{pointerEvents: loadingKYC && 'none'}} onClick={()=>{connectData ? loadingKYC === false && buyWithKYC(item) : ConnecttoWallet()}}>
                        <span title={kycprice}>{fShortenNumber(kycprice)} KYC</span>
                       
                        {loadingKYC ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData? <h4>BUY</h4>:<h4>Connect Wallet</h4>}
                    </div>
                    } */}
                  </div>
                 : 
                  <div className='content-in default'>NFT not for sale. Make offer tool coming soon </div>}
                
                <div className='featured-content-list'>
                    <ul>
                    <li>
                        <img
                        src={Transactinicon}
                        alt='Images'
                        />
                    </li>
                
                   
                    <li className='title'>{transactionData ? (transactionData.length===1 ? ("1 Transaction") : (transactionData.length<100 ? transactionData.length+" Transactions": "100+ Transactions" )) : "No Transactions Yet"}</li>
                    </ul>
                    <Likes/>
                </div>
            </div>
        </div>
    </div>
       
       
    )

}