// import { styled } from '@mui/material/styles';
import styled from '@emotion/styled'

export default styled((theme) => ({
    appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    },
    heading: {
        color: 'rgba(0,183,255, 1)',
    },
    image: {
        marginLeft: '15px',
    },
    [theme.breakpoints.down('sm')]: {
        mainContainer: {
            flexDirection: "column-reverse"
        } 
    },
    container :{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100vh',
    }

}));