import * as api from '../api/index.js'  

export const nftstoragecall = (nftdata) => async(dispatch) => {
    try{
        const {data} = await api.storenft(nftdata);
           
        return data;

    }catch(error){
        console.log(error.message);
    }
}
