export const Numberval = (value) => {
    const string = value.toString();
    const number = Number(string);
    if (isNaN(number) || number==="") {
        return false;
    } else {
       return true;
    }
};
 
export const Urlval = (value) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    if (regex.test(value)) {
        return false;
    } else {
       return true;
    }
};

export const Integerval = (value) => {
    const string = value.toString();
    const number = Number(string);
    if (isNaN(number) || number==="") {
            return false;
    } else {
        if(Number.isInteger(number)){
            return true;
        }
        return false
    }
};

export const HttpsCheck = (value) => {
    const checkvalue = value.split('://')[0]
    if (checkvalue==='https' || checkvalue==="ipfs") {
            return true;
    } else {
        return false
    }
};

export const EmailCheck = (value) => {
    return /\S+@\S+\.\S+/.test(value);
}
