import React from 'react';
import { CreateNFTArea, CreateNFCollectioArea, CreateNFTCollectionAreaAdd } from '../containers';
import { useLocation } from 'react-router-dom';


const CreateNFT = () => {
   
    const pathname = useLocation().pathname.split('/')[2];
  
    return(
        <div>
            {pathname ==='collection' ? <CreateNFCollectioArea /> : pathname==='collection-add' ? <CreateNFTCollectionAreaAdd /> : <CreateNFTArea />}
            
        </div>
    );

}

export default CreateNFT;
