import React, { useState} from 'react';
import Headercomp from '../../components/Header/Headercomp'

const Header = ()=> {
    const [MobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    return (
        <Headercomp mobileMenu={{ MobileMenuIsOpen, setMobileMenuIsOpen }}/>
        
    )
};

export default Header;