import { DiscoverArea} from '../containers';


const Browse = () => {
  
    return(
        <div>
            <DiscoverArea />
        </div>
    );

}

export default Browse;
