import * as api from '../api/index.js' 
import {START_LOADING, END_LOADING } from '../constants/actionTypes.js' 


export const getNetworkBySymbol = (abrv) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.getNetworkBySymbol(abrv);
      dispatch({ type: END_LOADING });
      return data;
  
    } catch (error) {
      console.log(error);
    }
  };



