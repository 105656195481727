import React, {useState, useEffect} from 'react';
import { BiHeart } from "react-icons/bi";
import { useHistory } from 'react-router-dom';
import { Button, Box, Avatar, Typography, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { likePost, updateNFTpriceUsingContract } from '../../../actions/nfts';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';

import Loading from "../../../images/cardicons/loading.gif";
import { CheckForSale, MarketWithdrawal, SellNFT, CheckNFTPrice, CheckPrice } from '../../Blockchain/MintBuySell';
import { PriceUpdateStatusEvent, MarketWithdrawalEvent } from '../../Blockchain/Events';
import { useWeb3React } from "@web3-react/core"
import { injected } from '../../Blockchain/Connector';
import * as actionType from '../../../constants/actionTypes';

import binaincecurrency from '../../../images/Binance_currency.svg';
import eth from '../../../images/eth.png';
import polygon from '../../../images/polygon.png';
import { Numberval } from '../../../utils/validate';
import { getImageSrc, getNFTImageSrc } from '../../../utils/formatImage';
import { fShortenNumber } from '../../../utils/formatNumber';
import { getUser } from '../../../api/index.js';
import Snackalert from '../../Buttons/Snackalert';
import MediaDisplay from '../MediaDisplay/MediaDisplay';



export default function MyNFTCard({item}) {
    const {user, connecteduser} = useSelector((state) => state.users);
    const {connectData} = useSelector((state) => state.connect);
    const { transaction } = useSelector((state) => state.transactions);
    const dispatch = useDispatch(); 
    const userprofile= JSON.parse(localStorage.getItem('profile'))
    const {library, chainId, activate} = useWeb3React();
    const [checksale, setChecksale] = useState();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [disableForm, setDisableForm] = useState(true);
    const [values, setValues] = React.useState({
        amount: ''
    });
    const [shownftprice, setShownftprice] = useState('')
    const [userData, setUserData]= useState(undefined);
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = useState(false);
    const [likecount, setLikecount] = useState('');
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };
    const history = useHistory();

    const handleShow = () => setShow(true);


    const Likes = () => { 
        if(connectData){
            return connecteduser?.likes?.split(',').find((like) => like === item.id.toString()) 
            ? (
                    <><p>
                    <i title='you have liked this item'><BiHeart /> </i>
                    {/* {item.likes} */}
                    {likecount? likecount : item?.likes}
                    </p>
                    </>
            ) : (
                !likecount?
                <><p>
                    <i type='button' onClick={() => dispatch(likePost(item.id, setLikecount))}><BiHeart /> </i>
                    {/* {item.likes} */}
                    {likecount? likecount : item?.likes}
                    </p>
                    </>
                :
                <><p>
                  <i title='you have liked this item'><BiHeart /> </i>
                  {likecount? likecount : item?.likes}
                  </p>
                </>
            )
        }
        return  <><p>
                    <i title='connect wallet to like item'><BiHeart/> </i>
                    {/* {item.likes} */}
                    {likecount? likecount : item?.likes}
                 </p></>;
    };

    const addDefaultSrc = (ev)=>{
        ev.target.src = '/images/logo/NoNFTimage.png'
    }

    const checkForSale = async(id, contract) => {
        setLoading(true)
        const listedforsale = await CheckForSale(id, contract, library);
        const nftprice = await CheckNFTPrice(id, contract, library);
        setChecksale(listedforsale);
        if(typeof nftprice==='string'){
            const nftpriceupdate = {price: nftprice, contract: item.contract, tokenid: item.tokenid, chain: item.chain};
            parseFloat(item.mintprice) !==nftprice && await dispatch(updateNFTpriceUsingContract(nftpriceupdate));  
            setShownftprice(nftprice)
        }  else {
            setShownftprice(item.mintprice)
        }           
        setLoading(false)
    }

    const marketWithdrawal = async () => {
        setLoading(true)
   
            await MarketWithdrawal(item.tokenid, item.contract, library).then(async(res)=>{
              
                if(!res || res!==true){
                    res.data? handleSnack(res.data.message, 'error') : handleSnack('Transaction unsuccessful', 'warning')
                    setLoading(false)
                } else{
                    const statusupdate = {status: 0, contract: item.contract, tokenid: item.tokenid};
                   
                    await MarketWithdrawalEvent(statusupdate, library, userprofile, setLoading, handleSnack, dispatch);

                }
                
            }
           );
           
       
    }

    const sellMyNFT = async(price) => {
        setLoading(true);
        try{
            await SellNFT(item.tokenid, item.contract, price, library).then(async(res)=>{
                if(!res || res!==true){
                    res.data? handleSnack(res.data.message, 'error') : handleSnack('Transaction unsuccessful', 'warning')
                    setLoading(false)
                } else{
                    
                    const priceupdate = {price: price, status: 1, contract: item.contract, tokenid: item.tokenid};
        
                    await PriceUpdateStatusEvent(priceupdate, library, userprofile, setLoading, handleSnack, setValues, setOpen, dispatch);

                }
                
            }
           );


         
        }catch(err){
            console.log(err)
            err.message? handleSnack(err.message, 'error') : handleSnack('Transaction unsuccessful', 'warning')
        }

    }

    const ConnecttoWallet = async()=> {
        try {
            await activate(injected)
            dispatch({ type: actionType.CONNECT, payload:'connected' });
        } catch (ex) {
        console.log(ex)
        }

    }


    const handleChange = (prop) => (event) => {
        setValues({ [prop]: event.target.value });
        if(event.target.value.length!==0 && Numberval(event.target.value)) {setDisableForm(false)} else {setDisableForm(true)}
    };

    const handleClickModal = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const getCurrencyIcon = (chain)=>{
        switch(chain){
            case 'BNB':
                return binaincecurrency;
            case 'ETH':
                return eth;
            case 'MATIC':
                return polygon;
            default :
                return binaincecurrency;
        }
    }

    const getSymbolByChainID = (chainid)=>{
        switch(chainid){
            case 97:
                return "BNB";
            case 5: 
                return "ETH";
            case 80001:
                return "MATIC";
            default:
                return "BNB";
        }
    }

    const getChainIdFromSymbol = (network)=>{
        switch(network){
            case "BNB":
               return 97;
            case "ETH": 
                return 5;
            case "MATIC":
                return 80001;
        }
    }
   
    const switchNetwork = async(network)=>{
        let chainnetworkid = getChainIdFromSymbol(network)
        try {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x'+parseInt(chainnetworkid).toString(16).toUpperCase()}],
            });
            chainnetworkid='';
        //    console.log("You have succefully switched network")
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
             console.log("This network is not available in your metamask, please add it")
            }
            console.log("Failed to switch to the network")
        }
         window.location.reload(false);

    }
    
    
    useEffect(()=>{
        /////// chanId of 56 is for BNB smart chain.  chainId  of 97 is for BNB testnet.    chainId  of 5777 is for local ganache 
        // if(chainId===97 && item.chain==="BNB"){
            checkForSale(item.tokenid, item.contract);
        //  }
        //  if(chainId===5777 && item.chain==="ETHL" ){
        //     checkForSale(item.tokenid, item.contract);
        //  }
    },[item]);

    useEffect(() => {
        async function setUserinfo(){
         if(item.creator.length>0){     
            const userinfo = await getUser(item.creator);
            setUserData(userinfo.data)
          }
        }
        setUserinfo()
     }, [dispatch, item]);

    

    return (
    <div className='col-lg-4 col-md-6'>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='featured-item featured-card box-shadow'>
            <div className='featured-item-img'>
                    <a href={`/detail/${item.contract}/${item.tokenid}`} className='featured-item-preview'>
                        <MediaDisplay item={item} drop={false} detail={false}/>
                    {/* <img className='feature-item-preview-img trim'
                        src={getNFTImageSrc(item)}
                        alt={item.name} 
                        onError={addDefaultSrc}
                    /> */}
                    </a>
               
                <div className='featured-user'>
                    <a href={`/user/${userData?.wallet_address}`} className='featured-user-option'>
                        <img
                            src={getImageSrc(userData)}
                            alt={userData?.username} 
                            onError={addDefaultSrc}
                        />
                        <span>Created by {userData?.username.split('').splice(0, 15).join('')}{userData?.username.split('').length > 15 && '...' }</span>
                    </a>
                
                </div>
             
            </div>
    
            <div className='content'>
                <h3>
                    <a href={`/detail/${item.contract}/${item.tokenid}`} style={{overflow: 'hidden',WebkitLineClamp: 1, display: '-webkit-box',WebkitBoxOrient: 'vertical'}}>
                        {item.name}
                    </a>
                    
                </h3>
               
                {getSymbolByChainID(chainId)===item.chain? checksale === true ? (
                    <div type='button' className='content-in default-btn' style={{pointerEvents: loading && 'none'}} onClick={()=>{connectData ? loading === false && marketWithdrawal() : ConnecttoWallet()}}>
                        <span>{fShortenNumber(shownftprice)} {item.chain}&nbsp; </span>
                        
                        {loading ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData?<h4> Withdraw From Marketplace</h4>:<h4>Connect Wallet</h4>}
                    </div>
                ) : (
                     <div type='button' className='content-in' style={{pointerEvents: loading && 'none'}} onClick={()=>{connectData ? loading === false && handleClickModal() : ConnecttoWallet()}}> 
                        {/* {connectData?<span>{getKYCPrice(item?.mintprice)} KYC</span> : <h4>Connect Wallet</h4>} */}
                        {connectData?<span>{fShortenNumber(shownftprice)} {item.chain}&nbsp;</span> : <h4>Connect Wallet</h4>}
                        {loading ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData? <h4>Sell On Marketplace</h4>:<h4>Connect Wallet</h4>}
                    </div>
                    
                                  
                )
                :
                (
                    <div type='button' className='content-in btn-success' style={{pointerEvents: loading && 'none'}} onClick={()=>{connectData ? loading === false && switchNetwork(item.chain) : ConnecttoWallet()}}>
                        
                        {loading ? <i className='loading-gif'><img src={Loading} alt="loading..." /> </i> : connectData?<h4> Switch wallet network to {item.chain}</h4>:<h4>Connect Wallet</h4>}
                    </div>
                )
                }
                <div className='featured-content-list'>
                    <ul>
                    </ul>
                    <Likes/>

                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Set price of {item.name}</DialogTitle>
                            <DialogContent>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Avatar
                                    alt={item.chain+' Currency'}
                                    src={getCurrencyIcon(item.chain)}
                                    sx={{ width: 24, height: 24 }}
                                    />
                                    <TextField id="input-with-sx" label="set your price in BNB here" variant="standard" value={values.amount} onChange={handleChange('amount')}/>
                                    <Typography variant="subtitle2">{item.chain}</Typography>
                                </Box>
                            
                            </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button disabled={disableForm} onClick={()=>{sellMyNFT(values.amount)}}>Sell</Button>
                        </DialogActions>
                    </Dialog>
                
                </div>
            </div>
        </div>
    </div>
       
       
    )

}