import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch } from 'react-redux';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Box, Typography, Switch, FormControlLabel, FormHelperText } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Urlval, HttpsCheck } from '../../../utils/validate'
import { fShortenNumber } from '../../../utils/formatNumber'
import Resizer from 'react-image-file-resizer';
import { createUser, getConnectedUser, uploadUserImage } from '../../../actions/users';
import { rProfileImagef } from '../../../utils/formatImage';
import { getUser } from '../../../actions/users';



const CreateProfileCard = () => {
    const {connecteduser} = useSelector((state) => state.users);
    //console.log(connecteduser)
    const userprofile= JSON.parse(localStorage.getItem('profile')); 
    const [disableForm, setDisableForm] = useState(true);
    const [values, setValues] = useState({
        facebookhandle:'', instahandle:'', twithandle:'', profiledesc:'', profileusername:'', profilepic:'', profilepicfile:'', profileacc:userprofile
    });
    const [checked, setChecked] = useState(true);
    const [label, setLabel] = useState('Add Profile Picture Link');
    const [selectedfile, setSelectedfile] = useState('');
    const [imageURL, setImageURL] = useState('');
    const [httpscheck, setHttpscheck] = useState({
        facebookhandle:'', instahandle:'', twithandle:''});
    const ref = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    
    

    const addDefaultSrc = (ev)=>{
        ev.target.src = '/images/logo/NoNFTimage.png'
     }

    // const getImageSrc = (acc)=>{
    //     if(acc){
    //     const checkhttp = acc.thumbnail?.split('://')[0]
    //     const checkext = acc.thumbnail?.split('.')[1]
    //     if(checkhttp ==="https"){
    //         return acc
    //     }else if (checkhttp==="ipfs"){
    //         return `https://ipfs.infura.io/ipfs/${acc.thumbnail?.split('//')[1]}`
    //     }else if (checkext ==="png"){
    //         return `../images/collection/${acc?.name}/${acc.thumbnail}`
    //     }else{
    //         return 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'
    //     }
    //     }else{
    //     return 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'
    //     }
        
    // }
    
    const clear = ()=>{
        setValues({
            facebookhandle:'', instahandle:'', twithandle:'', profiledesc:'', profileusername:'', profilepic:'', profilepicfile:'', profileacc:userprofile
        })
        setDisableForm(true);
        setSelectedfile('');
        setImageURL('');

    }

    const createProfile = async (e)=>{
        e.preventDefault();
        if(userprofile){
            if(selectedfile){
                if(selectedfile.type==='image/png' || selectedfile.type==='image/jpeg' || selectedfile.type==='image/jpg' || selectedfile.type==='image/gif'){
                    if(selectedfile.size<500000){
                        let imageresized;
                        if(selectedfile.type==='image/gif'){
                            imageresized = selectedfile
                        }else{
                            imageresized = await rProfileImagef(selectedfile); 
                        } 
                    
                        const fd = new FormData();
                        fd.append('files', imageresized);
                        fd.append('acc', values.profileacc);
                        dispatch(uploadUserImage(fd))
                    } 
                }
            }
            dispatch(createUser(values));
            clear();
            history.push('/create');
        }else{
            history.push('/mynfts');
        }
    }

    const handleChange = (prop) => (event) => {
            if(prop==='twithandle' || prop==='facebookhandle'|| prop==='instahandle' || prop==='profilepic'){
                if(HttpsCheck(event.target.value)===true){
                    setValues({...values, [prop]: event.target.value})
                    val(prop, event.target.value);
                    if(prop==='twithandle' || prop==='facebookhandle'|| prop==='instahandle'){
                        setHttpscheck({...httpscheck, [prop]:'ok'})
                    }
                }else{
                    setValues({...values, [prop]: ''})
                    val(prop, '');
                    if(prop==='twithandle' || prop==='facebookhandle'|| prop==='instahandle'){
                        if(event.target.value===''){
                            setHttpscheck({...httpscheck, [prop]:''})
                        }
                        else{ 
                            setHttpscheck({...httpscheck, [prop]:'no'})
                            }
                    }
                }
            }else{
                setValues({...values, [prop]: event.target.value})
                val(prop, event.target.value);
            }
    };

    const val = (prop, input)=>{
        const name = prop ==='profileusername'? input : values.profileusername;
        const descript = prop ==='profiledesc'? input : values.profiledesc;
        const picfile = prop ==='profilepicfile'? input : values.profilepicfile;
        const piclink = prop ==='profilepic'? input : values.profilepic;
        if(input){
            if (name.length!=0 && descript.length!=0 && piclink.length!=0 || name.length!=0 && descript.length!=0 && picfile.length!=0 ){
                setDisableForm(false)
            }else {
                setDisableForm(true)
            }
        }else{
            setDisableForm(true)
        }
    }

    //const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) =>{
    const handleToggle = (event) =>{
        setChecked(event.target.checked);
        if(event.target.checked ===true){
            setLabel('Add Profile Picture Link');
            ref.current.value = ""
            setSelectedfile('')
            setValues({...values, profilepicfile: ''});
            setImageURL('')
            setprofilepic('')
            val('profilepicfile','')
        }else{
            setLabel('Upload Profile Picture');
            setValues({...values, profilepic: ''})
        }
    }

    const setprofilepic = (name)=>{
        if(name){
            const fileext = String(name.split('.')[1]);
            if(fileext != 'gif'){
            setValues({...values, profilepicfile: `profilepic.png`});
            }else {
            setValues({...values, profilepicfile: `profilepic.${fileext}`});
            }
        }else{
            setValues({...values, profilepicfile: ''});
        }
    } 

    const previewPic = (picfile)=>{
        const previewurl = URL.createObjectURL(picfile)
        setImageURL(previewurl);
    }

    const redirectcreatenft = ()=>{
        history.push(`/create`);
      }

    useEffect(() => {
       //if (connectData) {
        dispatch(getUser(userprofile));
      
       //}
    }, [userprofile]);

    connecteduser&&redirectcreatenft()
    
    // if(connecteduser){
      
    //     return(
    //         <div className='collection-form-area'>
    //             <div className='section-title'>
    //                 <h2>Account already exists</h2>
    //                {redirectcreatenft}
    //             </div>
    //         </div>
    //     // //console.log("gghhh")
    //     // //setInterval(history.push('/'),1000)
    //     )
    // }else {

        return (
            <>
                <div className='collection-form-area'>
                    <div className='section-title'>
                        <h2>Create A Profile</h2>
                    </div>
                    <div className='collection-form'>
                        <form>
                            <div className='row'>
                                
                                <h3>Choose to Upload Picture or add profile picture link *</h3>
                                <Box sx={{marginTop:3, marginBottom:3}}>
                                    <FormControlLabel control={<Switch checked={checked} onChange={handleToggle} inputProps={{ 'aria-label': 'controlled' }} color='error'/>} label={label}/>
                                </Box>
                                
                                <div className='profile-outer' hidden={checked}>
                                    <h3>Upload Profile Image (500KB Max File Size)*</h3>
                                    <div className='profileButton'>
                                        <input
                                        className='profileButton-input'
                                        type='file'
                                        name='attachments[]'
                                        accept='image/gif, image/png, image/jpg, image/jpeg' 
                                        title=".gif, .png, .jpg, .jpeg only. 540px X 540px picture dimension. 500KB Maximum file size"
                                        id='upload'
                                        onChange={(e)=>{setSelectedfile(e.target.files[0]); setprofilepic(e.target.files[0].name); val('profilepicfile',e.target.files[0].name); previewPic(e.target.files[0]) }}
                                        ref={ref}
                                        />
                                        <label
                                        className='profileButton-button ripple-effect'
                                        htmlFor='upload'
                                        >
                                        {values.profilepicfile ? values.profilepicfile : 'e. g. png, jpg, jpeg, gif'}
                                        </label>
                                        <span className='profileButton-file-name'></span>
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.profilepicfile.length>0?true:false}>Upload File</FormHelperText>
                                    </div>
                                    
                                </div>

                                <div className='preview-box' hidden={imageURL.length>0?false:true}>
                                    <h3>Preview</h3>

                                    <div className='previewButton'>
                                        <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                                            <img src={imageURL} className="img-thumbnail col-3"/>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-12' hidden={checked===true ? false : true}>
                                    <div className='form-group'>
                                        <label>Profile Picture Link *</label>
                                        <input
                                        type='text'
                                        name='piclink'
                                        id='piclink'
                                        className='form-control'
                                    // value = {values.profilepic} 
                                        onChange={handleChange('profilepic')}
                                        // onInput={val}
                                        placeholder='e. g. “https://” or "ipfs://"'
                                        />
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.profilepic.length>0?true:false}>Enter profile picture link. Make sure it starts with https:// or ipfs://</FormHelperText>
                                    </div>
                                    
                                    
                                </div>

                                <div className='col-lg-12'>
                                    <div className='form-group'>
                                        <label>Profile Name *</label>
                                        <input
                                        type='text'
                                        name='name'
                                        id='name'
                                        className='form-control'
                                        value = {values.profileusername} 
                                        onChange={handleChange('profileusername')}
                                        // onInput={val}
                                        placeholder='e. g. “walking in the air”'
                                        />
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.profileusername.length>0?true:false}>Enter profile name</FormHelperText>
                                    </div>
                                    
                                </div>

                                <div className='col-lg-12 col-md-12'>
                                    <div className='form-group'>
                                        <label>Profile Description *</label>
                                        <textarea
                                        name='description'
                                        className='form-control'
                                        id='description'
                                        cols='30'
                                        rows='5'
                                        value={values.profiledesc} 
                                        onChange={handleChange('profiledesc')}
                                        // onFocus={val}
                                        placeholder='This description goes on your profile page'
                                        ></textarea>
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.profiledesc.length>0?true:false}>Enter description</FormHelperText>
                                    </div>
                                    
                                </div>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <label>Facebook</label>
                                        <input
                                        type='text'
                                        className='form-control'
                                        placeholder='e.g. "https://" or "ipfs://"'
                                        // onChange={(e)=>{Urlval(e.target)}}
                                        onChange={handleChange('facebookhandle')}
                                    // value = {values.facebookhandle}
                                        />
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={httpscheck.facebookhandle==='ok'||httpscheck.facebookhandle===''?true:false}>link must start with https:// or ipfs://</FormHelperText>
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <label>Twitter</label>
                                        <input
                                        type='text'
                                        className='form-control'
                                        placeholder='e.g. "https://" or "ipfs://"'
                                        onChange={handleChange('twithandle')}
                                        />
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={httpscheck.twithandle==='ok'||httpscheck.twithandle===''?true:false}>link must start with https:// or ipfs://</FormHelperText>
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <label>Instagram</label>
                                        <input
                                        type='text'
                                        className='form-control'
                                        placeholder='e.g. "https://" or "ipfs://"'
                                        onChange={handleChange('instahandle')}
                                        />
                                        <FormHelperText sx={{color:'red'}} className='danger' hidden={httpscheck.instahandle==='ok'||httpscheck.instahandle===''?true:false}>link must start with https:// or ipfs://</FormHelperText>
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <button
                                        type='submit'
                                        className='default-btn border-radius-5'
                                        disabled={disableForm}
                                        onClick={(e)=>{createProfile(e)}}
                                    >
                                        Create Profile
                                    </button>
                                </div>

                            </div>
                        </form>



                    </div>
                </div>
            </>
        );
//}
};

export default CreateProfileCard;
