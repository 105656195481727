export const Currencies = 'ETH, BNB and MATIC';

export const SiteType = 'Testnet';

export const SiteName = 'NFTHev';

export const ApprovedFileTypes = '.PNG, .JPG, .GIF, .MP4, .WebM, .MP3, .WAV, .OGG';

export const SitePolicyEmail = 'policy@nfthev.io'








