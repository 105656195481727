import { LIKE, FETCH_ALL, FETCH_BY_SEARCH, FETCH_POST, FETCH_BY_CATEGORY, FETCH_BY_FEATURES, FETCH_CATS, FETCH_NFTS, UPLOADED_FILES, FETCH_NFTDROPS, FETCH_DROP_BY_CATEGORY, FETCH_DROP_BY_SEARCH, FETCH_DROP, FETCH_BY_BLOCKCHAIN, FETCH_DROP_BY_BLOCKCHAIN } from '../constants/actionTypes';

const nfts = (state = { isLoading: true, nfts: [], nftcontracts:[] }, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return { ...state, isLoading: true };
    case 'END_LOADING':
      return { ...state, isLoading: false };
   
    
    case LIKE:
      return { ...state, nfts:action.payload.data };
    
    case FETCH_ALL:
      return {
        ...state,
        nfts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };

    case FETCH_BY_SEARCH:
      return {
        ...state,
          nfts: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
      };
    
    case FETCH_BY_CATEGORY:
      return {
        ...state,
          nfts: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
      };

    case FETCH_BY_FEATURES:
      return {
        ...state,
          nfts: action.payload.data,
      };
      
    case FETCH_BY_BLOCKCHAIN:
      return {
        ...state,
        nfts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };

    case FETCH_NFTS:
      return {
        ...state,
          nftcontracts: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
      };
      
    case FETCH_POST:
      return { ...state, nft: action.payload.nft };
    
    case FETCH_CATS:
       return { ...state, cats: action.payload.data };
    
    case UPLOADED_FILES:
      return {...state, uploadedFiles: action.payload.data}

    case FETCH_NFTDROPS:
      return {
        ...state,
        nftdrops: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
    case FETCH_DROP_BY_CATEGORY:
      return {
        ...state,
          nftdrops: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
      };
    case FETCH_DROP_BY_SEARCH:
      return {
        ...state,
          nftdrops: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
      };
    
    case FETCH_DROP_BY_BLOCKCHAIN:
      return {
        ...state,
          nftdrops: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
      };

    case FETCH_DROP:
      return { ...state, nftdrop: action.payload.nftdrop };

    default:
      return state;
  }
};

export default nfts;