import React from 'react';
import {SiteName } from '../../../utils/config';
import imageone from '../../../images/about/about-img1.jpg';
import imagetwo from '../../../images/about/about-img2.jpg';

const AboutArea = () => {
  return (
    <>
      <div className='about-area pt-70 pb-70'>
        <div className='container'>
          <div className='about-content'>
            <div className='section-title text-center'>
              <h2>About Our Company</h2>
            </div>
            <div>
              <p>
                Welcome to {SiteName} NFT Marketplace, the premier platform for creating, buying and selling non-fungible tokens (NFT's) across multiple blockchains. 
                We are a team of blockchain enthusiasts and digital art lovers who are passionate about the potential of NFTs to revolutionize the way that digital assets are bought and sold. 
                
              </p>
              <p>
                Our marketplace offers a wide variety of NFTs, including art, music, videos, and other forms of media. We work with a diverse group of creators and artists to bring you the best selection of NFTs available.
                
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6 col-sm-6'>
              <div className='about-img'>
                <img src={imageone} alt='Images' />
              </div>
            </div>

            <div className='col-lg-6 col-sm-6'>
              <div className='about-img'>
                <img src={imagetwo} alt='Images' />
              </div>
            </div>
          </div>

          <div className='about-bottom-content'>
            <p>
            Our platform is a web3 website built on top of the several blockchains including but not limited to Ethereum (ETH), Polygon (MATIC) and Binance Smart Chain (BSC). These blockchains provide a secure and transparent way to record transactions and store data. This ensures that all NFTs bought and sold on our marketplace are one-of-a-kind and cannot be replicated or exchanged for something else.
            </p>
            <p>
            We understand that buying and selling NFTs can be overwhelming, which is why we have made our platform user-friendly and easy to navigate. Whether you are a seasoned collector or just getting started with NFTs, our platform is designed to make the buying and selling experience simple and enjoyable.
            </p>
            <p>
            In addition to buying and selling NFTs, we also offer a variety of other services, such as NFT storage via the Interplanetary File System (IPFS), curation, and promotion. We believe that the value of NFTs lies not only in their ownership, but also in the ability to showcase them and share them with others.
            </p>
            <p>
            We are constantly working to improve our platform and bring you new features and services. If you have any questions or feedback, please don't hesitate to reach out to us. We are always happy to hear from our users and are dedicated to providing the best possible experience.
            </p>
            <p>
            Thank you for choosing {SiteName} as your go-to destination for NFTs. We look forward to helping you discover and enjoy the world of non-fungible tokens.
            </p>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutArea;
