import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem } from '@mui/material';

import { Link, useLocation } from 'react-router-dom';

import { getPosts, getCats, getPostsByCategory, getPostsBySearch, getNFTdrops, getNFTdropsByCategory, getNFTdropsBySearch, getPostsByBlockchain, getNFTdropsByBlockchain } from '../../../actions/nfts';
import { getAllCollectionBySearch, getAllCollection } from '../../../actions/collections';

const PaginationCollection = ({ page, sort, category, searchQuery, blockchain }) => {
  const { numberOfPages } = useSelector((state) => state.nfts);
  const dispatch = useDispatch();
  const pathname = useLocation().pathname.split('/')[2];


  const pagelink = (itempage)=> {
    if(searchQuery){ 
        return (pathname ==='nftdrop' ? `/browse/nftdrop?searchQuery=${searchQuery}&page=${itempage}&sort=${sort}` : pathname ==='collections' ? '' : `/browse?searchQuery=${searchQuery}&page=${itempage}&sort=${sort}`)
     }else if(category){ 
       return (pathname ==='nftdrop' ? `/browse/nftdrop?page=${itempage}&sort=${sort}&category=${category}` : `/browse?page=${itempage}&sort=${sort}&category=${category}`) 
     }else if (blockchain){
      return (pathname ==='nftdrop' ? `/browse/nftdrop?page=${itempage}&sort=${sort}&blockchain=${blockchain}` : `/browse?page=${itempage}&sort=${sort}&blockchain=${blockchain}`) 
     }else {
       return (pathname ==='nftdrop' ? `/browse/nftdrop?page=${itempage}&sort=${sort}` : pathname ==='collections' ? '' : `/browse?page=${itempage}&sort=${sort}`)
     }
  }

  useEffect(() => {
    if(searchQuery){
     
      if(pathname ==='nftdrop'){
        dispatch(getNFTdropsBySearch({ searchQuery, page, sort}));
        dispatch(getCats());
      } else if(pathname =='collections'){
        dispatch(getAllCollectionBySearch({ searchQuery, page, sort}));
      }
      else {
        dispatch(getPostsBySearch({ searchQuery, page, sort}));
        dispatch(getCats());
      }
      // dispatch(getCats());
    }else if(category){
     
        if(pathname ==='nftdrop'){
          dispatch(getNFTdropsByCategory({ category, page, sort }));
        }else{
          dispatch(getPostsByCategory({ category, page, sort }));
          
        }
        dispatch(getCats());
    }else if(blockchain){
      
      if(pathname ==='nftdrop'){
        dispatch(getNFTdropsByBlockchain({ blockchain, page, sort }));
      }else{
        dispatch(getPostsByBlockchain({ blockchain, page, sort }));
        
      }
      dispatch(getCats());
  }else {
      if (page || sort) {
        if(pathname ==='nftdrop'){
          dispatch(getNFTdrops(page, sort));
          dispatch(getCats());
        }else if(pathname ==='collections'){
          dispatch(getAllCollection({page, sort}));
        }
        else {
          dispatch(getPosts(page, sort));
          dispatch(getCats());
        }
       
      }
    }
  }, [dispatch, page, sort, category, searchQuery, blockchain, pathname]);

  return (
    <Pagination
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
       
        <PaginationItem {...item} component={Link} to={()=>pagelink(item.page)} />
      )}
    />
  );
};

export default PaginationCollection;