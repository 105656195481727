import { FETCH } from '../constants/actionTypes';

const lptokens = (lptokens = [], action) => {

    switch (action.type){
        case FETCH:

           return action.payload;

        default:

            return lptokens;
    }
}

export default lptokens;
