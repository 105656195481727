import React from 'react';
import styled from "styled-components";
import { Colors, Devices } from "../Theme/KittyNftTheme/Theme";

export const FooterEl = styled.footer`
  background-color: #e5dbf6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: ${Colors.Red};
  padding: 1rem 2rem;
	margin-top: auto;
`;

export const Socials = styled.div`
  display: flex;
  font-size: 1.5rem;
  gap: 1.5rem;
`;
export const CopyRight = styled.small`
  font-size: 0.9rem;
`;
export const Links = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  @media ${Devices.Tablet} {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;
export const Ahref = styled.a`
  color: ${Colors.Red};
`