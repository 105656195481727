import React, {useState, useEffect} from 'react';
import { getCollectionImageSrc } from '../../../utils/formatImage';



export default function NFTTopCollection ({ item, creator, col }){
    const addDefaultSrc = (ev)=>{
        ev.target.src = '/images/logo/NoNFTimage.png'
    }

    return (
        <div className={col===4? 'col-lg-4 col-6' :col===3?'col-lg-3 col-6':'col-lg-12 col-md-12 p-1'}>
              <div className='collections-card'>  
                    <a href={`/collection/${item?.url_name}`}>
                        <img className='trim'
                        src={getCollectionImageSrc(item, false)}
                        alt={item?.name} 
                        onError={addDefaultSrc}
                        />
                    </a>
                 
                <div className='content'>
                  <div className='collections-user'>
                    <img className='collectionimgresize'
                      src={getCollectionImageSrc(item, true)}
                      alt={item?.name} 
                      onError={addDefaultSrc}
                    />
                  </div>
                  <h3>               
                      <a href={`/collection/${item?.url_name}`}>{item.name}</a>    
                  </h3>
                  
                </div>
              </div>
            </div>
    )

}