import React from 'react';
import {Currencies, SiteName} from '../../../utils/config'

const HelpCenterArea = () => {
  return (
    <>
      <div className='help-center-area pt-70 pb-70'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Getting Started</h3>
                <p>
                  To get started, install <a href="https://metamask.io/download/" target="_blank" rel="noreferrer">MetaMask</a> browser wallet on your browser.
                  We recommend using MetaMask for best result. Make sure you have either {Currencies} in your wallet. 
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Buying</h3>
                <p>
                  To buy an NFT asset, navigate to the page of the NFT, connect your wallet and click the buy button. The buyer's wallet must have either {Currencies} to make a purchase. 
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Selling</h3>
                <p>
                  Sellers must be preapproved to create/mint NFTs. To become approved, connect wallet, select setting from the drop down menu. Click "get approved" and follow the instructions. 
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Creating/Minting</h3>
                <p>
                  Creating NFT is free. Click the <a href='/create'>Create</a> button on the top right corner of the screen.
                  Then click "Create an NFT", fill out the form, upload NFT file and click the create button.  
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>Creating/Minting NFT Collections</h3>
                <p>
                  NFT collection creation is free. Click the <a href='/create'>Create</a> button on the top right corner.
                  Then click "Create an NFT Collection", fill out the form, upload NFT files and click the create button.  
                </p>
              </div>
            </div>


            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>NFT Drops</h3>
                <p>
                  This give non-NFT creators the ability to mint NFTs and become the first to own said NFTs versus buying an already minted NFT from a seller.  
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>FAQ</h3>
                <p>
                  Use the frequently asked questions section below to get more information on how to use {SiteName}. Scan through to the get your questions answered.
                </p>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='help-center-card'>
                <h3>User Safety</h3>
                <p>
                  This is a web3 website where users interact with their web3 wallet. NFT Minting and purchases are done on the blockchain and controlled by smart contracts. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpCenterArea;
