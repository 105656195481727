import React, { useEffect } from 'react';
import { Collection } from '../containers';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionByUrl } from '../actions/collections';
import PageBanner from '../components/Cards/PageBanner/PageBanner';
import { useLocation } from 'react-router-dom';
import { getOwner } from '../actions/users'


const Collectionpage = () => {
    const dispatch = useDispatch();
    const pathname = useLocation().pathname.split('/')[2];
    const {collection} = useSelector((state) => state.collections);
    useEffect(() => {
        dispatch(getCollectionByUrl(pathname));
    }, [dispatch, pathname]);

    useEffect(()=>{
        dispatch(getOwner(collection?.owner)) 
    }, [dispatch, collection])

    
   
    return(
        <div>
            <PageBanner/>
            <Collection/>
        </div>
    ); 

}

export default Collectionpage;
