import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Box, OutlinedInput, MenuItem, Select, Chip, FormHelperText, FormControl} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NFTStorage, File } from 'nft.storage'
import { useWeb3React } from "@web3-react/core"
import { Integerval, Numberval } from '../../../utils/validate'
import { getCats, mintNFT } from '../../../actions/nfts';
import { getNetworkBySymbol } from '../../../actions/network';
import { getCollectionByUrlBol, addCollection, uploadCollectionFile, getOwnerCollection } from '../../../actions/collections'; 
import { MintNFTShared } from '../../Blockchain/MintBuySell';
import { MintSharedCollectionEvent } from '../../Blockchain/Events';
import { Fees, Blockchains } from '../../../utils/selectOptions'
import {rProfileImagef, rBannerImagef } from '../../../utils/formatImage'

import Loading from "../../../images/cardicons/loading.gif";
import Snackalert from '../../Buttons/Snackalert';
import { GiConsoleController } from 'react-icons/gi';
import { breakpoints } from '@mui/system';
import {nftstoragecall} from '../../../actions/apicalls';
import axios from 'axios';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, category, theme) {
  return {
    fontWeight:
      category.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const CreateNFTCollectionCardAdd = () => {
    const [category, setCategory] = useState([]);
    const [collectionname, setCollectionname] = useState([]);
    const { cats } = useSelector((state) => state.nfts);
    const { ownerscollections } = useSelector((state) => state.collections);
    const [values, setValues] = useState({
        nftname:'', nfturlname:'', nftcat:'', nftfiles:'', nftproperties:'', nftnetwork:'', nftcontract:''
    });
    const [displayname, setDisplayname] = useState('');
    const [network, setNetwork] = useState('');
    const [displayProfileFile, setDisplayProfileFile] = useState('');
    const [disableForm, setDisableForm] = useState(true);
    const [imageURLs, setImageURLs] = useState([]);

    const [formaterror, setFormaterror] = useState('');
    const [persedJSON, setPersedJSON] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [snackmess, setSnackmess] = useState(undefined); 
    const [severity, setSeverity] = useState('success');
    const [opensnack, setOpensnack] = useState(false);
    const [dowhilecounter, setDowhilecounter]=useState(0);
    const [collectionid, setCollectionid] = useState();
    const handleSnack = (mess, severity) => {
        setOpensnack(true);
        setSnackmess(mess);
        setSeverity(severity);
    };
    
    const dispatch = useDispatch();
    const theme = useTheme();
    const userprofile= JSON.parse(localStorage.getItem('profile')); 
    const { library } = useWeb3React();
    let propvalue = ''
  
    let totalmintcycle
    
    const handleChange = (event) => {
        const {
        target: { value },
        } = event;
        setCategory(
        typeof value === 'string' ? value.split(',') : value,
        );
        setValues({...values, nftcat: value});
        val('nftcat', value)
    };

    const handleChangeForCollectionName = (event) => {
        const {
        target: { value },
        } = event;
        setCollectionname(
        typeof value === 'string' ? value.split(',') : value,
        );
        setValues({...values, nfturlname: value});
        val('nfturlname', value)
        setCollectionid(event.explicitOriginalTarget.id)
    };

    const handleSelectBlockchain = async (event) =>{
        let abrv = {symb:event.target.value, contracttype: "Shared", mainnet:0, active:1 }
        let contractinfo = await dispatch(getNetworkBySymbol(abrv));
        setNetwork(event.target.value);
        setValues({...values, nftnetwork: event.target.value, nftcontract: contractinfo[0].contract });
        val('nftnetwork', event.target.value)
    }

    const dowloadsamplefile = ()=>{
        fetch('/images/collections/samplenftcollection.json').then(response=>{
            response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Samplejsonfile.json';
            alink.click();
           })
        })
    }
    
    const val = (prop, input)=>{
        const name = prop ==='nfturlname'? input : values.nfturlname;
        const nftfiles = prop ==='nftfiles'? input : values.nftfiles;
        const nftcat = prop ==='nftcat'? input : values.nftcat;
        const properties = prop === 'nftproperties' ? input : values.nftproperties;
        const nftnetwork = prop === 'nftnetwork' ? input : values.nftnetwork;

        if(input){
   
            if (name.length!=0 && nftfiles.length!=0 && nftcat.length!=0 && properties.length!=0 && nftnetwork.toString().length!=0 ){
           
                setDisableForm(false)
            }else {

                setDisableForm(true)
            }
        }else{
            setDisableForm(true)
        }
    }

    const uploadNFTpic = (nftfiles)=>{
        let filename=nftfiles[0].name;

        if(nftfiles.length>1){
            for (let i = 1; i < nftfiles.length; i++) {
                        filename = filename+', '+nftfiles[i].name
                }
        }
        setDisplayname(filename);

        const imagefiles = Array.from(nftfiles);
        const previewurl = imagefiles.map((imagefile)=>(
            URL.createObjectURL(imagefile)
        ))
        setImageURLs(previewurl);

        val('nftfiles', nftfiles)
        setValues({...values, nftfiles: nftfiles});
    }

    const uploadPropertyFile = (nftfiles)=>{
        let filetype=nftfiles[0].type;
        if(filetype.split('/')[1]==='json'){
            setDisplayProfileFile(nftfiles[0].name);

            const fileReader = new FileReader();
            fileReader.readAsText(nftfiles[0], "UTF-8");
            fileReader.onload = e => {
                const parsedfile = JSON.parse(e.target.result);
                setPersedJSON(parsedfile);

                const namearray = displayname.split(', ');
                if(namearray.length>0){
                    namearray.map((arraykey)=>{
                        let arraymatch = arraykey.split('.')[0].toString()
                        if(!parsedfile.properties[arraymatch]){
                            return setFormaterror('JSON File not formatted properly. Ensure name of NFT files match file names entered in the json file.');
                        }else if(!parsedfile.properties[arraymatch].name){
                            return setFormaterror('JSON File not formatted properly. Ensure name is a child of the exact file name in the json file.');
                        }else if(!parsedfile.properties[arraymatch].attributes){
                            return setFormaterror('JSON File not formatted properly. Ensure attributes is a child of the file name entered in the json file.');
                        }else if (!parsedfile.properties[arraymatch].description){
                            return setFormaterror('JSON File not formatted properly. Ensure description is a child of the file name entered in the json file.');
                        }else if (!parsedfile.properties[arraymatch].price){
                            return setFormaterror('JSON File not formatted properly. Ensure price is a child of the file name entered in the json file.');
                        }else if (!parsedfile.properties[arraymatch].creatorsfee){
                            return setFormaterror('JSON File not formatted properly. Ensure creatorsfee is a child of the file name entered in the json file.');
                        }
                   
                    })
                }
                val('nftproperties', nftfiles)
                setValues({...values, nftproperties: nftfiles[0]});
            };
            

        }else{
            setFormaterror('Upload only json file.')
          
        }
    }



    const handleSubmitCollection= async(e)=>{
        e.preventDefault()
        setisLoading(true)
        
        totalmintcycle = displayname.split(', ').length
        
            let arraymatch = values.nftfiles[dowhilecounter].name.split('.')[0].toString();
            
            Object.values(persedJSON.properties[arraymatch].attributes).forEach(function(values, i) {
                if(i === 0){
                    propvalue = values.trait_type+':'+values.value+':'+values.percent
                }else{
                    propvalue = propvalue+','+values.trait_type+':'+values.value+':'+values.percent
                }  
            });
          
            const fdnft = new FormData();
            fdnft.append('files', values.nftfiles[dowhilecounter]);
            fdnft.append('nftname', persedJSON.properties[arraymatch].name);
            fdnft.append('nftdesc', persedJSON.properties[arraymatch].description);
            fdnft.append('nftroyalty', persedJSON.properties[arraymatch].creatorsfee);
            fdnft.append('newarray', JSON.stringify(persedJSON.properties[arraymatch].attributes));
            const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                const metadatlink = metadatavalue.url.split('://')
                await axios.get(`https://ipfs.io/ipfs/${metadatlink[1]}`).then(async(response)=>{
                    const metadataimageurl = response.data.image;
            // const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                    const nftinfo = {creatorfee: persedJSON.properties[arraymatch].creatorsfee, contract: values.nftcontract, metadataurl: metadatavalue.url, price: persedJSON.properties[arraymatch].price, connectData: userprofile, quantity: 1};
                    const mintnft = await MintNFTShared(nftinfo, library)
                    if(mintnft === 'error'){
                        handleSnack('Transaction was not successful', 'warning')
                        setisLoading(false)
                    }else{
                        const content = {nftprojectname: persedJSON.properties[arraymatch].name, nftcontract: values.nftcontract, nftprice: persedJSON.properties[arraymatch].price, nftdesc: persedJSON.properties[arraymatch].description, cid: metadataimageurl, meta: metadatavalue.url, nftcategory: values.nftcat.toString(), nftino: 0, nftstatus:1, nftcreatedby: userprofile, nftownedby:userprofile, nftprop:propvalue, nftcollectionname:values.nfturlname, nftcollectionid:collectionid, nftblockchain: values.nftnetwork};
                        const transactioninfo = {nftaddress: values.nftcontract, txtype: 'MINT', nftmintprice: persedJSON.properties[arraymatch].price, nftamount: 1, seller: userprofile, buyer: userprofile, date:new Date()};
                        let MintNFTid = await MintSharedCollectionEvent(nftinfo, library, content, transactioninfo, setisLoading, handleSnack, clear, dispatch, setDowhilecounter, dowhilecounter, totalmintcycle);
                    }
                })
            })
            
       
    }
    
    const clear = ()=>{
        setValues({nftname:'', nfturlname:'', nftcat:'', nftfiles:'', nftproperties:'', nftnetwork:'', nftcontract:''});
        setDisplayname('');
        setNetwork('');
        setDisplayProfileFile('');
        setDisableForm(true);
        setImageURLs([]);
        setFormaterror('')
        setPersedJSON('')
        setCollectionid();
        setCollectionid(0);
        setCategory([]);
        setCollectionname([])
       
    }

    useEffect(()=>{
        dispatch(getOwnerCollection({acc:userprofile, page:'1', sort:'1'}))
    }, [userprofile]);

 

    useEffect(() => {
        dispatch(getCats(0)); 
    }, []);

    useEffect(()=>{

        async function nftmintloader(){
            totalmintcycle = displayname.split(', ').length
            let filenameinfo = values.nftfiles ? values.nftfiles[dowhilecounter].name.split('.')[0].toString() : "";
            if(dowhilecounter>0 && dowhilecounter<totalmintcycle){
           
                const fdnft = new FormData();
                fdnft.append('files', values.nftfiles[dowhilecounter]);
                fdnft.append('nftname', persedJSON.properties[filenameinfo].name);
                fdnft.append('nftdesc', persedJSON.properties[filenameinfo].description);
                fdnft.append('nftroyalty', persedJSON.properties[filenameinfo].creatorsfee);
                fdnft.append('newarray', JSON.stringify(persedJSON.properties[filenameinfo].attributes));
                const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                    const metadatlink = metadatavalue.url.split('://')
                    await axios.get(`https://ipfs.io/ipfs/${metadatlink[1]}`).then(async(response)=>{
                        const metadataimageurleffect = response.data.image;
                // const metadata = await dispatch(nftstoragecall(fdnft)).then(async(metadatavalue)=>{
                        const nftinfo = {creatorfee: persedJSON.properties[filenameinfo].creatorsfee, contract: values.nftcontract, metadataurl: metadatavalue.url, price: persedJSON.properties[filenameinfo].price, connectData: userprofile, quantity: 1};
                        const mint = await MintNFTShared(nftinfo, library)
                        
                        if(mint === 'error'){
                            handleSnack('Transaction was not successful', 'warning')
                            setisLoading(false)
                        }else{
                            propvalue = '';
                            Object.values(persedJSON.properties[filenameinfo].attributes).forEach(function(values, i) {
                                if(i === 0){
                                    propvalue = values.trait_type+':'+values.value+':'+values.percent
                                }else{
                                    propvalue = propvalue+','+values.trait_type+':'+values.value+':'+values.percent
                                }  
                            });
                        
                            const content = {nftprojectname: persedJSON.properties[filenameinfo].name, nftcontract: values.nftcontract, nftprice: persedJSON.properties[filenameinfo].price, nftdesc: persedJSON.properties[filenameinfo].description, cid: metadataimageurleffect, meta: metadatavalue.url, nftcategory: values.nftcat.toString(), nftino: 0, nftstatus:1, nftcreatedby: userprofile, nftownedby:userprofile, nftprop:propvalue, nftcollectionname:values.nfturlname, nftcollectionid:collectionid, nftblockchain: values.nftnetwork};
                            const transactioninfo = {nftaddress: values.nftcontract, txtype: 'MINT', nftmintprice: persedJSON.properties[filenameinfo].price, nftamount: 1, seller: userprofile, buyer: userprofile, date:new Date()};
                            let MintNFTid = await MintSharedCollectionEvent(nftinfo, library, content, transactioninfo, setisLoading, handleSnack, clear, dispatch, setDowhilecounter, dowhilecounter, totalmintcycle);
                        
                        }
                    })
                })
            }
        }
        nftmintloader()
    },[dowhilecounter])

    return (
    <>
        <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
        <div className='collection-form-area'>
            <div className='section-title'>
                <h2>Add to Existing Collection</h2>
            </div>

            <div className='collection-form'>
                <form onSubmit={handleSubmitCollection}>

                    <div className='collection-category'>
                        <h3>Select Collection *</h3>
                        <FormControl sx={{ m: 1, width: '100%'}} color='error'>
                    
                        <Select
                            labelId="demo-simple-select-chip"
                            id="demo-multiple-chip"
                            value={collectionname}
                            sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                            onChange={handleChangeForCollectionName}
                            input={<OutlinedInput id="select-multiple-chip" label="category" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, borderRadius:'15px', border:'none', borderStyle:'none' }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                            {ownerscollections?.map((ownerscollection) => (
                                <MenuItem
                                key={ownerscollection.id}
                                value={ownerscollection.url_name}
                                name={ownerscollection.id}
                                id={ownerscollection.id}
                                sx={{border:'none'}}
                                style={getStyles(ownerscollection.url_name, collectionname, theme)}
                                >
                                {ownerscollection.url_name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{color:'red'}} hidden={values.nfturlname.length>0?true:false}>Select existing collection</FormHelperText>
                        </FormControl>
                    </div>

                    <div className='collection-category'>
                        <h3>Choose Item Category *</h3>
                        <FormControl sx={{ m: 1, width: '100%'}} color='error'>
                    
                        <Select
                            labelId="demo-simple-select-chip"
                            id="demo-multiple-chip"
                            multiple
                            value={category}
                            sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="category" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, borderRadius:'15px', border:'none', borderStyle:'none' }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                            {cats?.map((cat) => (
                                <MenuItem
                                key={cat.id}
                                value={cat.name}
                                sx={{border:'none'}}
                                style={getStyles(cat.name, category, theme)}
                                >
                                {cat.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText sx={{color:'red'}} hidden={values.nftcat.length>0?true:false}>Select Categories</FormHelperText>
                        </FormControl>
                    </div>

                    <div className='collection-category'>
                            <h3>Select Blockchain *</h3>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth color='error'>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                                        value={network}
                            
                                        onChange={handleSelectBlockchain}
                                        >
                                            {Blockchains.map((network, i)=>(
                                                <MenuItem sx={{border:'none'}} key={i} value={network}>{network}</MenuItem>
                                            ))}
                                            
                                        </Select>
                                        <FormHelperText sx={{color:'red'}} hidden={values.nftnetwork.toString().length>0?true:false}>Select Blockchain to mint NFT</FormHelperText>
                            
                                    </FormControl>
                                </Box>

                        </div>

                    <div className='profile-outer'>
                        <h3>Upload NFT Files *</h3>
                        <div className='profileButton'>
                            <input
                            className='profileButton-input'
                            type='file'
                            name='attachments[]'
                            accept='image/png, image/jpg, image/jpeg, image/gif'
                            id='upload'
                            onChange={(e)=>{uploadNFTpic(e.target.files) }}
                            multiple
                            />
                            <label
                            className='profileButton-button ripple-effect'
                            htmlFor='upload'
                            >
                            {displayname ? displayname :'png, jpg, jpeg and gif only'}
                            </label>
                            <span className='profileButton-file-name'></span>
                        </div>
                        <FormHelperText sx={{color:'red'}} className='danger' hidden={values.nftfiles.length>0?true:false}>Upload File. Total NFT files uploaded must match the total number of NFTs you intend to add to the collection. </FormHelperText>
                    </div>

                    <div className='preview-box' hidden={imageURLs.length>0?false:true}>
                        <h3>Preview</h3>

                        <div className='previewButton'>
                            <div className="row overflow-auto" style={{maxWidth: '100%', maxHeight: '350px'}}>
                            {/* <ImageList sx={{ width: '100%', height: 350 }}> */}  
                            {imageURLs.map((imageURL, index)=>(     
                                    <img key={index} src={imageURL} className="img-thumbnail col-3"/>
                            ))}
                            </div>
                        </div>
                    </div>

                    


                    <div className='row'>
                        
                        <div className='profile-outer'>
                            <h3>Upload JSON File With Properties * <FormHelperText  onClick={dowloadsamplefile} sx={{ cursor: 'pointer', color: 'blue' }}>Click here to download a sample JSON file</FormHelperText></h3>
                            <div className='profileButton'>
                                <input
                                className='profileButton-input'
                                type='file'
                                name='attachments[]'
                                accept='application/json' //make this json file
                                id='uploadPropFile'
                                onChange={(e)=>{uploadPropertyFile(e.target.files) }}
                                disabled = {displayname!='' ? false:true }
                                />
                                <label
                                className='profileButton-button ripple-effect'
                                htmlFor='uploadPropFile'
                                >
                                {displayname ? displayProfileFile ? displayProfileFile :'JSON only' : 'Upload NFT files for ability to upload json file'}
                                </label>
                                <span className='profileButton-file-name'></span>
                            </div>
                            <FormHelperText sx={{color:'red'}} className='danger' hidden={values.nftproperties.length>0?true:false}>Upload file with properties </FormHelperText>
                            <FormHelperText sx={{color:'red'}} className='danger' hidden={formaterror.length>0?false:true}>{formaterror} </FormHelperText>
                        </div>

                        <div className='col-lg-12 col-md-12'>
                            <button
                                type='submit'
                                className='default-btn border-radius-5'
                                disabled={disableForm}
                                style={{maxWidth:'140px'}}
                            >
                                {isloading?  <img src={Loading} style={{mixBlendMode: 'multiply', transform: 'scale(1.7)'}} className=""/> :"Add"}
                                {/* Create NFT */}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>
  );
};

export default CreateNFTCollectionCardAdd;
