
import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import {getUpcomingdrops, getPastdrops, getActivedrops} from '../../actions/nfts';


const ActiveandUpcoming = ({page}) => {
    const dispatch = useDispatch();

    const handleUpcoming = (pageno) => {
        dispatch(getUpcomingdrops(pageno));
    };

    const handleActive = async(pageno)=> {
        dispatch(getActivedrops(pageno));

    }

    const handlePast =  (pageno) => {
        dispatch(getPastdrops(pageno));
    }
    
    return ( 
            <div>
                <Stack direction="row" spacing={1} alignItems="left">
                    <Chip label="Upcoming" color="primary"  variant="outlined" onClick={()=>handleUpcoming(page)}/>
                    <Chip label="Active" color="success" variant="outlined" onClick={()=>handleActive(page)}/>
                    <Chip label="Past" color="secondary" variant="outlined" onClick={()=>handlePast(page)}/>
                </Stack>

            </div>
    );
};

export default ActiveandUpcoming;