import React from 'react';
import { PageDetailsOverall, PageDetailNFTDrop, SimilarNFT } from '../containers';
import NftDropPageBanner from '../components/Cards/PageBanner/NftDropPageBanner';

import { useLocation } from 'react-router-dom';


const Pagedetail = () => {

    const pathnamecontract = useLocation().pathname.split('/')[2];

    const nftid = useLocation().pathname.split('/')[3] ;

    return(
        <div>
            {pathnamecontract ==='nftdrop' && <NftDropPageBanner />}
            {pathnamecontract ==='nftdrop' ? <PageDetailNFTDrop /> : <PageDetailsOverall />}
            
        </div>
    );

}

export default Pagedetail;
