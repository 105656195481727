import React, {useEffect, useState} from 'react';

import { Grid, Paper, Box, FormControl, Select, MenuItem } from '@mui/material';
import {fDate} from '../../../utils/formatTime'
import { useDispatch, useSelector } from 'react-redux';
import { GiCheckMark } from "react-icons/gi";

import Loading from "../../../images/cardicons/loading.gif";
import { MintNFTDropBNB, MintNFTDropKYC, CheckPrice } from '../../Blockchain/MintBuySell';
import { MintDropEvent } from '../../Blockchain/Events';
import { fNumber2decimal, fShortenNumber } from '../../../utils/formatNumber'

import { getImageSrc }  from '../../../utils/formatImage';
import { Quantities } from '../../../utils/selectOptions'
import Copy from '../../Buttons/Copy';
import { useWeb3React } from "@web3-react/core"
import { injected } from '../../Blockchain/Connector';

import * as actionType from '../../../constants/actionTypes';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Snackalert from '../../Buttons/Snackalert';



const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
}));


const lightTheme = createTheme({ palette: { mode: 'light' } });


const PageDetailsDescription = ({item, creatordata}) => {

  const {connectData} = useSelector((state) => state.connect);
  const [loadingBNB, setLoadingBNB] = useState(false);
  const [loadingKYC, setLoadingKYC] = useState(false);
  const {library, activate} = useWeb3React();
  const [kycprice, setKycprice] = useState('');
  const [snackmess, setSnackmess] = useState(undefined); 
  const [severity, setSeverity] = useState('success');
  const [opensnack, setOpensnack] = React.useState(false);
  const [mintquantity, setMintquantity] = useState(1);
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  const dispatch = useDispatch();
  let now = new Date();
  let parsenow = Date.parse(now) / 1000
  
  const handleSnack = (mess, severity) => {
      setOpensnack(true);
      setSnackmess(mess);
      setSeverity(severity);
  };

  const buyDropWithBNB = async(saledata)=>{
    setLoadingBNB(true);
    const nftinfo = {contract: saledata.contract, metadataurl: saledata.metadataurl, mintprice: saledata.price, connectData: userprofile, quantity:mintquantity};
    await MintNFTDropBNB(nftinfo, library).then(
      async (res)=> {
           if(!res || res===false){
               res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
               setLoadingBNB(false);
           } else{
                const content = {nftprojectname: saledata.name, nftcontract: saledata.contract, nftprice: saledata.price, nftdesc: saledata.description, cid:saledata.imageurl , meta: saledata.metadataurl, nftcategory: saledata.category, nftino: 0, nftcreatedby: saledata.owner, nftownedby:userprofile, nftstatus:1, nftprop:saledata.properties, nftblockchain: saledata.chain, nftcollectionname:saledata.collection, nftcollectionid:saledata.collectionid};     
              const transactioninfo = {dropid: saledata.id, nftaddress: saledata.contract, txtype: 'MINT', nftmintprice: saledata.price, nftamount: 1, seller: userprofile, buyer: userprofile, nftblockchain: saledata.chain, date:new Date()}; 
              const remainingquantity = saledata.quantity_left - mintquantity > 0 ? saledata.quantity_left - mintquantity : 0;
              const updatequantity = {quanityleft:remainingquantity, id:saledata.id}
              let MintNFTid = await MintDropEvent(nftinfo, library, content, transactioninfo, updatequantity, setLoadingBNB, handleSnack, dispatch);
           }
   });
}

  const buyDropWithKYC = async(saledata)=>{
    setLoadingKYC(true);
    const nftinfo = {contract: saledata.contract, metadataurl: saledata.metadataurl, mintprice: saledata.price, connectData: userprofile, quantity:mintquantity };
    await MintNFTDropKYC(nftinfo, library).then(
     async (res)=> {
          if(!res || res!=true){
            res.data? handleSnack(res.data.message, 'error') : handleSnack("Transaction unsuccessful", 'warning')
            setLoadingKYC(false)
          } else{
            const content = {nftprojectname: saledata.name, nftcontract: saledata.contract, nftprice: saledata.price, nftdesc: saledata.description, cid:saledata.imageurl , meta: saledata.metadataurl, nftcategory: saledata.category, nftino: 0, nftcreatedby: saledata.owner, nftownedby:userprofile, nftstatus:1, nftprop:saledata.properties, nftblockchain: saledata.chain, nftcollectionname:saledata.collection, nftcollectionid:saledata.collectionid};
            const transactioninfo = {dropid: saledata.id, nftaddress: saledata.contract, txtype: 'MINT', nftmintprice: saledata.price, nftamount: 1, seller: userprofile, buyer: userprofile, nftblockchain: saledata.chain, date:new Date()};
            const remainingquantity = saledata.quantity_left - mintquantity > 0 ? saledata.quantity_left - mintquantity : 0;
            const updatequantity = {quanityleft:remainingquantity, id:saledata.id}
            let MintNFTid = await MintDropEvent(nftinfo, library, content, transactioninfo, updatequantity, setLoadingKYC, handleSnack, dispatch);          
          }
     }
    );
  }


  const handleSelect = (event) =>{
    setMintquantity(event.target.value);
    
  }

  const ConnecttoWallet = async()=> {
      try {
          await activate(injected)
          dispatch({ type: actionType.CONNECT, payload:'connected' });
      } catch (ex) {
      console.log(ex)
      }

  }

  const addDefaultSrc = (ev)=>{
    ev.target.src = '/images/logo/NoNFTimage.png'
   }

  const getKYCPrice = async(price)=>{
    if(price){
        let NFTprice;
        NFTprice = await CheckPrice(price, library);
        NFTprice = fNumber2decimal(NFTprice)
    
        setKycprice(NFTprice)
    }
  }

  // useEffect(() => {
  //   if (connectData) {
  //    getKYCPrice(item.price)
  //   }
  // }, [item.price, connectData]);

 
  return (
    <>
      <Snackalert severity={severity} snackmess={snackmess} opensnack={opensnack} setOpensnack={setOpensnack}  />
      <div className='section-title'>
        <h2>{item.name}</h2>
        <p>
          {item.description}
        </p>
      </div>

      <div className='row'>
        <div className='col-lg-12 col-12'>
          <div className='item-details-user'>
            <h3>Creator</h3>
            <div className='content'>
              <div className='images'>
                <img
                  src={getImageSrc(creatordata)}
                  alt={creatordata?.username}
                  onError={addDefaultSrc}
                />
                {creatordata?.verified==1&&<i><GiCheckMark/></i>}
              </div>

              <span>{creatordata?.username}</span>
            </div>
          </div>
        </div>


      </div>

     
      <div className='d-flex justify-content-between '>
            <span style={{color:'red'}}>Select Quantity</span>
           
            <FormControl fullWidth color='error'>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  sx={{borderRadius:'15px', backgroundColor:'#F6F6F6', border:'none'}} 
                  value={mintquantity}
                  onChange={handleSelect}
                >
                    {Quantities.map((quantity, i)=>(
                        <MenuItem sx={{border:'none'}} key={i} value={quantity}>{quantity}</MenuItem>
                    ))}
                    
                </Select>
            </FormControl>
      </div>
      {/* <div className='justify-content-between'> */}
      <div className='d-flex justify-content-between'> 
      <div className='item-details-in-content'>
      
        <div><h2> MINT </h2></div>
        
        <div className='item-right'>
          <div type='button' className='default-btn border-radius-50' style={{pointerEvents: loadingBNB && 'none'}} onClick={()=>{connectData ? loadingBNB === false && buyDropWithBNB(item) : ConnecttoWallet()}}>
              <span title={item.price}>{loadingBNB ? "": fShortenNumber(item.price)+' '+item.chain} </span>
              {loadingBNB ? <i className='loading-gif'><img src={Loading} alt="loading..." style={{minWidth:'70px', maxWidth:'70px' }}/> </i> : !connectData&&<h6>Connect Wallet</h6>}
          </div>
        </div>
        {/* {item.chain==='BNB' &&
        <div className='item-right'>
          <div type='button' className='default-btn border-radius-50' style={{pointerEvents: loadingBNB && 'none'}} onClick={()=>{connectData ? loadingBNB === false && buyDropWithKYC(item) : ConnecttoWallet()}}>
            <span title={kycprice}>{loadingKYC ? "" : fShortenNumber(kycprice)+ ' KYC'}</span>
            {loadingKYC ? <i className='loading-gif'><img src={Loading} alt="loading..." style={{width:'70px'}}/> </i> : !connectData&&<h6>Connect Wallet</h6>}
          </div>
        </div>
        } */}
        </div>
    
      </div>


      <div className='item-details-price'>
        <ul>
          <li className="text-danger"><strong>
            {Date.parse(item.startdate) / 1000 > parsenow? 'Starts':item.active===0? 'Ended':'Started'}
            <b>{item.active!=0&&fDate(item.startdate)}</b></strong>
          </li>
          <li>
            Contract
            <b>{item.contract.split('').splice(0, 8).join('')}{'...'}{item.contract.split('').splice(35)} <Copy text={item.contract} /></b>
          </li>
          <li>
            Quantity
            <b>{item.total_quantity}</b>
          </li>
          <li>
            Quantity Left
            <b>{item.quantity_left}</b>
          </li>
          <li>
            Created
            <b>{fDate(item.date)}</b>
          </li>
          <li>
            Category
            <b title={item.category.split(',').join(', ')}>{item.category.split(',').join(', ').split('').splice(0,25).join('')}{item.category.split(',').join(', ').length > 25 && '...' }</b>
          </li>
          {item.collection&&
            <li>
              Collection
              <b><a href={`/collection/${item.collection}`} title={item.collection} target='_blank'>{item.collection.split('').splice(0, 25).join('')}{item.collection.split('').length > 25 && '...' }</a></b>
            </li>
          }
        </ul>
      </div>

      <h3>{item.properties&&'Properties'}</h3>
      <div className='item-details-user-item'>
      {item.properties&&(
        <Grid container spacing={2}>
         
          <Grid item xs={12}>
            <ThemeProvider theme={lightTheme}>
              <Box
                sx={{
                  p: 2,
                  bgcolor: 'background.default',
                  display: 'grid',
                  gridTemplateColumns: { md: '1fr 1fr 1fr' },
                  gap: 2,
                  border_radius: '5px',
                }}
              >
              
                {item.properties.split(',').map((prop, i) => (
                  <div key={i}>
                  
                    <Item key={i+1} elevation={7}>
                        <div key={i+2} ><b key={i+4} >{prop.split(':')[0]}</b></div>
                        <i key={i+3} >{prop.split(':')[1]}</i>
                        <div key={i+5} ><h6 key={i+6} >{prop.split(':')[2]}</h6></div>
                    </Item>
                 
                  </div>
                ))}
              </Box>
            </ThemeProvider>
          </Grid>
        </Grid>
      )}
      </div>

    </>
  );
};

export default PageDetailsDescription;
