import React from 'react';
import { RequestArea } from '../containers';


const Sendrequest = () => {

    return(
        <div>
          <RequestArea />  
        </div>
    );

}

export default Sendrequest;
