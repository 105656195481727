import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination, PaginationItem } from '@mui/material';
import { getMyNFTs, getMyNFTsBySearch } from '../../../actions/nfts';
import { getOwnerCollection, getOwnerCollectionBySearch } from '../../../actions/collections';

import { Link, useLocation } from 'react-router-dom';

const Paginate = ({ page, sort, searchQuery }) => {
  const { numberOfPages } = useSelector((state) => state.nfts);
  const { numberOfPagesCollection } = useSelector((state) => state.collections);
  const { connectData } = useSelector((state) => state.connect);
  const dispatch = useDispatch();
  const pathname = useLocation().pathname.split('/')[2];
  const userprofile= JSON.parse(localStorage.getItem('profile')); 
  
  const pagelink = (itempage)=> {
    if(searchQuery){ 
      if(pathname==='collections'){
        return (`/mynfts/collections?searchQuery=${searchQuery}&page=${itempage}&sort=${sort}`)
      }else{
        return (`/mynfts?searchQuery=${searchQuery}&page=${itempage}&sort=${sort}`)
      }
     }else {
      if(pathname==='collections'){
        return (`/mynfts/collections?page=${itempage}&sort=${sort}`)
      }else{
       return (`/mynfts?page=${itempage}&sort=${sort}`)
      }
     }
  }

  useEffect(() => {
    if(searchQuery){
      if(pathname === 'collections'){
        dispatch(getOwnerCollectionBySearch({acc:userprofile, page:page, sort:sort, searchQuery:searchQuery}));
      }else{
        dispatch(getMyNFTsBySearch({acc:userprofile, page:page, sort:sort, searchQuery:searchQuery}));
      }
    }else {
      if(pathname === 'collections'){
        dispatch(getOwnerCollection({acc:userprofile, page:page, sort:sort}))
      }else{
        dispatch(getMyNFTs({acc:userprofile, page:page, sort:sort}))
    
      }
    }
  }, [dispatch, page, sort, searchQuery, connectData, pathname, userprofile]);


  return (
    pathname === 'collections'?
    <Pagination
      count={numberOfPagesCollection}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem {...item} component={Link} to={()=>pagelink(item.page)} />
      )}
    /> :
    <Pagination
      count={numberOfPages}
      page={Number(page) || 1}
      variant="outlined"
      color="primary"
      renderItem={(item) => (
        <PaginationItem {...item} component={Link} to={()=>pagelink(item.page)} />
      )}
    /> 
  );
};

export default Paginate;