import React from 'react';
import PageBannerStatic from '../../../components/Cards/PageBanner/PageBannerStatic';

const Marketing = () => {

  const dowloadfile = (e)=>{
    fetch(`/images/Logo/${e}`).then(response=>{
        response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${e}`;
        alink.click();
       })
    })
}
  const pageinfo={page:'Marketing', file:'marketing'}
  return (
    <>
    
      <PageBannerStatic
        page={pageinfo}
      />
      <div className='blog-area-two pt-50 pb-70'>
        <div className='container'>
          {/* <div className='section-title'>
            <h2>Marketing</h2>
          </div> */}

          <div className='row justify-content-center '>

            <div className='col-lg-4 col-md-6'>
              <div className='blog-card box-shadow'>
                <div className='blog-img' style={{textAlign:'center'}}>    
                    <span>
                      <img style={{height:224}} src='../images/logo/Logo.png' alt='Images' />
                    </span>  
                </div>
                <div className='content'>
                  <h5 className='positioning'>
                      <span>
                        Logo.png
                      </span>
                      <span className='downloadbutton' onClick={()=>{dowloadfile('Logo.png')}}>
                        Download
                      </span> 
                  </h5>    
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='blog-card box-shadow'>
                <div className='blog-img' style={{textAlign:'center'}}>      
                    <span>
                      <img style={{height:224}} src='../images/logo/LogoSymbol.png' alt='Images' />
                    </span>     
                </div>
                <div className='content'>
                  <h5 className='positioning'>      
                      <span>
                        Logo-Img.png
                      </span>  
                      <span className='downloadbutton' onClick={()=>{dowloadfile('LogoSymbol.png')}}>
                        Download
                      </span> 
                  </h5>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='blog-card box-shadow'>
                <div className='blog-img' style={{textAlign:'center'}}>    
                    <span>
                      <img style={{height:224}} src='../images/logo/Logo.svg' alt='Images' />
                    </span>     
                </div>
                <div className='content'>
                  <h5 className='positioning'>
                      <span>
                        Logo.svg
                      </span>
                      <span className='downloadbutton' onClick={()=>{dowloadfile('Logo.svg')}}>
                        Download
                      </span> 
                     
                  </h5>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='blog-card box-shadow'>
                <div className='blog-img' style={{textAlign:'center'}}>      
                    <span>
                      <img style={{height:224}} src='../images/logo/LogoSymbol.svg' alt='Images' />
                    </span>     
                </div>
                <div className='content'>
                  <h5 className='positioning'>      
                      <span>
                        Logo-Img.svg
                      </span>  
                      <span className='downloadbutton' onClick={()=>{dowloadfile('LogoSymbol.svg')}}>
                        Download
                      </span> 
                  </h5>
                </div>
              </div>
            </div>

          

           

            

            

            

           

            
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketing;
